import React from 'react';
import ReactDOM from 'react-dom';
import 'css/reset.scss';
import 'css/index.scss';

import App from 'components/app';
// import registerServiceWorker from './register-service-worker';

// TODO: uncomment when using mimic
// if (process.env.NODE_ENV === 'development') {
//   require('components/debug/debug-menu');
//
//   // TODO: change to app name
//   const Mimic = require('mimic').default;
//   Mimic.setAppName('QED-it console');
// }

ReactDOM.render(<App />, document.getElementById('root'));
// registerServiceWorker();
