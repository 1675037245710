import { useContext, useState } from 'react';
import { createStore } from 'reusable';
import { get } from 'lodash/fp';

import { AuthContext } from 'context/auth.context';

import { useApiRequest } from 'hooks/api.hooks';

import { AppInfo } from 'types/app-info.types';

export const APP_INFO_NETWORK_LABEL = 'app info network label';

export const useAppInfo = createStore(() => {
  const { user } = useContext(AuthContext);
  const apiRequest = useApiRequest();
  const [appInfo, setAppInfo] = useState<AppInfo>({});

  const apiAddress = get('node.address', user);
  const authToken = get('node.auth_token', user);

  const fetchAppInfo = () =>
    apiRequest({
      path: '/health',
      method: 'POST',
      baseUrl: apiAddress,
      headers: { 'x-auth-token': authToken },
      networkLabel: APP_INFO_NETWORK_LABEL,
      onSuccess: setAppInfo,
      onError: console.error
    });

  return {
    fetchAppInfo,
    appInfo
  };
});
