import React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as Transfers } from 'assets/images/transfers.svg';
import { ReactComponent as Issuances } from 'assets/images/issuance.svg';
import { IconTextButton } from 'components/wallet/transactions/icon-text-button';
import { Mode } from 'types/wallet.types';

type Props = {
  mode: Mode;
  onSelect: (mode: Mode) => void;
};

const TransactionModeTabs = (props: Props) => {
  const { mode, onSelect } = props;

  return (
    <Container>
      <IconTextButton
        text="TRANSFERS"
        icon={Transfers}
        onClick={() => onSelect('transfers')}
        isOpen={mode === 'transfers'}
      />
      <Separator />
      <IconTextButton
        text="ISSUANCES"
        icon={Issuances}
        onClick={() => onSelect('issuances')}
        isOpen={mode === 'issuances'}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Separator = styled.div`
  background-color: ${({ theme }) => theme.colors.midDarkGrey};
  width: 1px;
  height: 20px;
  margin: 0 40px;
`;

export default TransactionModeTabs;
