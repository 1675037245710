import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Flex, Box } from '@rebass/grid/emotion';
import OutsideClickHandler from 'react-outside-click-handler';

import {
  WalletAndPermission,
  PublicKey,
  WalletAddress,
  Action,
  PlaceHolder,
  RowGrid,
  ActionButton,
  AdminLabel,
  IssuerLabel
} from 'components/network-governance/table-common-components';
import { CopyToClipBoard } from 'components/common/copy-to-clip-board';
import { ToolTipBaseStyle, TooltipText } from 'components/common/tool-tip';
import { Text, Title } from 'components/common/typography';
import { WalletMenu } from 'components/network-governance/wallet-menu';
import { Popper } from 'components/common/popper';

interface Props {
  name: string;
  publicKey: string;
  walletAddress: string;
  isNodeAdmin: boolean;
  isIssuerWallet: boolean;
  isAdminWallet: boolean;
}

export const TableRow = ({
  name,
  publicKey,
  walletAddress,
  isIssuerWallet,
  isAdminWallet,
  isNodeAdmin
}: Props) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isPublicKeyTooltipOpen, setIsPublicKeyTooltipOpen] = useState(false);
  const [isWalletAddressTooltipOpen, setIsWalletAddressTooltipOpen] = useState(
    false
  );

  const isDisabledMenu = !isNodeAdmin && !isAdminWallet && !isIssuerWallet;

  return (
    <Container
      onMouseEnter={() => setIsFocused(true)}
      onMouseLeave={() => setIsFocused(false)}>
      <RowGrid>
        <StyledWalletAndPermission>
          <StyledTitle isFocused={isFocused}>{name}</StyledTitle>
          {isAdminWallet && <AdminLabel>Admin</AdminLabel>}
          {isIssuerWallet && <IssuerLabel>Issuer</IssuerLabel>}
        </StyledWalletAndPermission>
        <PublicKey>
          <Popper
            placement="top-start"
            targetElementClass=".network-governance-container"
            isOpen={isPublicKeyTooltipOpen}
            referenceElement={
              <Box
                onMouseLeave={() => setIsPublicKeyTooltipOpen(false)}
                onMouseEnter={() => setIsPublicKeyTooltipOpen(true)}>
                <CopyToClipBoard value={publicKey} highlightOnCopy={true} />
              </Box>
            }
            poppingElement={
              <ToolTipBaseStyle>
                <TooltipText>
                  <Text>{publicKey}</Text>
                </TooltipText>
              </ToolTipBaseStyle>
            }
          />
        </PublicKey>
        <WalletAddress>
          <Popper
            isOpen={isWalletAddressTooltipOpen}
            placement="top-start"
            targetElementClass=".network-governance-container"
            referenceElement={
              <Box
                onMouseLeave={() => setIsWalletAddressTooltipOpen(false)}
                onMouseEnter={() => setIsWalletAddressTooltipOpen(true)}>
                <CopyToClipBoard value={walletAddress} highlightOnCopy={true} />
              </Box>
            }
            poppingElement={
              <ToolTipBaseStyle>
                <TooltipText>
                  <Text>{walletAddress}</Text>
                </TooltipText>
              </ToolTipBaseStyle>
            }
          />
        </WalletAddress>
        <PlaceHolder />
        <Flex alignItems="center">
          <StyledAction>
            <Popper
              isOpen={isMenuOpen}
              padding={{ right: 9 }}
              targetElementClass=".network-governance-container"
              referenceElement={
                <ActionButton
                  disabled={isDisabledMenu}
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                />
              }
              poppingElement={
                <OutsideClickHandler
                  onOutsideClick={() => setIsMenuOpen(false)}>
                  <WalletMenu
                    handleCloseMenu={() => setIsMenuOpen(false)}
                    publicKey={publicKey}
                    name={name}
                    isAdminWallet={isAdminWallet}
                    isIssuerWallet={isIssuerWallet}
                    isNodeAdmin={isNodeAdmin}
                  />
                </OutsideClickHandler>
              }
            />
          </StyledAction>
        </Flex>
      </RowGrid>
    </Container>
  );
};

const Container = styled.div`
  padding: 8px 15px;
  color: ${({ theme }) => theme.colors.darkerGrey};

  &:hover {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.09);
    border-radius: 13px;
  }
`;

const StyledWalletAndPermission = styled(WalletAndPermission)`
  display: flex;
  align-items: center;
`;

const StyledTitle = styled(Title)<{
  isFocused: boolean;
}>`
  margin-right: 15px;

  ${({ isFocused, theme }) => isFocused && `color: ${theme.colors.lightBlue};`}
`;

const StyledAction = styled(Action)`
  position: relative;
`;
