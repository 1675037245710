import styled from '@emotion/styled';

interface MenuProps {
  position?: 'fixed' | 'absolute';
  top?: number;
  bottom?: number;
  right?: number;
  left?: number;
}

export const Menu = styled.div<MenuProps>`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 15px;
  cursor: auto;
  color: ${({ theme }) => theme.colors.midDarkGrey};
  position: ${({ position }: MenuProps) => position};
  top: ${({ top }: MenuProps) => top && `${top}px`};
  bottom: ${({ bottom }: MenuProps) => bottom && `${bottom}px`};
  right: ${({ right }: MenuProps) => right && `${right}px`};
  left: ${({ left }: MenuProps) => left && `${left}px`};
  z-index: 200;
`;
