import styled from '@emotion/styled';

import { ReactComponent as ActionIcon } from 'assets/images/dotes.svg';

import { MediumText } from 'components/common/typography';

const BaseStyle = styled.div`
  position: relative;
`;

export const WalletAndPermission = styled(BaseStyle)``;

export const PublicKey = styled(BaseStyle)`
  padding-right: 50px;
`;

export const WalletAddress = styled(BaseStyle)`
  padding-right: 50px;
`;

export const Action = styled(BaseStyle)``;

export const PlaceHolder = styled.div``;

export const RowGrid = styled.div`
  display: grid;
  grid-template-columns: 40% 25% 25% 5% 5%;
  align-items: center;
`;

const Label = styled(MediumText)`
  padding: 10px 13px;
  font-size: 15px;
  font-weight: 500;
  border-radius: 10px;
`;

export const AdminLabel = styled(Label)`
  background-color: ${({ theme }) => theme.colors.veryLightTurquoise};
  color: ${({ theme }) => theme.colors.lightBlue};
  margin-right: 5px;
`;

export const IssuerLabel = styled(Label)`
  background-color: ${({ theme }) => theme.colors.grey};
  color: ${({ theme }) => theme.colors.midDarkGrey};
`;

export const ActionButton = styled(ActionIcon)<{ disabled: boolean }>`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  fill: ${({ theme, disabled }) =>
    disabled ? theme.colors.lightGrey : theme.colors.lightBlue};
  pointer-events: ${({ disabled }) => disabled && 'none'};
  cursor: pointer;


  &:hover {
    ${({ disabled, theme }) =>
      !disabled &&
      `
        background-color: ${theme.colors.greyLighter};
        .dotes {
          fill: ${theme.colors.midDarkGrey};
        }
    `}
`;
