import { useContext } from 'react';
import { createStore } from 'reusable';
import { get } from 'lodash/fp';

import { AuthContext } from 'context/auth.context';

import { useApiRequest } from 'hooks/api.hooks';

import { Rule } from 'stores/wallet/rules.store';

const ADD_RULE = 'add rule';

export const useAddRule = createStore(() => {
  const apiRequest = useApiRequest();
  const { user } = useContext(AuthContext);

  const apiAddress = get('node.address', user);
  const authToken = get('node.auth_token', user);

  const addWalletRule = (
    wallet_id: string,
    authorization: string,
    rules_to_add: Rule[],
    onSuccess: () => void = () => {}
  ) =>
    apiRequest({
      path: '/wallet/create_rule',
      method: 'POST',
      baseUrl: apiAddress,
      headers: { 'x-auth-token': authToken },
      data: {
        wallet_id,
        authorization,
        rules_to_add
      },
      networkLabel: ADD_RULE,
      onSuccess: onSuccess
    });

  return {
    addWalletRule
  };
});
