import React, { useState, useEffect } from 'react';
import moment from 'moment';
import styled from '@emotion/styled';
import {
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Line,
  LineChart
} from 'recharts';
import {
  debounce,
  filter,
  get,
  map,
  flow,
  min,
  max,
  mean,
  forEach
} from 'lodash/fp';

import { ReactComponent as HeaderIcon } from 'assets/images/processing-time.svg';

import ProcessingTimeTooltip from './processing-time-tooltip';
import ChartLabel from 'components/common/chart-label';
import Circle from 'components/common/circle';
import { Text } from 'components/common/typography';
import IconicText from 'components/common/iconic-text';
import ChartActiveDot from 'components/common/chart-active-dot';
import { CHART_COLORS } from './processing-time-colors';

import { useGetTasks } from 'stores/wallet/get-tasks.store';

import { ProcessingTimeChartData } from 'types/chart.types';
import { Tasks, Task } from 'types/tasks.types';

const getTaskProcessingTime = (task: Task) => {
  const taskUpdateTime = moment(get('updated_at', task)).unix();
  const taskCreationTime = moment(get('created_at', task)).unix();
  const processingTimeMS = taskUpdateTime - taskCreationTime;
  return processingTimeMS;
};

const getChartData = (tasks: Tasks): ProcessingTimeChartData => {
  let accumulator = moment().subtract(15, 'minutes');
  let now = moment();
  const chartData: ProcessingTimeChartData = [];

  while (accumulator < now) {
    const currentStepTasksProcessingTimes: number[] = [];
    const accumulatorEndPoint = accumulator.clone().add(15, 'seconds');

    forEach((task: Task) => {
      const currentTaskTimestamp = moment(task.created_at);
      if (currentTaskTimestamp.isBetween(accumulator, accumulatorEndPoint)) {
        currentStepTasksProcessingTimes.push(getTaskProcessingTime(task));
      }
    }, tasks);

    const minProcessingTime = min(currentStepTasksProcessingTimes) || 0;
    const maxProcessingTime = max(currentStepTasksProcessingTimes) || 0;
    const averageProcessingTime = mean(currentStepTasksProcessingTimes) || 0;

    chartData.push({
      min: minProcessingTime,
      max: maxProcessingTime,
      average: averageProcessingTime,
      date: accumulator.clone().valueOf()
    });

    accumulator = accumulator.clone().add(15, 'seconds');
  }

  return chartData;
};

// TODO: add types to task when merged
const ProcessingTimeChart = () => {
  const { fetchTasks } = useGetTasks();
  const [chartData, setChartData] = useState<ProcessingTimeChartData>([]);
  const [tooltipX, setTooltipX] = useState<number>(0);

  useEffect(() => {
    fetchTasks(['success']).then(({ tasks }: { tasks: Tasks }) => {
      const initialChartData = getChartData(tasks);
      setChartData(initialChartData);
    });

    const updateProcessingTime = () => {
      /*Ori:
      Call get_tasks with result=success every 15 seconds.
      Take all the results that ended within the last 15 seconds.
      Calculate last update time - created time for each one
      . Then calculate the min, max, and avg for all the results in the last 15 seconds
       */
      fetchTasks(['success']).then((tasks: any) => {
        const filterStartTime = moment().subtract(15, 'seconds');
        const chartStartTime = moment().subtract(15, 'minutes');
        const now = moment();

        const tasksProcessingTime = flow(
          get('tasks'),
          filter((task: any) => {
            const taskUpdateTime = moment(get('updated_at', task));
            return taskUpdateTime.isBetween(filterStartTime, now);
          }),
          map(getTaskProcessingTime)
        )(tasks);

        const minProcessingTime = min(tasksProcessingTime) || 0;
        const maxProcessingTime = max(tasksProcessingTime) || 0;
        const averageProcessingTime = mean(tasksProcessingTime) || 0;

        setChartData((current: ProcessingTimeChartData) => [
          ...current.filter(processingTime =>
            moment(processingTime.date).isBetween(chartStartTime, now)
          ),
          {
            min: minProcessingTime,
            max: maxProcessingTime,
            average: averageProcessingTime,
            date: new Date().getTime()
          } as any //todo: fix ts
        ]);
      });
    };

    const intervalId = setInterval(updateProcessingTime, 15000);
    updateProcessingTime();
    return () => clearInterval(intervalId);
  }, []);

  const onAreaChartMouseOver = debounce(10, (event: any) => {
    const { chartX } = event;
    setTooltipX(chartX);
  });

  const xAxisDateFormatter = (value: number) => {
    const diffMinutes = moment(value).diff(moment(), 'minutes');
    if (diffMinutes === 0) {
      return 'Now';
    }
    return `${diffMinutes} min`;
  };

  const yAxisDateFormatter = (value: number) => {
    return `${value} s`;
  };

  return (
    <Container>
      <IconicText text="PROCESSING TIME RIGHT NOW" icon={HeaderIcon} />
      <ResponsiveContainer width="99%" height={520}>
        <LineChart data={chartData} onMouseMove={onAreaChartMouseOver}>
          <Line
            isAnimationActive={false}
            type="monotone"
            dataKey="max"
            stroke={CHART_COLORS.max}
            activeDot={ChartActiveDot}
            dot={false}
          />
          <Line
            isAnimationActive={false}
            type="monotone"
            dataKey="average"
            stroke={CHART_COLORS.average}
            activeDot={ChartActiveDot}
            dot={false}
          />
          <Line
            isAnimationActive={false}
            type="monotone"
            dataKey="min"
            stroke={CHART_COLORS.min}
            activeDot={ChartActiveDot}
            dot={false}
          />
          <XAxis
            tick={<ChartLabel dy={10} valueFormatter={xAxisDateFormatter} />}
            dataKey="date"
            domain={['dataMin', 'dataMax']}
            type="number"
            axisLine={false}
            tickLine={false}
            padding={{ left: 10, right: 10 }}
            tickCount={6}
            tickMargin={15}
          />
          <YAxis
            tick={<ChartLabel dy={4} valueFormatter={yAxisDateFormatter} />}
            tickCount={3}
            tickSize={4}
            axisLine={false}
            type="number"
            padding={{ top: 10, bottom: 10 }}
          />
          <Tooltip content={ProcessingTimeTooltip} />
        </LineChart>
      </ResponsiveContainer>
      <ChartLegend>
        <ChartLegendItem>
          <Circle color={CHART_COLORS.min} size={13} />
          <TitleContainer>
            <Text>Minimum Processing Time</Text>
          </TitleContainer>
        </ChartLegendItem>
        <ChartLegendItem>
          <Circle color={CHART_COLORS.average} size={13} />
          <TitleContainer>
            <Text>Average Processing Time</Text>
          </TitleContainer>
        </ChartLegendItem>
        <ChartLegendItem>
          <Circle color={CHART_COLORS.max} size={13} />
          <TitleContainer>
            <Text>Maximum Processing Time</Text>
          </TitleContainer>
        </ChartLegendItem>
      </ChartLegend>
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const ChartLegend = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.midDarkGrey};
`;

const TitleContainer = styled.div`
  margin-left: 8px;
`;

const ChartLegendItem = styled.div`
  display: flex;
  align-items: center;
  & + & {
    margin-left: 32px;
  }
`;
export default ProcessingTimeChart;
