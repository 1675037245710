import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion';

import logo from 'assets/images/logo-small.svg';
import bell from 'assets/images/bell.png';
import userIcon from 'assets/images/user-icon.svg';

import { UserMenu } from 'components/layout/header/user-menu';
import { H1 } from 'components/common/typography';

interface Props {
  section: string;
}

export const Header = ({ section }: Props) => {
  const [isUserMenuOpen, setIsUserMenuOpen] = useState<boolean>(false);

  return (
    <>
      <HeaderPlaceHolder />
      <Container>
        <Flex ml="20px" alignItems="center">
          <Logo />
          <Section>
            <H1>{section}</H1>
          </Section>
        </Flex>
        <Flex mr="38px" alignItems="center">
          {/*TODO: uncomment after POC*/}
          {/*<Bell />*/}
          <ConnectorContainer>
            {/*TODO: uncomment after POC*?}
          {/*
          <ConnectorType>Nexledger Connector</ConnectorType>
          <StatusIconContainer>
            <StatusIcon />
            <Status>ON</Status>
          </StatusIconContainer>
          */}
          </ConnectorContainer>
          <User onClick={() => setIsUserMenuOpen(!isUserMenuOpen)}>
            <Icon />
            {isUserMenuOpen && <UserMenu handleToggle={setIsUserMenuOpen} />}
          </User>
        </Flex>
      </Container>
    </>
  );
};

const HeaderPlaceHolder = styled.div`
  height: ${({ theme }) => theme.headerHeight}px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${({ theme }) => theme.headerHeight}px;
  background-color: ${({ theme }) => theme.colors.dark};
  box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.16);

  position: fixed;
  z-index: 19;
  top: 0;
  right: 0;
  left: 0;
`;

const Logo = styled.div`
  background: url(${logo}) no-repeat center;
  width: 80px;
  height: 30px;
  margin-right: 20px;
`;

const Section = styled.div`
  color: ${({ theme }) => theme.colors.white};
`;

const Bell = styled.div`
  background: url(${bell}) no-repeat center;
  width: 25px;
  height: 35px;
  cursor: pointer;
`;

const User = styled.div`
  ${({ theme }) => theme.typography.loginButton};
  background-color: ${({ theme }) => theme.colors.white};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 40px;
  cursor: pointer;
`;

const Icon = styled.div`
  background: url(${userIcon}) no-repeat center;
  background-size: contain;
  width: 30px;
  height: 30px;
`;

// TODO: connector - remove after POC?
const ConnectorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
`;

const ConnectorType = styled.div`
  font-family: SharpSansNo1-Bold;
  font-size: 22px;
  line-height: 1.36;
  /* todo: re-add this margin after re-addiing the 'ON' status icon
  margin-right: 10px;
  */
`;

const StatusIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StatusIcon = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  box-shadow: 0 0 6px 0 ${({ theme }) => theme.colors.lightBlue};
  border: solid 1px ${({ theme }) => theme.colors.midDarkGrey};
  background-color: #a1e5ff;
  margin-right: 10px;
`;

const Status = styled.div`
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.typography.text};
  font-size: 15px;
  line-height: 1;
`;
