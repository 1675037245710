import React, { useState } from 'react';
import styled from '@emotion/styled';
import { keyframes, css } from '@emotion/core';
import get from 'lodash/fp/get';

import blockIcon from 'assets/images/block.svg';

import { Popper } from 'components/common/popper';
import { CopyToClipBoard } from 'components/common/copy-to-clip-board';
import {
  BlockHashContainer,
  BlockTypeContainer,
  BlockHeightContainer,
  TransactionHashContainer,
  TransactionTimeContainer,
  ViewTransactionProofContainer
} from 'components/block-explorer/block-explorer-table-components';

import { viewedTransactionTx } from 'stores/wallet/transactions.store';
import { useIsProofModalOpen } from 'stores/ui/proof-modal-ui.store';

import { formatTransactionDateTime } from 'utils/date.utils';

import { NetworkActivityTransaction } from 'types/network-activity.types';
import { Title, BoldTitle, Text } from 'components/common/typography';
import { ToolTipBaseStyle, TooltipText } from 'components/common/tool-tip';

type Props = {
  transaction: NetworkActivityTransaction;
};

type TransactionMapper = (_: NetworkActivityTransaction) => string;

const transactionLabelMapping: { [key: string]: string | TransactionMapper } = {
  Transfer: 'Private Transfer',
  Rule: 'Rule',
  Issue: (type: NetworkActivityTransaction) => {
    const isPrivate = get('content.outputs[0].is_confidential', type);
    return isPrivate ? 'Private Issuance' : 'Public Issuance';
  }
};

const getTransactionLabel = (
  transaction: NetworkActivityTransaction
): string => {
  const type: string = get('metadata.type', transaction);
  const typeExplainer = transactionLabelMapping[type];
  if (typeof typeExplainer === 'function') {
    return typeExplainer(transaction);
  }

  return typeExplainer;
};

const BlockExplorerTableRow = (props: Props) => {
  const { transaction } = props;
  const blockHeight = get('metadata.block_height', transaction);
  const txHash = get('metadata.tx_hash', transaction);
  const blockHash = get('metadata.block_hash', transaction);

  const [isBlockHashTooltipIsOpen, setIsBlockHashTooltipIsOpen] = useState(
    false
  );
  const [isTxHashTooltipIsOpen, setIsTxHashTooltipIsOpen] = useState(false);

  const { setTransactionTx } = viewedTransactionTx();
  const [, setIsOpen] = useIsProofModalOpen();

  const onViewTransactionProofClick = () => {
    setTransactionTx(txHash);
    setIsOpen(true);
  };

  const renderPoppingElement = (text: string) => (
    <ToolTipBaseStyle>
      <TooltipText>
        <Text>{text ? text : ''}</Text>
      </TooltipText>
    </ToolTipBaseStyle>
  );

  const transactionTypeText: string = getTransactionLabel(transaction);

  return (
    <Container>
      <BlockHeightContainer>
        <BlockHeight>
          <BlockHeightIcon />
          <BlockHeightText>
            <Title>{blockHeight ? blockHeight : ''}</Title>
          </BlockHeightText>
        </BlockHeight>
      </BlockHeightContainer>
      <BlockTypeContainer>
        <BlockType type={transactionTypeText}>
          <Title>{transactionTypeText}</Title>
        </BlockType>
      </BlockTypeContainer>
      <BlockHashContainer>
        <Popper
          isOpen={isBlockHashTooltipIsOpen}
          placement="top-start"
          targetElementClass=".block-explorer-container"
          referenceElement={
            <BlockHash
              onMouseEnter={() => setIsBlockHashTooltipIsOpen(true)}
              onMouseLeave={() => setIsBlockHashTooltipIsOpen(false)}>
              <CopyToClipBoard value={blockHash} highlightOnCopy={true} />
            </BlockHash>
          }
          poppingElement={renderPoppingElement(blockHash)}
        />
      </BlockHashContainer>
      <TransactionHashContainer>
        <Popper
          isOpen={isTxHashTooltipIsOpen}
          placement="top-start"
          targetElementClass=".block-explorer-container"
          referenceElement={
            <TransactionHash
              onMouseEnter={() => setIsTxHashTooltipIsOpen(true)}
              onMouseLeave={() => setIsTxHashTooltipIsOpen(false)}>
              <CopyToClipBoard value={txHash} highlightOnCopy={true} />
            </TransactionHash>
          }
          poppingElement={renderPoppingElement(txHash)}
        />
      </TransactionHashContainer>
      <TransactionTimeContainer>
        <TransactionTime>
          <Title>
            {formatTransactionDateTime(transaction.metadata.timestamp)}
          </Title>
        </TransactionTime>
      </TransactionTimeContainer>
      <ViewTransactionProofContainer>
        <ViewTransactionProofButton onClick={onViewTransactionProofClick}>
          <BoldTitle>view transaction proof</BoldTitle>
        </ViewTransactionProofButton>
      </ViewTransactionProofContainer>
    </Container>
  );
};

const changeBackgroundColor = keyframes`
 0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
`;

const BlockHeight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const BlockHeightText = styled.div`
  color: ${({ theme }) => theme.colors.darkerGrey};
`;

const BlockHeightIcon = styled.div`
  background: url(${blockIcon}) no-repeat center;
  background-size: contain;
  margin-right: 13px;
  height: 20px;
  width: 20px;
`;

const BlockType = styled.div<{
  type: string;
}>`
  border-radius: 10px;
  width: min-content;
  padding: 8px 14px;
  background-color: ${({ theme, type }) =>
    type === 'Public Issuance' ? theme.colors.orange : theme.colors.grey};
  color: ${({ theme, type }) =>
    type === 'Public Issuance'
      ? theme.colors.darkOrange
      : theme.colors.darkerGrey};
  white-space: nowrap;
`;

const hashMediaQueries = css`
  overflow-x: hidden;
  text-overflow: ellipsis;
  max-width: 20ch;

  @media (max-width: 1750px) {
    max-width: 15ch;
  }
  @media (max-width: 1440px) {
    max-width: 10ch;
  }
`;

const BlockHash = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.midDarkGrey};

  & > * {
    ${hashMediaQueries}
  }
`;
const TransactionHash = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.darkerGrey};
  & > * {
    ${hashMediaQueries}
  }
`;

const TransactionTime = styled.div`
  color: ${({ theme }) => theme.colors.midDarkGrey};
`;

//todo: use typography button when available
const ViewTransactionProofButton = styled.div`
  margin-left: auto;
  text-transform: uppercase;
  ${({ theme }) => theme.typography.transferButton};
  color: ${({ theme }) => theme.colors.lightBlue};
  border-color: ${({ theme }) => theme.colors.lightBlue};
  border-width: 2px;
  border-style: solid;
  background-color: ${({ theme }) => theme.colors.white};
  width: min-content;
  border-radius: 25px;
  padding: 10px 14px;
  cursor: pointer;
  white-space: nowrap;
  font-size: 14px;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex: 1 0 auto;
  flex-direction: row;
  align-items: center;
  margin: 4px 0;
  animation: ${changeBackgroundColor} 0.5s ease-in-out;
  padding: 16px;
  &:hover {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.09);
    border-radius: 13px;
  }
  &:hover ${ViewTransactionProofButton} {
    background-color: ${({ theme }) => theme.colors.azure};
    border-color: ${({ theme }) => theme.colors.azure};
  }
`;
export default BlockExplorerTableRow;
