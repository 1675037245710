import React, { useState } from 'react';
import styled from '@emotion/styled';
import { isEmpty } from 'lodash/fp';
import OutsideClickHandler from 'react-outside-click-handler';

import downArrow from 'assets/images/down-arrow.png';

type Item = { value: any; text: string };

interface Props {
  items: Item[];
  onSelect: (item: Item) => void;
  selectedValue?: string;
  label?: string;
  width?: string;
  height?: string;
  margin?: string;
  padding?: string;
  dropDownMaxHeight?: string;
}

export const SingleSelect = ({
  items,
  onSelect,
  label,
  selectedValue,
  width,
  height,
  margin,
  dropDownMaxHeight
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (item: Item) => {
    setIsOpen(false);
    onSelect(item);
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
      <Container margin={margin} width={width}>
        {label && <Label>{label}</Label>}
        <InputIconContainer onClick={() => setIsOpen(!isOpen)} height={height}>
          <Input height={height}>{selectedValue}</Input>
          <Icon />
        </InputIconContainer>
        {isOpen && (
          <Dropdown
            width={width}
            maxHeight={dropDownMaxHeight}
            className="dropdown">
            {!isEmpty(items) ? (
              items.map((item, index) => (
                <Item onClick={() => handleSelect(item)} key={index}>
                  {item.text}
                </Item>
              ))
            ) : (
              <Item>No Options</Item>
            )}
          </Dropdown>
        )}
        {isOpen}
      </Container>
    </OutsideClickHandler>
  );
};

const Container = styled.div<{ margin?: string; width?: string }>`
  position: relative;
  line-height: 1.25;
  color: ${({ theme }) => theme.colors.midDarkGrey};
  margin: ${({ margin }) => margin};
  width: ${({ width }) => (width ? width : `485px`)};
`;

const Label = styled.div`
  color: ${({ theme }) => theme.colors.midDarkGrey};
  ${({ theme }) => theme.typography.text};
  font-size: 12px;
`;

const InputIconContainer = styled.div<{ height?: string }>`
  display: flex;
  align-items: center;
  height: ${({ height }) => (height ? height : `30px`)};
  border-bottom: 1px solid ${({ theme }) => theme.colors.midDarkGrey};
  padding: 0 10px 0 0;
  cursor: pointer;
`;

const Input = styled.div<{ height?: string }>`
  ${({ theme }) => theme.typography.modalInput};
  font-size: 12px;
  color: ${({ theme }) => theme.colors.midDarkGrey};
  width: calc(100% - 15px);
  height: ${({ height }) => (height ? height : `30px`)};
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px;
`;

const Icon = styled.div`
  background: url(${downArrow}) no-repeat center;
  background-size: contain;
  width: 15px;
  height: 15px;
`;

const Dropdown = styled.div<{ width?: string; maxHeight?: string }>`
  position: absolute;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.background};
  width: ${({ width }) => (width ? width : `485px`)};
  z-index: 10;
  white-space: nowrap;
  overflow-y: auto;

  ${({ maxHeight }) =>
    maxHeight &&
    `
    max-height: ${maxHeight};
  `}
`;

const Item = styled.div`
  ${({ theme }) => theme.typography.text};
  font-size: 12px;
  line-height: 1.22;
  padding: 10px;
  cursor: pointer;
  max-width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;

  &:hover {
    background-color: ${({ theme }) => theme.colors.lightBlue};
    color: ${({ theme }) => theme.colors.white};
  }
`;
