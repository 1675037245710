import React, { ReactNode } from 'react';
import styled from '@emotion/styled';

type Props = {
  children: ReactNode;
  padding?: { top?: number; right?: number; bottom?: number; left?: number };
};

export const Card = ({ children, padding }: Props) => {
  return <StyledCard padding={padding}>{children}</StyledCard>;
};

const StyledCard = styled.div<{
  padding?: { top?: number; right?: number; bottom?: number; left?: number };
}>`
  height: 100%;
  box-shadow: 0 0 28px 0 rgba(0, 0, 0, 0.11);
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.white};
  padding-top: ${({ padding }) =>
    padding && padding.top ? `${padding.top}px` : '15px'};
  padding-right: ${({ padding }) =>
    padding && padding.right ? `${padding.right}px` : '30px'};
  padding-bottom: ${({ padding }) =>
    padding && padding.bottom ? `${padding.bottom}px` : '15px'};
  padding-left: ${({ padding }) =>
    padding && padding.left ? `${padding.left}px` : '30px'};
`;

export default Card;
