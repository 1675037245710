import React from 'react';
import { ChartBalance } from 'types/chart.types';
import { Transaction } from 'types/wallet.types';
import {
  getFormattedAmount,
  getNumberOfTransactionsFormatted
} from 'utils/wallet.utils';
import { isEmpty, flow, filter, get, size } from 'lodash/fp';
import ChartKeyValueTable from 'components/common/chart-keyvalue-table';

interface Props {
  transactions: Transaction[];
  balances: ChartBalance[];
}

export const AssetBalanceTable = (props: Props) => {
  const { transactions, balances } = props;

  let netChange = 0,
    totalIncomingTransactions = 0,
    totalOutgoingTransactions = 0;

  if (!isEmpty(transactions)) {
    netChange = balances[0].balance - balances[balances.length - 1].balance;

    totalIncomingTransactions = flow(
      filter((transaction: Transaction) =>
        get(['content', 'is_incoming'], transaction)
      ),
      size
    )(transactions);

    totalOutgoingTransactions = flow(
      filter(
        (transaction: Transaction) =>
          !get(['content', 'is_incoming'], transaction)
      ),
      size
    )(transactions);
  }

  return (
    <ChartKeyValueTable
      keyValuePairs={{
        'NET CHANGE': `${netChange > 0 ? '+' : ''}${getFormattedAmount(
          netChange
        )}`,
        'TOTAL INCOMING TRANSACTIONS': getNumberOfTransactionsFormatted(
          totalIncomingTransactions
        ),
        'TOTAL OUTGOING TRANSACTIONS': getNumberOfTransactionsFormatted(
          totalOutgoingTransactions
        )
      }}
    />
  );
};
