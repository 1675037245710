import isEmpty from 'lodash/fp/isEmpty';
import filter from 'lodash/fp/filter';
import get from 'lodash/fp/get';
import find from 'lodash/fp/find';

import { Rule } from 'stores/wallet/rules.store';
import { PublicKey } from 'types/wallet.types';
import { Wallet } from 'types/firebase.types';

export const getWalletRules = (publicKey: string, rules: Rule[]) =>
  filter(rule => rule.public_key === publicKey, rules);

export const getIsIssuer = (publicKey: string, rules: Rule[]) => {
  const walletRules = getWalletRules(publicKey, rules);

  return !isEmpty(find(rule => get('namespace', rule), walletRules));
};

export const getIsAdmin = (publicKey: string, rules: Rule[]) => {
  const walletRules = getWalletRules(publicKey, rules);

  return !!get('is_admin', find(rule => rule.is_admin === true, walletRules));
};

export const getIsNodeAdmin = (publicKeys: PublicKey[], rules: Rule[]) => {
  let isNodeAdmin;

  if (!isEmpty(rules) && !isEmpty(publicKeys)) {
    for (let i = 0; i < rules.length; i++) {
      if (rules[i].is_admin) {
        isNodeAdmin = find(({ public_key }: { public_key: string }) => {
          return rules[i].public_key === public_key;
        }, publicKeys);

        if (!!isNodeAdmin) {
          break;
        }
      }
    }
  }

  return isNodeAdmin;
};

export const getNodeAuth = (walletId: string, wallets: Wallet[]) =>
  get('auth', find(wallet => wallet.id === walletId, wallets));
