import React, { FormEvent, useState } from 'react';
import Autosuggest, {
  InputProps,
  ChangeEvent,
  RenderSuggestionParams,
  SuggestionsFetchRequestedParams,
  RenderSuggestionsContainerParams
} from 'react-autosuggest';
import styled from '@emotion/styled';

import deburr from 'lodash/fp/deburr';

import { TextField } from 'components/common/text-field';

import { ValueType } from 'types/common.types';

const renderInputComponent = (inputProps: InputProps<any>) => {
  const {
    error,
    helperText,
    onChange,
    value,
    ref,
    onFocus,
    autoComplete,
    ...rest
  } = inputProps;
  return (
    <TextField
      {...rest}
      autoComplete={autoComplete as any}
      outsideRef={ref}
      type="text"
      label="Asset ID"
      value={value}
      onChange={(value: any, e: any) => onChange(e, value)}
      onFocus={onFocus}
      error={error}
      helperText={helperText}
    />
  );
};

const renderSuggestion = (
  suggestion: ValueType<any>,
  { isHighlighted }: RenderSuggestionParams
) => {
  return (
    <Suggestion selected={isHighlighted}>
      <span>{suggestion.label}</span>
    </Suggestion>
  );
};

const renderSuggestionsContainer = (
  options: RenderSuggestionsContainerParams
) => (
  <div {...options.containerProps}>
    <SuggestionsContainer>{options.children}</SuggestionsContainer>
  </div>
);

const getSuggestions = (value: string, suggestions: ValueType<any>[]) => {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  const result =
    inputLength === 0
      ? []
      : suggestions.filter((suggestion: any) =>
          suggestion.label.toLowerCase().includes(inputValue)
        );
  return result;
};

const getSuggestionLabel = (suggestion: ValueType<any>) => suggestion.label;

interface Props<T> {
  suggestions: ValueType<T>[];
  onChange?: (value?: T) => void;
  onInputChange?: (value: string) => void;

  error?: boolean;
  helperText?: string;
}
export function IntegrationAutosuggest<T>({
  suggestions,
  onChange,
  onInputChange,
  error,
  helperText
}: Props<T>) {
  const [value, setValue] = useState('');
  const [stateSuggestions, setSuggestions] = useState<any>([]);

  const handleSuggestionsFetchRequested = ({
    value
  }: SuggestionsFetchRequestedParams) =>
    setSuggestions(getSuggestions(value, suggestions));

  const handleSuggestionsClearRequested = () => setSuggestions([]);

  const handleChange = (event: FormEvent<any>, { newValue }: ChangeEvent) => {
    const suggestion = suggestions.find(
      item => item.label.toLowerCase() === newValue.toLowerCase()
    );
    onInputChange && onInputChange(newValue);
    if (suggestion && onChange) {
      onChange(suggestion.value);
    }
    setValue(newValue);
  };

  const inputProps: InputProps<any> = {
    value,
    onChange: handleChange,
    error: error,
    helperText: helperText
  };

  return (
    <StyledWrapper>
      <Autosuggest
        inputProps={inputProps}
        suggestions={stateSuggestions}
        highlightFirstSuggestion={true}
        renderSuggestion={renderSuggestion}
        getSuggestionValue={getSuggestionLabel}
        renderInputComponent={renderInputComponent}
        renderSuggestionsContainer={renderSuggestionsContainer}
        onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={handleSuggestionsClearRequested}
      />
    </StyledWrapper>
  );
}

const SuggestionsContainer = styled.div`
  position: absolute;
  margin-top: -40px;
  z-index: 1;
  left: 0;
  right: 0;
`;

const Suggestion = styled.div<{ selected: boolean }>`
  ${({ theme }) => theme.typography.text};
  font-size: 12px;
  line-height: 1.22;
  text-transform: capitalize;
  padding: 10px;
  cursor: pointer;
  max-width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.lightBlue : theme.colors.background};
  color: ${({ theme, selected }) =>
    selected ? theme.colors.white : theme.colors.midDarkGrey};
`;

const StyledWrapper = styled.div`
  .react-autosuggest__container {
    position: relative;
  }
`;
