import React, { FunctionComponent } from 'react';
import styled from '@emotion/styled';

import { useToggleSidebar } from 'stores/ui/sidebar-ui.store';

export const ScrollableContainer: FunctionComponent = ({ children }) => {
  const [isOpen] = useToggleSidebar();

  return <Container isOpen={isOpen}>{children}</Container>;
};

const Container = styled.div<{ isOpen: boolean }>`
  width: ${({ isOpen, theme }) =>
    isOpen
      ? `calc(100vw - ${theme.sidebarWidth.opened})`
      : `calc(100vw - ${theme.sidebarWidth.closed})`};
  max-width: ${({ isOpen, theme }) =>
    isOpen
      ? `calc(100vw - ${theme.sidebarWidth.opened})`
      : `calc(100vw - ${theme.sidebarWidth.closed}
)`};
  margin-left: ${({ isOpen, theme }) =>
    isOpen ? theme.sidebarWidth.opened : theme.sidebarWidth.closed};
  transition: ease-in 0.3s;
`;
