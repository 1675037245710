import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import isEmpty from 'lodash/fp/isEmpty';
import includes from 'lodash/fp/includes';
import { NumericDictionary } from 'lodash';

import { ReactComponent as Pencil } from 'assets/images/pencil.svg';

import { theme } from 'constants/themes.constants';

import { useIsPermissionsModalOpen } from 'stores/ui/permission-modal-ui.store';
import {
  useCurrentWalletName,
  useCurrentWalletPublicKey,
  useIsNodeAdmin,
  useNodeAdminWallet
} from 'stores/governance/governance.store';
import { useDeleteRule } from 'stores/wallet/delete-rule.store';
import { useAddRule } from 'stores/wallet/add-rule.store';
import { Rule, useRules } from 'stores/wallet/rules.store';
import { useRulesNotifications } from 'hooks/notifications/rules-notifications.hooks.ts';

import { walletRulesSelector } from 'selectors/wallet-rules.selector';

import { Modal } from 'components/common/modal/modal';
import { ModalTitle } from 'components/common/modal/modal-title';
import { ModalSubtitle } from 'components/common/modal/modal-subtitle';
import { PermissionItem } from 'components/network-governance/permission-modal-permission-item';
import { Text, Title } from 'components/common/typography';
import { WarningLabel } from 'components/common/warning-label';
import { Button } from 'components/common/button';
import { TextField } from 'components/common/text-field';
import { Switch } from 'components/common/switch';

export const PermissionModal = () => {
  const [deletedRulesIndexes, setDeletedRulesIndexes] = useState<
    NumericDictionary<boolean>
  >({});
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const [newRuleNamespace, setNewRuleNamespace] = useState('');
  const [isNamespaceValid, setIsNamespaceValid] = useState(false);
  const [isNewRuleConfidential, setIsNewRuleConfidential] = useState(false);

  const walletRules = walletRulesSelector();
  const { fetchRules } = useRules();
  const { deleteWalletRule } = useDeleteRule();
  const { addWalletRule } = useAddRule();
  const { modifyRules } = useRulesNotifications();
  const [, setIsOpen] = useIsPermissionsModalOpen();
  const [publicKey, setPublicKey] = useCurrentWalletPublicKey();
  const [nodeAdminWallet] = useNodeAdminWallet();
  const [walletName, setWalletName] = useCurrentWalletName();
  const [isNodeAdmin] = useIsNodeAdmin();

  const title = 'ISSUANCE PERMISSIONS';
  const subtitle = `Wallet Name: ${walletName ? walletName : 'N/A'}`;

  const addToDeletedRules = (index: number) => {
    if (deletedRulesIndexes[index]) {
      return;
    }

    setDeletedRulesIndexes({
      ...deletedRulesIndexes,
      [index]: true
    });
  };

  const removeFromDeletedRules = (index: number) => {
    if (!deletedRulesIndexes[index]) {
      return;
    }
    const newState = { ...deletedRulesIndexes };
    delete newState[index];
    setDeletedRulesIndexes(newState);
  };

  const isNewNamespaceValid = () => {
    const isValid = /^[a-zA-Z0-9_.*]*$/.test(newRuleNamespace);
    setIsNamespaceValid(isValid);
  };

  const isChangesValid = () => {
    return !isEmpty(newRuleNamespace) || !isEmpty(deletedRulesIndexes);
  };

  const applyChanges = async () => {
    if (!isChangesValid()) {
      return;
    }

    const { auth, wallet_id } = nodeAdminWallet;
    const deletedIndexes = Object.keys(deletedRulesIndexes).map((key: string) =>
      parseInt(key)
    );

    if (!isEmpty(deletedIndexes)) {
      const rulesToDelete = walletRules.filter((rule: Rule, index: number) =>
        includes(index, deletedIndexes)
      );

      if (!isEmpty(rulesToDelete)) {
        await deleteWalletRule(
          wallet_id,
          auth,
          rulesToDelete,
          ({ id }: { id: string }) => modifyRules(id, walletName)
        );
      }
    }

    if (newRuleNamespace && publicKey) {
      const newRule: Rule = {
        can_issue_confidentially: isNewRuleConfidential,
        is_admin: false,
        namespace: newRuleNamespace,
        public_key: publicKey
      };

      await addWalletRule(
        wallet_id,
        auth,
        [newRule],
        ({ id }: { id: string }) => modifyRules(id, walletName)
      );
    }

    setIsOpen(false);
  };

  useEffect(() => {
    if (!isEmpty(deletedRulesIndexes)) {
      setIsWarningOpen(true);
      return;
    }

    setIsWarningOpen(false);
  }, [deletedRulesIndexes]);

  useEffect(() => {
    return () => {
      fetchRules();
      setPublicKey(null);
      setWalletName(null);
    };
  }, []);

  useEffect(() => {
    isNewNamespaceValid();
  }, [newRuleNamespace]);

  return (
    <Modal onClose={() => setIsOpen(false)} paddingLeft={0} paddingRight={0}>
      <ModalTitle text={title} align="center" margin="0 0 15px" />
      <ModalSubtitle text={subtitle} align="center" margin="0 0 15px" />
      <HR />
      <Warning />
      <FormContainer>
        <FormHeader>
          <Title>Asset ID Namespace</Title>
          <Title>Confidential Issuance</Title>
        </FormHeader>
        {isNodeAdmin && (
          <NewRuleContainer>
            <NewRuleNamespace>
              <TextField
                value={newRuleNamespace}
                onChange={value => setNewRuleNamespace(value)}
                showIcon={true}
                autoFocus={true}
                iconComponent={<PencilIcon />}
                iconClass="pencil"
                iconHighlight={
                  isNamespaceValid
                    ? theme.colors.lightBlue
                    : theme.colors.midDarkGrey
                }
                helperText="Namespaces may include only alphanumeric characters"
                error={!isNamespaceValid}
              />
            </NewRuleNamespace>
            <NewRuleConfidentiality>
              <Switch
                isChecked={isNewRuleConfidential}
                onChange={value => setIsNewRuleConfidential(value)}
                disabled={false}
              />
            </NewRuleConfidentiality>
          </NewRuleContainer>
        )}

        <PermissionsList>
          {walletRules.map((rule: Rule, index: number) => (
            <PermissionItem
              key={index}
              index={index}
              rule={rule}
              isEditable={isNodeAdmin}
              isDeleted={deletedRulesIndexes[index]}
              deletePermission={index => addToDeletedRules(index)}
              removeDelete={index => removeFromDeletedRules(index)}
            />
          ))}
        </PermissionsList>
      </FormContainer>
      <WarningLabel
        isOpen={isWarningOpen}
        backgroundColor={theme.colors.warningRed}
        fill={theme.colors.warningIconRed}>
        Deleting this permission cannot be undone.
      </WarningLabel>
      <ButtonsContainer>
        <Cancel onClick={() => setIsOpen(false)}>Cancel</Cancel>
        {isNodeAdmin && (
          <Button
            isDisabled={
              (isEmpty(newRuleNamespace) && isEmpty(deletedRulesIndexes)) ||
              !isNamespaceValid
            }
            onClick={applyChanges}
            text="APPLY CHANGES"
            variant="primary"
            width="200px"
            height="45px"
            fontSize="12px"
            fontWeight="600"
          />
        )}
      </ButtonsContainer>
    </Modal>
  );
};

const HR = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGrey};
  height: 1px;
  opacity: 0.88;
`;

const Warning = styled.div`
  width: 1000px;
`;

const FormContainer = styled.div`
  margin-left: 115px;
  margin-right: 50px;
`;

const FormHeader = styled.div`
  color: ${({ theme }) => theme.colors.midDarkGrey};
  display: grid;
  grid-template-columns: 70% 30%;
  padding: 15px 0;
`;

const NewRuleContainer = styled.div`
  display: grid;
  grid-template-columns: 70% 30%;
  align-items: center;
  margin-bottom: 25px;

  .input {
    margin-bottom: 0;
  }
`;

const NewRuleNamespace = styled.div`
  padding-right: 110px;

  .helper-text {
    margin-bottom: 0;
    margin-top: 5px;
  }
`;

const NewRuleConfidentiality = styled.div`
  padding-left: 60px;
`;

const PermissionsList = styled.div`
  max-height: 250px;
  margin-bottom: 15px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 135px;
`;

const Cancel = styled(Text)`
  color: ${({ theme }) => theme.colors.midDarkGrey};
  cursor: pointer;
`;

const PencilIcon = styled(Pencil)`
  height: 20px;
  width: 20px;
`;
