import React, { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box, Flex } from '@rebass/grid/emotion';
import { get, isEmpty } from 'lodash/fp';
import qs from 'qs';

import { Form } from 'components/login/form';
import { Button } from 'components/common/button';
import { ForgotPasswordModal } from 'components/login/forgot-password-modal';
import { ResetPasswordModal } from 'components/login/reset-password-modal';

import background from 'assets/images/login-background.jpg';
import waves from 'assets/images/waves.png';
import logo from 'assets/images/logo.svg';

import { AuthContext } from 'context/auth.context';

import {
  useIsResetPasswordModalOpen,
  useIsForgotPasswordOpen
} from 'stores/ui/password-modal-ui.store.ts';

import { ResetPasswordSearchParams } from 'types/login.types';

import history from 'utils/history.utils';

export const Login = () => {
  const [badCredentials, setBadCredentials] = useState(false);
  const [searchParams, setSearchParams] = useState<ResetPasswordSearchParams>();
  const [
    isForgotPasswordModalOpen,
    setIsForgotPasswordModalOpen
  ] = useIsForgotPasswordOpen();
  const [
    isResetPasswordModalOpen,
    setIsResetPasswordModalOpen
  ] = useIsResetPasswordModalOpen();

  const { isAuthenticated } = useContext(AuthContext);
  const { user } = useContext(AuthContext);

  if (isAuthenticated === 'authorized' && user) {
    history.push(`/dashboard`);
  }

  useEffect(() => {
    if (history.location.pathname === '/login/reset-password') {
      const { oobCode } = qs.parse(history.location.search, {
        ignoreQueryPrefix: true
      });

      if (isEmpty(oobCode)) {
        history.push('/login');
        return;
      }

      setSearchParams({ oobCode });
      setIsResetPasswordModalOpen(true);
    }
  }, [history.location.pathname]);

  return (
    <Container>
      {isForgotPasswordModalOpen && <ForgotPasswordModal />}
      {isResetPasswordModalOpen && (
        <ResetPasswordModal searchParams={searchParams} />
      )}
      {/*TODO: conditional render*/}
      <OverFlowImage />
      <LoginSide>
        <LoginContentWrapper>
          <Box marginBottom="40px">
            <Logo />
            <Error badCredentials={badCredentials}>
              Incorrect email or password.
            </Error>
            <Form handleBadCredentials={value => setBadCredentials(value)} />
            <ForgotPassword onClick={() => setIsForgotPasswordModalOpen(true)}>
              Forgot your password?
            </ForgotPassword>
          </Box>
          <Box mb="135px">
            <NotACustomer>Not a customer?</NotACustomer>
            <Flex justifyContent="center" alignItems="center">
              <Button
                text="REQUEST A DEMO"
                variant="tertiary"
                width="325px"
                onClick={() => window.open('https://qed-it.com', '_blank')}
              />
            </Flex>
          </Box>
        </LoginContentWrapper>
      </LoginSide>
      <InfoSide>
        <InfoContentWrapper>
          <HighlightedTitle>A privacy layer</HighlightedTitle>
          <Title>for your enterprise blockchain</Title>
        </InfoContentWrapper>
      </InfoSide>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  background: url(${background}) no-repeat center;
`;

const OverFlowImage = styled.div`
  z-index: 2;
  height: 100%;
  width: 100%;
  position: absolute;
  background: url(${waves}) no-repeat center;
  background-size: 60%;
  background-position: bottom -6vw right 0;
  pointer-events: none;
`;

const LoginSide = styled.div`
  z-index: 1;
  flex: 50%;
  max-width: 50%;
  background-image: ${({ theme }) => theme.colors.greyGradient};
  display: flex;
  justify-content: center;
  align-items: start;
  padding-top: 200px;

  @media (max-width: 1800px) and (max-height: 1000px) {
    padding-top: 150px;
  }
`;

const LoginContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 430px;
`;

const Logo = styled.div`
  background: url(${logo}) no-repeat center;
  width: 305px;
  height: 115px;
  margin: 10px auto 25px;
`;

const Error = styled.div<{ badCredentials: boolean }>`
  ${({ theme }) => theme.typography.text};
  color: ${({ theme }) => theme.colors.midDarkRed};
  font-size: 15px;
  height: 25px;
  opacity: 0;
  text-align: center;

  ${({ badCredentials }) =>
    badCredentials &&
    `
    opacity: 1;
  `}
`;

const ForgotPassword = styled.div`
  ${({ theme }) => theme.typography.text};
  color: ${({ theme }) => theme.colors.midDarkGrey};
  text-decoration: underline;
  text-align: center;
  cursor: pointer;
  margin-top: 15px;
`;

const NotACustomer = styled.div`
  ${({ theme }) => theme.typography.text};
  color: ${({ theme }) => theme.colors.lightBlue};
  text-align: center;
  cursor: pointer;
  margin-bottom: 15px;
`;

const InfoSide = styled.div`
  z-index: 1;
  flex: 50%;
  max-width: 50%;
  background-color: rgba(27, 31, 35, 0.8);
  padding-top: 200px;
  display: flex;
  justify-content: center;
  align-items: start;

  @media (max-width: 1800px) and (max-height: 1000px) {
    padding-top: 150px;
  }
`;

const InfoContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 80%;
  div {
    width: 100%;
  }
`;

const Title = styled.div`
  ${({ theme }) => theme.typography.title};
  color: ${({ theme }) => theme.colors.white};
  font-size: 34px;
`;

const HighlightedTitle = styled.div`
  ${({ theme }) => theme.typography.title};
  color: ${({ theme }) => theme.colors.lightBlue};
  font-size: 34px;
  font-weight: 600;
`;
