import React, { useEffect, useState, useRef } from 'react';
import styled from '@emotion/styled';

import Modal from 'components/common/modal/modal';
import { Button } from 'components/common/button';
import { ModalTitle as Title } from 'components/common/modal/modal-title';
import { ModalSubtitle as Subtitle } from 'components/common/modal/modal-subtitle';
import {
  TextField,
  TextFieldContainer,
  ValidIcon
} from 'components/common/text-field';

import {
  handleFirebasePasswordReset,
  signIn
} from 'hooks/firebase/firebase.auth.hooks';

import { validatePassword } from 'utils/login.utils';

import { ResetPasswordSearchParams } from 'types/login.types';

import { useIsResetPasswordModalOpen } from 'stores/ui/password-modal-ui.store.ts';
import check from 'assets/images/green-check-icon.svg';

interface Props {
  searchParams?: ResetPasswordSearchParams;
}

export const ResetPasswordModal = ({ searchParams }: Props) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isNewPasswordValid, setIsNewPasswordValid] = useState(false);
  const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(false);
  const [isResetSuccess, setIsResetSuccess] = useState(false);
  const [, setIsResetPasswordModalOpen] = useIsResetPasswordModalOpen();
  const [isError, setIsError] = useState(false);
  const handleResetPassword = () => {
    if (
      newPassword !== confirmPassword ||
      !isNewPasswordValid ||
      !isConfirmPasswordValid
    ) {
      setIsError(true);
      return;
    }

    if (searchParams) {
      const { oobCode } = searchParams;

      // TODO: handle invalid actionCode (returned by firebase)
      handleFirebasePasswordReset(oobCode, newPassword)
        .then(email => {
          setIsResetSuccess(true);
          setTimeout(() => {
            signIn(email, newPassword);
            setIsResetPasswordModalOpen(false);
          }, 1500);
        })
        .catch(e => console.log('error: ', e));
    }
  };

  useEffect(() => {
    if (validatePassword(newPassword)) {
      setIsNewPasswordValid(true);
      return;
    }
    setIsNewPasswordValid(false);
  }, [newPassword]);

  useEffect(() => {
    if (
      validatePassword(confirmPassword) &&
      isNewPasswordValid &&
      newPassword === confirmPassword
    ) {
      setIsConfirmPasswordValid(true);
      return;
    }
    setIsConfirmPasswordValid(false);
  }, [newPassword, confirmPassword, isNewPasswordValid]);

  return (
    <Modal onClose={() => setIsResetPasswordModalOpen(false)}>
      <Container>
        {isResetSuccess ? (
          <>
            <Title
              text="Reset your password"
              margin="0 0 30px"
              align="center"
            />
            <Subtitle
              text="Your password has been successfully reset."
              margin="0 0 10px"
              align="center"
            />
          </>
        ) : (
          <>
            <Title
              text="Reset your password"
              margin="0 0 30px"
              align="center"
            />
            <Subtitle
              text="We received a request to reset your password."
              margin="0 0 10px"
              align="center"
            />
            <Subtitle
              text="Passwords must be at least 6 characters long and include both symbols and numbers."
              margin="0 0 30px"
              align="center"
              isError={isError}
            />
            <TextFieldContainer>
              <TextField
                type="password"
                label="New password"
                value={newPassword}
                onChange={setNewPassword}
                showIcon={isNewPasswordValid}
                iconComponent={<ValidIcon />}
              />
            </TextFieldContainer>
            <TextFieldContainer>
              <TextField
                type="password"
                label="Confirm new password"
                value={confirmPassword}
                onChange={setConfirmPassword}
                showIcon={isConfirmPasswordValid}
                iconComponent={<ValidIcon />}
              />
            </TextFieldContainer>
            <ButtonContainer>
              <Button
                onClick={handleResetPassword}
                variant={'primary'}
                text={'RESET PASSWORD'}
                width="280px"
                fontSize={'15px'}
              />
            </ButtonContainer>
          </>
        )}
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  width: 600px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  margin-top: 50px;
`;
