import { useContext, useState } from 'react';
import { createStore } from 'reusable';
import get from 'lodash/fp/get';

import { AuthContext } from 'context/auth.context';
import { useApiRequest } from 'hooks/api.hooks';
import { PublicKey } from 'types/wallet.types';

const PUBLIC_KEYS_LABEL = 'PUBLIC KEYS label';

export const usePublicKeysOfWallets = createStore(() => {
  // TODO: how to handle error
  const apiRequest = useApiRequest();
  const { user } = useContext(AuthContext);
  const [publicKeys, setPublicKeys] = useState<PublicKey[]>([]);
  const [error, setError] = useState(null);

  const address = get('node.address', user);
  const authToken = get('node.auth_token', user);

  const fetchPublicKeys = async (walletIds: string[]) => {
    const allRequests: Promise<any>[] = [];
    walletIds.forEach(walletId => {
      allRequests.push(
        apiRequest({
          path: 'wallet/get_public_key',
          method: 'POST',
          baseUrl: address,
          headers: { 'x-auth-token': authToken },
          data: { wallet_id: walletId },
          networkLabel: PUBLIC_KEYS_LABEL,
          onError: console.error
        })
      );
    });

    const publicKeys: PublicKey[] = await Promise.all(allRequests);

    setPublicKeys(publicKeys.filter(key => !!key));
  };

  return {
    publicKeys,
    error,
    fetchPublicKeys
  };
});
