import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { Flex, Box } from '@rebass/grid/emotion';
import MDSpinner from 'react-md-spinner';
import moment from 'moment';
import get from 'lodash/fp/get';

import transfersIcon from 'assets/images/transfers.svg';
import issuanceIcon from 'assets/images/issuance.svg';
import checkIcon from 'assets/images/confirmation-check.svg';
import rejectIcon from 'assets/images/reject-icon.svg';

import { AuthContext } from 'context/auth.context';

import { theme } from 'constants/themes.constants';

import { useUserTransactions } from 'stores/user/node-transactions.store';
import { useTransfer } from 'stores/wallet/transfers.store';
import { useGetTaskStatus } from 'stores/wallet/task-status.store';

import { NotificationTitle } from 'components/notifications-handler/notification-title';
import { MediumText, Text } from 'components/common/typography';

import { getWalletAuth } from 'utils/wallet.utils';

import { Result as TaskResult, ModifiedTask, Task } from 'types/tasks.types';
import { Wallet } from 'types/firebase.types';

interface Props {
  transaction: ModifiedTask;
}

export const Notification = ({ transaction }: Props) => {
  const [isClosed, setIsClosed] = useState(false);
  const [createdAt, setCreatedAt] = useState<null | string>(null);
  const [isCanceled, setIsCanceled] = useState(false);

  const {
    blackListRef,
    setTransactions,
    transactionsRef
  } = useUserTransactions();
  const { confirmTransfer, rejectTransfer } = useTransfer();
  const { fetchTaskStatus } = useGetTaskStatus();

  const wallets: Wallet[] = get('user.wallets', useContext(AuthContext));

  const transactionType: string = transaction.type;

  const result: TaskResult = transaction.result;

  const handleIsCloseNotificationNotAvailable = (result: TaskResult) =>
    result === 'awaiting_user_interaction' ||
    (transactionType === 'cancellation' && result !== 'success') ||
    (transactionType === 'outgoing_confirm_request' && result !== 'success');

  const handleConfirm = (taskId: string, walletId: string) => {
    const walletAuth = getWalletAuth(walletId, wallets);
    confirmTransfer(taskId, walletAuth)
      .then(() => {
        transactionsRef.current = {
          ...transactionsRef.current,
          [taskId]: {
            ...transactionsRef.current[taskId],
            result: 'confirmed'
          }
        };

        setTransactions({ ...transactionsRef.current });
      })
      .catch((e: any) => console.log('error:', e));
  };

  const handleReject = (taskId: string, walletId: string) => {
    const walletAuth = getWalletAuth(walletId, wallets);
    rejectTransfer(taskId, walletAuth)
      .then(() => {
        delete transactionsRef.current[taskId];
        setTransactions({ ...transactionsRef.current });
      })
      .catch((e: any) => console.log('error:', e));
  };

  const handleCancel = (transaction: ModifiedTask) => {
    if (!transaction.sender || isCanceled) {
      return;
    }

    const walletAuth = getWalletAuth(transaction.sender, wallets);
    rejectTransfer(transaction.id, walletAuth)
      .then(({ id }: { id: string }) => {
        setIsCanceled(true);
        // Terminate fetchAndSetTaskStatus polling functionality by adding to blacklist
        blackListRef.current = {
          ...blackListRef.current,
          [transaction.id]: true
        };

        const fetchRejectedTransferStatus = () => {
          fetchTaskStatus(id).then((status: Task) => {
            if (
              status.result === 'pending' ||
              status.result === 'in_progress'
            ) {
              if (
                transactionsRef.current[transaction.id].type !== 'cancellation'
              ) {
                transactionsRef.current = {
                  ...transactionsRef.current,
                  [transaction.id]: {
                    ...transactionsRef.current[transaction.id],
                    type: 'cancellation'
                  }
                };
                setTransactions({ ...transactionsRef.current });
              }
              setTimeout(fetchRejectedTransferStatus, 3000);

              return;
            }

            if (status.result === 'success') {
              transactionsRef.current = {
                ...transactionsRef.current,
                [transaction.id]: {
                  ...transactionsRef.current[transaction.id],
                  result: 'success'
                }
              };

              setTransactions({ ...transactionsRef.current });
              return;
            }

            if (status.result === 'failure') {
              transactionsRef.current = {
                ...transactionsRef.current,
                [transaction.id]: {
                  ...transactionsRef.current[transaction.id],
                  result: 'failure'
                }
              };

              setTransactions({ ...transactionsRef.current });
            }
          });
        };

        fetchRejectedTransferStatus();
      })
      .catch((e: any) => console.log('error in handleCancel', e));
  };

  const handleClose = () => {
    blackListRef.current = { ...blackListRef.current, [transaction.id]: true };
    delete transactionsRef.current[transaction.id];
    setTransactions({ ...transactionsRef.current });
    setIsClosed(true);
  };

  useEffect(() => {
    if (transaction.result && createdAt) {
      return;
    }

    setCreatedAt(moment(transaction.createdAt).fromNow());

    const interval = setInterval(() => {
      setCreatedAt(moment(transaction.createdAt).fromNow());
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Container isClosed={isClosed}>
      <Flex justifyContent="space-between" width="100%">
        <Flex>
          <Box mr={'10px'}>
            {result === 'success' ||
            result === 'failure' ||
            result === 'confirmed' ||
            result === 'canceled' ? (
              transactionType === 'issue_asset' ? (
                <IssuanceIcon />
              ) : (
                <TransferIcon />
              )
            ) : (
              <MDSpinner
                singleColor={theme.colors.midDarkGrey}
                size={20}
                borderSize={1}
              />
            )}
          </Box>
          <Flex flexDirection="column">
            <NotificationTitle transaction={transaction} />
            <TimeAgo>{createdAt}</TimeAgo>
          </Flex>
        </Flex>
        <Flex flexDirection="column" justifyContent="space-between">
          {!handleIsCloseNotificationNotAvailable(result) && (
            <RemoveButton onClick={handleClose}>X</RemoveButton>
          )}
          {transactionType === 'outgoing_confirm_request' &&
            result === 'in_progress' && (
              <CancelButton onClick={() => handleCancel(transaction)}>
                <CancelIcon className="cancel-icon">X</CancelIcon>
                CANCEL
              </CancelButton>
            )}
          {result === 'awaiting_user_interaction' && (
            <Flex mt="auto">
              <ConfirmationContainer
                onClick={() =>
                  handleReject(transaction.id, transaction.recipient)
                }>
                <RejectCircle>
                  <RejectIcon />
                </RejectCircle>
                REJECT
              </ConfirmationContainer>
              <ConfirmationContainer
                onClick={() =>
                  handleConfirm(transaction.id, transaction.recipient)
                }>
                <ConfirmCircle>
                  <ConfirmIcon />
                </ConfirmCircle>
                CONFIRM
              </ConfirmationContainer>
            </Flex>
          )}
          {result === 'confirmed' && (
            <ConfirmationContainer>
              <ConfirmCircle>
                <ConfirmIcon />
              </ConfirmCircle>
              CONFIRMED
            </ConfirmationContainer>
          )}
          {result === 'failure' || result === 'canceled' ? (
            <TransactionFailed>TRANSACTION FAILED</TransactionFailed>
          ) : null}
          {/* TODO: uncomment in next stage*/}
          {/*{status === 'success' ? (*/}
          {/*  <ViewTransaction*/}
          {/*    to={{*/}
          {/*      pathname: `/wallet/${transaction.sender}`,*/}
          {/*      state: { txHash: transaction.tx_hash }*/}
          {/*    }}>*/}
          {/*    VIEW TRANSACTION*/}
          {/*  </ViewTransaction>*/}
          {/*) : status === 'failure' ? (*/}
          {/*  <TransactionFailed>TRANSACTION FAILED</TransactionFailed>*/}
          {/*) : null}*/}
        </Flex>
      </Flex>
    </Container>
  );
};

const Container = styled.div<{ isClosed: boolean }>`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 23px 20px;
  display: flex;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  }

  ${({ isClosed }) => isClosed && ` display: none`};
`;

const TransferIcon = styled.div`
  background: url(${transfersIcon}) no-repeat center;
  background-size: contain;
  height: 20px;
  width: 25px;
`;

const IssuanceIcon = styled.div`
  background: url(${issuanceIcon}) no-repeat center;
  background-size: contain;
  height: 20px;
  width: 25px;
`;

const TimeAgo = styled(Text)`
  color: ${({ theme }) => theme.colors.midDarkGrey};
  font-size: 12px;
`;

const TransactionFailed = styled(MediumText)`
  color: ${({ theme }) => theme.colors.darkRed};
  font-size: 13px;
  font-weight: 600;
`;

const ConfirmationContainer = styled(TransactionFailed)`
  color: ${({ theme }) => theme.colors.midDarkGrey};
  display: flex;
  align-items: center;
  text-transform: uppercase;
  cursor: pointer;

  &:last-child {
    color: ${({ theme }) => theme.colors.lightBlue};
    margin-left: 15px;
  }
`;

const ConfirmCircle = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.lightBlue};
  border-radius: 50%;
  height: 17px;
  width: 17px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RejectCircle = styled(ConfirmCircle)`
  border: 1px solid ${({ theme }) => theme.colors.midDarkGrey};
`;

const RejectIcon = styled.div`
  background: url(${rejectIcon}) no-repeat center;
  background-size: contain;
  width: 15px;
  height: 15px;
`;

const ConfirmIcon = styled.div`
  background: url(${checkIcon}) no-repeat center;
  background-size: contain;
  width: 9px;
  height: 9px;
`;

const CancelButton = styled.button`
  outline: none;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  margin-top: auto;

  font-family: Montserrat-Medium;
  color: ${({ theme }) => theme.colors.midDarkGrey};
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;

  &:active {
    color: ${({ theme }) => theme.colors.lightBlue};
    text-decoration: underline;

    .cancel-icon {
      background-color: ${({ theme }) => theme.colors.lightBlue};
    }
  }
`;

const CancelIcon = styled.div`
  font-family: Montserrat-Regular;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  background-color: ${({ theme }) => theme.colors.midDarkGrey};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  height: 17px;
  width: 17px;
  margin-right: 5px;
`;

const ViewTransaction = styled(Link)`
  font-family: Montserrat-Medium;
  font-size: 13px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.lightBlue};
  cursor: pointer;
  text-decoration: none;
`;

const RemoveButton = styled.div`
  background-color: ${({ theme }) => theme.colors.grey};
  font-family: Montserrat-Regular;
  font-size: 12px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  cursor: pointer;
`;
