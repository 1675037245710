import React, { useContext, useEffect, useState, useRef } from 'react';
import { isEmpty, get } from 'lodash/fp';

import history from 'utils/history.utils';

import { Button } from 'components/common/button';

import { AuthContext } from 'context/auth.context';

import { signIn } from 'hooks/firebase/firebase.auth.hooks';
import { TextField } from 'components/common/text-field';
import styled from '@emotion/styled';

interface Credentials {
  email: string;
  password: string;
}

interface Props {
  handleBadCredentials: (value: boolean) => void;
}

export const Form = ({ handleBadCredentials }: Props) => {
  const { isAuthenticated, user } = useContext(AuthContext);
  const [credentials, setCredentials] = useState<Credentials>({
    email: '',
    password: ''
  });
  const [isLogged, setIsLogged] = useState(false);

  const handleError = (error: any) => {
    handleBadCredentials(true);
  };

  const handleSubmit = (e?: Event) => {
    if (e) {
      e.preventDefault();
    }
    const { email, password } = credentials;
    // TODO: handle memory leak
    if (!isEmpty(email) && !isEmpty(password)) {
      signIn(email, password)
        .then(() => setIsLogged(true))
        .catch((error: any) => handleError(error));
    }
  };

  useEffect(() => {
    const { email, password } = credentials;

    if (isEmpty(email) && isEmpty(password)) {
      handleBadCredentials(false);
    }
  }, [credentials]);

  useEffect(() => {
    if (isAuthenticated === 'authorized' && user && isLogged) {
      history.push(`/dashboard`);
    }
  }, [isAuthenticated, user, isLogged]);

  return (
    <form>
      <TextFieldContainer>
        <TextField
          type="text"
          autoFocus={true}
          placeholder="Email"
          value={credentials.email}
          onChange={value => setCredentials({ ...credentials, email: value })}
        />
      </TextFieldContainer>
      <PasswordContainer>
        <TextField
          type="password"
          placeholder="Password"
          value={credentials.password}
          onChange={value =>
            setCredentials({ ...credentials, password: value })
          }
        />
      </PasswordContainer>
      <Button
        type="submit"
        text="LOGIN"
        variant="primary"
        onClick={handleSubmit}
      />
    </form>
  );
};

const TextFieldContainer = styled.div`
  .input {
    border-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.white};
    padding-top: 0;
    padding-bottom: 8px;
    font-size: 18px;

    &:focus {
      padding-top: 0;
      padding-bottom: 8px;
      border-bottom-width: 1px;
      border-bottom-color: ${({ theme }) => theme.colors.white};
    }
  }
`;

const PasswordContainer = styled(TextFieldContainer)`
  .input {
    margin-bottom: 25px;
  }
`;

export default Form;
