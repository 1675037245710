import React from 'react';
import styled from '@emotion/styled';
import { map, isEmpty } from 'lodash/fp';

import BlockExplorerTableHeader from './block-explorer-table-header';
import BlockExplorerTableRow from './block-explorer-table-row';
import InfiniteScroll from 'components/common/infinite-scroll';

import useBlockExplorerPolling from 'hooks/block-explorer.hooks';

import { NetworkActivityTransaction } from 'types/network-activity.types';

const BlockExplorerTable = () => {
  const {
    transactions,
    fetchPreviousTransactions,
    isLoading
  } = useBlockExplorerPolling();

  const hasMore = !isLoading;

  return (
    <Container>
      <HeaderContainer>
        <BlockExplorerTableHeader />
      </HeaderContainer>
      <ContentContainer>
        <InfiniteScroll
          loadMore={fetchPreviousTransactions}
          hasMore={hasMore}
          isInitialLoading={isEmpty(transactions)}
          isLoadingMore={isLoading}>
          {map(
            (transaction: NetworkActivityTransaction) => (
              <BlockExplorerTableRow
                key={transaction.metadata.tx_hash}
                transaction={transaction}
              />
            ),
            transactions
          )}
        </InfiniteScroll>
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const HeaderContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  margin-bottom: 15px;
  padding: 0 16px;
  flex: 0 1 0;
`;

const ContentContainer = styled.div`
  flex: 1 0 0;
  padding: 0 5px;

  max-height: 100%;
  overflow-y: auto;

  ::-webkit-scrollbar {
    display: none;
  }

  &:hover {
    ${({ theme }) => theme.scrollbar.main};
  }
`;

export default BlockExplorerTable;
