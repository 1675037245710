import React from 'react';
import styled from '@emotion/styled';

interface Props {
  text: string;
  margin?: string;
  padding?: string;
  align?: string;
}

export const ModalTitle = ({ text, margin, padding, align }: Props) => {
  return (
    <>
      <Title margin={margin} padding={padding} align={align}>
        {text}
      </Title>
    </>
  );
};

const Title = styled.div<{ margin?: string; padding?: string; align?: string }>`
  ${({ theme }) => theme.typography.boldText};
  color: ${({ theme }) => theme.colors.midDarkGrey};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  text-align: ${({ align }) => align};
`;
