import React from 'react';
import styled from '@emotion/styled';

interface Props {
  text: string;
  isError?: boolean;
  margin?: string;
  padding?: string;
  align?: string;
}

export const ModalSubtitle = ({
  text,
  isError,
  margin,
  padding,
  align
}: Props) => {
  return (
    <>
      <Subtitle
        margin={margin}
        padding={padding}
        align={align}
        isError={isError}>
        {text}
      </Subtitle>
    </>
  );
};

const Subtitle = styled.div<{
  isError?: boolean;
  margin?: string;
  padding?: string;
  align?: string;
}>`
  ${({ theme }) => theme.typography.text};
  color: ${({ theme, isError }) =>
    isError ? theme.colors.errorRed : theme.colors.midDarkGrey};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  text-align: ${({ align }) => align};
  font-size: 15px;
  font-weight: 500;
`;
