import { createStore } from 'reusable';
import { filter } from 'lodash/fp';

import { useRules } from 'stores/wallet/rules.store';
import { useCurrentWalletPublicKey } from 'stores/governance/governance.store';

export const walletRulesSelector = createStore(() => {
  const [currentPublicKey] = useCurrentWalletPublicKey();
  const { rules } = useRules();

  return filter(rule => {
    return rule.public_key === currentPublicKey;
  }, rules);
});
