import { useContext } from 'react';
import { createStore } from 'reusable';
import get from 'lodash/fp/get';

import { AuthContext } from 'context/auth.context';

import { useApiRequest } from 'hooks/api.hooks';
import { useTransferAndIssuanceNotifications } from 'hooks/notifications/transfer-issuance-notifications.hooks';

import { TransferDetails } from 'types/wallet.types';

const TRANSFER_LABEL = 'transfer label';

export const useTransfer = createStore(() => {
  const { user } = useContext(AuthContext);
  const apiRequest = useApiRequest();

  const {
    fetchAndSetTransferAndIssuanceNotifications
  } = useTransferAndIssuanceNotifications();

  const authToken = get('node.auth_token', user);
  const urlAddress = get('node.address', user);

  const confirmTransfer = (taskId: string, auth: string) => {
    return apiRequest({
      path: '/node/approve_task',
      method: 'POST',
      baseUrl: urlAddress,
      headers: { 'x-auth-token': authToken },
      data: {
        task_id: taskId,
        authorization: auth
      }
    });
  };

  const rejectTransfer = (taskId: string, auth: string) => {
    return apiRequest({
      path: '/node/cancel_task',
      method: 'POST',
      baseUrl: urlAddress,
      headers: { 'x-auth-token': authToken },
      data: {
        task_id: taskId,
        authorization: auth
      }
    });
  };

  const transferAsset = (transferDetails: TransferDetails) => {
    const { asset_id, amount, to, from, isConfirmRequired } = transferDetails;
    const recipientAddress = get('value.recipient_address', to);
    const publicKey = get('value.public_key', to);
    const recipientName = get('text', to);
    const auth = get('value.auth', from);
    const walletId = get('value.id', from);
    const assetId = get('value', asset_id);

    return apiRequest({
      path: 'wallet/transfer_asset',
      method: 'POST',
      baseUrl: urlAddress,
      headers: { 'x-auth-token': authToken },
      data: {
        wallet_id: walletId,
        authorization: auth,
        recipient_address: recipientAddress,
        amount,
        asset_id: assetId,
        memo: '',
        require_confirmation_from:
          isConfirmRequired && publicKey ? publicKey : undefined
      },
      args: {
        amount,
        assetId,
        sender: walletId,
        recipient: recipientName,
        type: 'transfer_asset',
        isConfirmRequired
      },
      networkLabel: TRANSFER_LABEL,
      onSuccess: fetchAndSetTransferAndIssuanceNotifications
    });
  };

  return {
    transferAsset,
    confirmTransfer,
    rejectTransfer
  };
});
