import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import YAML from 'yaml';
import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';
import SwaggerUI from 'swagger-ui';
import 'swagger-ui/dist/swagger-ui.css';
import 'components/swagger/swagger-port.styless.css';

import { AuthContext } from 'context/auth.context';

import { ScrollableContainer } from 'components/common/page-containers/scrollable-container';
import { useIsLoading } from 'stores/network.store';
import { APP_INFO_NETWORK_LABEL, useAppInfo } from 'stores/app-info.store';
import Loader from 'components/common/loader';

export const SwaggerPort = () => {
  const { user } = useContext(AuthContext);
  const swaggerRef = useRef(null);
  const { appInfo, fetchAppInfo } = useAppInfo();
  const authToken = get('node.auth_token', user);
  const serverAddress = get('node.address', user);
  const isLoadingAppInfo = useIsLoading([APP_INFO_NETWORK_LABEL]);
  const [isYamlLoading, setIsYamlLoading] = useState<boolean>(true);

  const getVersion = (version: string): string =>
    version === 'v1.4.1' ? 'v1.5.1' : version === 'latest' ? 'master' : version;

  useEffect(() => {
    fetchAppInfo();
  }, []);

  useEffect(() => {
    if (swaggerRef && swaggerRef.current && !isEmpty(appInfo)) {
      try {
        const { version } = appInfo;
        const swaggerVersion = getVersion(version);

        // TODO: change this fetch with custom hook
        fetch(
          `https://raw.githubusercontent.com/QED-it/asset_transfers_dev_guide/${swaggerVersion}/spec/asset-swagger.yaml`
        )
          .then(response => response.text())
          .then(yamlSwagger => {
            if (swaggerRef && swaggerRef.current) {
              const jsonSwagger = YAML.parse(yamlSwagger);
              jsonSwagger.servers[0].variables.server_url.default = serverAddress;
              jsonSwagger.servers[0].url = '{server_url}';
              delete jsonSwagger.servers[0].variables.port;
              setIsYamlLoading(false);
              const swaggerUI = SwaggerUI({
                domNode: swaggerRef.current,
                spec: jsonSwagger,
                onComplete: () =>
                  swaggerUI.preauthorizeApiKey('ApiKeyAuth', authToken)
              });
            }
          });
      } catch (e) {
        console.error(e);
      }
    }
  }, [swaggerRef.current, appInfo]);

  return (
    <ScrollableContainer>
      <Container>
        {isLoadingAppInfo || isYamlLoading ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        ) : null}
        <div ref={swaggerRef} />
      </Container>
    </ScrollableContainer>
  );
};

const Container = styled.div`
  padding: 0 150px;
`;

const LoaderContainer = styled.div`
  height: ${({ theme }) => `calc(100vh - ${theme.headerHeight}px)`};
`;
