import { useContext } from 'react';
import { createStore } from 'reusable';
import get from 'lodash/fp/get';

import { AuthContext } from 'context/auth.context';

import { useApiRequest } from 'hooks/api.hooks';

const GET_TASK_STATUS_LABEL = 'GET-TASK-STATUS label';

export const useGetTaskStatus = createStore(() => {
  const { user } = useContext(AuthContext);
  const apiRequest = useApiRequest();

  const urlAddress = get('node.address', user);
  const authToken = get('node.auth_token', user);

  const fetchTaskStatus = (id: string) => {
    return apiRequest({
      path: 'node/get_task_status',
      method: 'POST',
      baseUrl: urlAddress,
      headers: { 'x-auth-token': authToken },
      data: { id },
      networkLabel: GET_TASK_STATUS_LABEL,
      onError: console.error
    });
  };

  return {
    fetchTaskStatus
  };
});
