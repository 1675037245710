import { useContext, useState } from 'react';
import { createStore } from 'reusable';
import { get } from 'lodash/fp';

import { AuthContext } from 'context/auth.context';

import { useApiRequest } from 'hooks/api.hooks';

import { PublicKey } from 'types/wallet.types';

export const PUBLIC_KEY_LABEL = 'PUBLIC KEY label';

interface Props {
  walletId: string;
  onSuccess?: (balances: any) => void;
}

export const usePublicKey = createStore(() => {
  const apiRequest = useApiRequest();
  const { user } = useContext(AuthContext);
  const [publicKey, setPublicKey] = useState();
  const [error, setError] = useState();

  const address = get('node.address', user);
  const authToken = get('node.auth_token', user);

  const fetchPublicKey = (
    walletId: string,
    onSuccess: (publicKey: PublicKey) => void = setPublicKey
  ) =>
    apiRequest({
      path: 'wallet/get_public_key',
      method: 'POST',
      baseUrl: address,
      headers: { 'x-auth-token': authToken },
      data: { wallet_id: walletId },
      networkLabel: PUBLIC_KEY_LABEL,
      onSuccess,
      onError: console.error
    });

  return {
    publicKey,
    setPublicKey,
    error,
    setError,
    fetchPublicKey
  };
});
