import React, { useEffect } from 'react';
import styled from '@emotion/styled';

import { useIsProofModalOpen } from 'stores/ui/proof-modal-ui.store';
import { viewedTransactionTx } from 'stores/wallet/transactions.store';

import { transactionProofSelector } from 'selectors/transaction-proof.selector';
import Modal from './modal/modal';
import { BoldTitle, Text, Title } from './typography';
import { useNetworkActivity } from 'stores/wallet/network-activity.store';
import Loader from 'components/common/loader';

export const TransactionProofModal = () => {
  const [, setIsOpen] = useIsProofModalOpen();
  const { fetchNetworkActivity } = useNetworkActivity();
  const transactionProof = transactionProofSelector();
  const { transactionTx } = viewedTransactionTx();

  useEffect(() => {
    if (!transactionProof && transactionTx) {
      fetchNetworkActivity({
        length: 1,
        txHash: transactionTx
      });
    }
  }, []);

  const proof = transactionProof
    ? JSON.stringify(transactionProof, null, 2)
    : null;

  return (
    <Modal onClose={() => setIsOpen(false)}>
      <Container>
        <Header>
          <TitleContainer>
            <BoldTitle>TRANSACTION PROOF</BoldTitle>
          </TitleContainer>
          <TxContainer>
            <TxTitle>
              <Text>Tx Hash</Text>
            </TxTitle>
            <Title>{transactionTx}</Title>
          </TxContainer>
          <HR />
        </Header>
        {transactionProof && <Proof>{proof}</Proof>}
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  width: 900px;
`;

const Header = styled.div`
  color: ${({ theme }) => theme.colors.midDarkGrey};
  margin-bottom: 10px;
`;

const TitleContainer = styled.div`
  color: ${({ theme }) => theme.colors.midDarkGrey};
  text-align: center;
  margin-bottom: 25px;
  margin-top: 35px;
`;

const TxContainer = styled.div`
  margin: 0 20px 15px;
`;

const TxTitle = styled.div`
  margin-bottom: 5px;
`;

const HR = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGrey};
  height: 1px;
  opacity: 0.88;
`;

const Proof = styled.pre`
  max-height: 500px;
  height: calc(100vh / 2);
  overflow-y: scroll;
  &:hover {
    ${({ theme }) => theme.scrollbar.main};
  }
  flex: 1;
  font-family: monospace;

  padding: 25px 20px 35px;

  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
`;
