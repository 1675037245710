import { useState } from 'react';
import { createStore } from 'reusable';

import { Categories } from 'types/sidebar.types';

export const useToggleSidebar = createStore(() => useState(false));

export const useSelectedCategory = createStore(() =>
  useState<Categories | null>(null)
);

export const useSidebarMenuWallets = createStore(() => useState([]));

export const useTransfersMenu = createStore(() => useState(false));
