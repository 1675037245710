import React, { useContext, useMemo } from 'react';
import styled from '@emotion/styled';
import { get } from 'lodash/fp';
import OutsideClickHandler from 'react-outside-click-handler';

import logout from 'assets/images/logout.svg';

import { AuthContext } from 'context/auth.context';

import { useIsChangePasswordOpen } from 'stores/ui/password-modal-ui.store.ts';

import { logOut } from 'hooks/firebase/firebase.auth.hooks';
import { BoldTitle, Title } from 'components/common/typography';
import { Menu } from 'components/common/menu-container';

interface Props {
  handleToggle: (bool: boolean) => void;
}

export const UserMenu = ({ handleToggle }: Props) => {
  const auth = useContext(AuthContext);
  const [, setIsChangePasswordModalOpen] = useIsChangePasswordOpen();
  const email = useMemo(() => get('userEmail', auth), [auth]);

  const handleLogOut = () => {
    handleToggle(false);
    logOut();
  };

  const handleClickOutside = () => {
    handleToggle(false);
  };

  return (
    <OutsideClickHandler onOutsideClick={handleClickOutside}>
      <Menu position="absolute" top={50} right={40}>
        <MenuContent>
          <UserEmail>
            <BoldTitle>{email}</BoldTitle>
          </UserEmail>
          <ChangePassword onClick={() => setIsChangePasswordModalOpen(true)}>
            <Title>Change Password</Title>
          </ChangePassword>
          <LogOut onClick={handleLogOut}>
            <Icon />
            <Title>Logout</Title>
          </LogOut>
        </MenuContent>
      </Menu>
    </OutsideClickHandler>
  );
};

const MenuContent = styled.div`
  padding: 12px;
`;

const UserEmail = styled.div`
  margin-bottom: 12px;
`;

const ChangePassword = styled.div`
  margin-bottom: 12px;
  cursor: pointer;
`;

const LogOut = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
`;

const Icon = styled.div`
  background: url(${logout}) no-repeat center;
  background-size: contain;
  height: 23px;
  width: 18px;
  margin-right: 15px;
`;
