import React, { ElementType } from 'react';
import styled from '@emotion/styled';
import { BoldTitle } from 'components/common/typography';

interface Props {
  text: string;
  icon: ElementType;
}

export const IconicText = ({ text, icon: Icon }: Props) => {
  return (
    <Container>
      <Icon />
      <TextContainer>
        <BoldTitle>{text}</BoldTitle>
      </TextContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;

  svg {
    height: 20px;
    #Ellipse_1,
    #Ellipse_2,
    #Ellipse_3,
    #Path_1,
    #Path_2 {
      stroke: ${({ theme }) => theme.colors.lightBlue};
      box-shadow: 0 0 28px 0 rgba(0, 0, 0, 0.11);
    }
  }
`;

const TextContainer = styled.div`
  margin-left: 8px;
  color: ${({ theme }) => theme.colors.midDarkGrey};
`;

export default IconicText;
