import { createStore } from 'reusable';

import { useGetTaskStatus } from 'stores/wallet/task-status.store';
import { useUserTransactions } from 'stores/user/node-transactions.store';
import { useRules } from 'stores/wallet/rules.store';

import {
  setRule,
  setNotificationResultChange
} from 'utils/user-transactions.utils';

import { taskResultEnum } from 'constants/tasks.constants';

import { Task } from 'types/tasks.types';

export const useRulesNotifications = createStore(() => {
  const {
    setTransactions,
    transactionsRef,
    orderRef,
    blackListRef
  } = useUserTransactions();
  const { fetchTaskStatus } = useGetTaskStatus();
  const { fetchRules } = useRules();

  const handleSetRulesInNotifications = (task: Task, walletName: string) => {
    if (blackListRef.current[task.id]) {
      return;
    }

    if (!transactionsRef.current[task.id]) {
      setRule(task, walletName, orderRef, transactionsRef, setTransactions);
      return;
    }

    if (transactionsRef.current[task.id].result !== task.result) {
      setNotificationResultChange(task, transactionsRef, setTransactions);
    }
  };

  const modifyRules = (taskId: string, walletName: string) => {
    fetchTaskStatus(taskId).then((task: Task) => {
      handleSetRulesInNotifications(task, walletName);

      if (
        task.result === taskResultEnum.inProgress ||
        task.result === taskResultEnum.pending
      ) {
        setTimeout(() => modifyRules(taskId, walletName), 3000);
      }

      if (task.result === taskResultEnum.success) {
        fetchRules();
      }
    });
  };

  return { modifyRules };
});
