import { useEffect, useState, useRef } from 'react';
import get from 'lodash/fp/get';
import size from 'lodash/fp/size';
import keyBy from 'lodash/fp/keyBy';
import values from 'lodash/fp/values';
import isEmpty from 'lodash/fp/isEmpty';

import { Transactions, Transaction } from 'types/wallet.types';

import { useBalances } from 'stores/wallet/balances.store';
import { useTransactions } from 'stores/wallet/transactions.store';

import history from 'utils/history.utils';

export const NETWORK_LABEL_FETCH_LATEST = 'transactions polling fetch latest';

const NUM_TRANSACTIONS_INITIAL_LOAD = 30;
const NUM_TRANSACTIONS_FETCH_MORE = 10;
const INITIAL_HAS_MORE = true;
const INITIAL_TRANSACTION: Transaction[] = [];

const getTransactionsByHash: any = keyBy('metadata.tx_hash');

const useTransactionsPolling = (walletId: string) => {
  const [transactions, setTransactions] = useState<Transaction[]>(
    INITIAL_TRANSACTION
  );
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState<boolean>(INITIAL_HAS_MORE);
  const [isInitialLoading, setIsInitialLoading] = useState<boolean>(true);
  const previousWalletId = useRef<string>('');

  const { fetchTransactions } = useTransactions();
  const { fetchBalances } = useBalances();

  const fetchLatestTransactions = () => {
    return fetchTransactions({
      walletId,
      startIndex: 0,
      length: NUM_TRANSACTIONS_INITIAL_LOAD,
      label: NETWORK_LABEL_FETCH_LATEST,
      onSuccess: (data: Transactions) => {
        setIsInitialLoading(false);

        // This check is required while we switched to another wallet before response from previous wallet was gotten.
        if (previousWalletId.current !== data.wallet_id) {
          return;
        }
        setTransactions(current => {
          const responseTransactionsByHash = getTransactionsByHash(
            data.transactions
          );
          const currentTransactionsByHash = getTransactionsByHash(current);

          const mergedTransactionsByHash = {
            ...responseTransactionsByHash,
            ...currentTransactionsByHash
          };

          const mergedTransactionsArray = values(mergedTransactionsByHash);
          return mergedTransactionsArray;
        });
      }
    });
  };

  const fetchPreviousTransactions = () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    const startIndex = size(transactions);
    return fetchTransactions({
      walletId,
      startIndex,
      length: NUM_TRANSACTIONS_FETCH_MORE,
      onSuccess: (data: Transactions) => {
        setTransactions(current => {
          const responseTransactionsByHash = getTransactionsByHash(
            data.transactions
          );
          const currentTransactionsByHash = getTransactionsByHash(current);

          const mergedTransactionsByHash = {
            ...currentTransactionsByHash,
            ...responseTransactionsByHash
          };

          const mergedTransactionsArray = values(mergedTransactionsByHash);
          return mergedTransactionsArray;
        });

        setHasMore(!isEmpty(data.transactions));
      }
    }).finally(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    const walletDidChange =
      previousWalletId.current && previousWalletId.current !== walletId;
    if (walletDidChange) {
      setTransactions(INITIAL_TRANSACTION);
      setHasMore(INITIAL_HAS_MORE);
      setIsInitialLoading(true);
    }
    previousWalletId.current = walletId;

    fetchLatestTransactions();
  }, [walletId]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchTransactions({
        walletId,
        length: 1,
        onSuccess: (response: Transactions) => {
          const currentTransactionTxHash = get(
            '[0].metadata.tx_hash',
            transactions
          );
          const sampleTransactionTxHash = get(
            'transactions[0].metadata.tx_hash',
            response
          );

          if (currentTransactionTxHash && sampleTransactionTxHash) {
            if (currentTransactionTxHash !== sampleTransactionTxHash) {
              fetchLatestTransactions();
              fetchBalances({ walletId });
            }
          }
        }
      });
    }, 15000);

    return () => clearInterval(interval);
  }, [history.location.pathname, transactions]);

  useEffect(() => {
    fetchBalances({ walletId });
  }, [transactions]);

  return {
    transactions,
    fetchPreviousTransactions,
    fetchLatestTransactions,
    isLoading,
    hasMore,
    isInitialLoading
  };
};

export default useTransactionsPolling;
