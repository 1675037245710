import { flow, last, split, find, get } from 'lodash/fp';
import numeral from 'numeral';

import { Wallet } from 'types/firebase.types';

export const getWalletIdFromUrl = (pathName: string) => {
  return flow([split('/'), last])(pathName);
};

export function getFormattedAmount(amount: string | number) {
  if (amount || amount === 0) {
    return numeral(amount)
      .format('0,0.[00]a')
      .toUpperCase();
  }
  return ''; //needed in order to always return a string (instead of string | undefined)
}

export function getNumberOfTransactionsFormatted(numTransactions: number) {
  return numeral(numTransactions)
    .format('0,0a')
    .toUpperCase();
}

export function getWalletAuth(walletId: string, wallets: Wallet[]) {
  const wallet: Wallet | undefined = find(
    wallet => wallet.id === walletId,
    wallets
  );

  return get('auth', wallet);
}
