import React from 'react';
import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion';

import {
  WalletAndPermission,
  PublicKey,
  WalletAddress,
  Action,
  PlaceHolder,
  RowGrid
} from 'components/network-governance/table-common-components';
import { Text } from 'components/common/typography';

export const TableHeader = () => {
  return (
    <Container>
      <RowGrid>
        <WalletAndPermission>
          <Text>Wallet Name & Permission Type</Text>
        </WalletAndPermission>
        <PublicKey>
          <Text>Public Key</Text>
        </PublicKey>
        <WalletAddress>
          <Text>Wallet Address</Text>
        </WalletAddress>
        <PlaceHolder />
        <Flex alignItems="center">
          <Action>
            <Text>Action</Text>
          </Action>
        </Flex>
      </RowGrid>
    </Container>
  );
};

const Container = styled.div`
  color: ${({ theme }) => theme.colors.midDarkGrey};
  padding: 0 25px;
`;
