import React, { useState, useEffect, useMemo } from 'react';
import styled from '@emotion/styled';

import get from 'lodash/fp/get';
import find from 'lodash/fp/find';
import flow from 'lodash/fp/flow';
import isEmpty from 'lodash/fp/isEmpty';

import { ReactComponent as AssetBalanceIcon } from 'assets/images/asset-balance.svg';

import Loader from 'components/common/loader';
import { BoldTitle } from 'components/common/typography';
import { IconicText } from 'components/common/iconic-text';
import { SingleSelect } from 'components/common/single-select';
import AssetBalanceChart from 'components/wallet/asset-balance/asset-balance-chart';
import { AssetBalanceTable } from 'components/wallet/asset-balance/asset-balance-table';
import { AssetBalancePeriod } from 'components/wallet/asset-balance/asset-balance-period';

import { useIsLoading } from 'stores/network.store';
import { useBalances, BALANCES_LABEL } from 'stores/wallet/balances.store';

import { Period } from 'types/asset-balance.types';
import { Transaction, Asset } from 'types/wallet.types';
import { ChartBalance, ChartData } from 'types/chart.types';

import {
  filterTransactionsByType,
  getBalances,
  getPeriodFilter,
  getEmptyChartData
} from 'utils/chart.utils';
import { mapWithKeys, getOr } from 'utils/lodash.utils';

import { NETWORK_LABEL_FETCH_LATEST } from 'hooks/transactions.polling';

type Props = {
  transactions: Transaction[];
  walletId: string;
};

export const AssetBalance = ({ transactions, walletId }: Props) => {
  const { balances: assetBalances } = useBalances();
  const isLoadingChartData = useIsLoading([
    NETWORK_LABEL_FETCH_LATEST,
    BALANCES_LABEL
  ]);

  const [assetIds, setAssetIds] = useState<{ value: any; text: string }[]>([]);
  const [period, setCurrentPeriod] = useState<Period>('week');
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [currentAssetId, setCurrentAssetId] = useState<string>('');

  useEffect(() => {
    const firstAssetId = get(['assets', 0, 'asset_id'], assetBalances);
    setCurrentAssetId(firstAssetId);
  }, [assetBalances, walletId]);

  useEffect(() => {
    if (isEmpty(assetBalances.assets)) {
      setAssetIds([]);
      return;
    }

    const allAssetsIds = assetBalances.assets.reduce(
      (assetsIds: any, { asset_id }: Asset) => {
        assetsIds[asset_id] = asset_id;
        return assetsIds;
      },
      {}
    );

    const formattedAssetsIds = mapWithKeys(
      (value: string, key: string) => ({ value: key, text: value }),
      allAssetsIds
    );

    setAssetIds(formattedAssetsIds);
  }, [assetBalances.assets]);

  useEffect(() => {
    if (!isLoadingChartData) {
      setIsInitialized(true);
    }
  }, [isLoadingChartData]);

  useEffect(() => {
    setIsInitialized(false);
  }, [walletId]);

  const handleAssetSelect = (item: { value: string; text: string }) =>
    setCurrentAssetId(item.value);

  const currentBalance = flow(
    get('assets'),
    find((asset: Asset) => get('asset_id', asset) === currentAssetId),
    getOr(0, 'amount')
  )(assetBalances);

  const assetName = currentAssetId;
  const filterByAssetId = (transaction: Transaction) =>
    currentAssetId === get(['content', 'asset_id'], transaction);

  const filteredTransactions: Transaction[] = useMemo(
    () =>
      transactions
        .filter(filterTransactionsByType(['transfers', 'issuances']))
        .filter(filterByAssetId)
        .filter(getPeriodFilter(period)),
    [transactions, currentAssetId, period, walletId]
  );

  const balances: ChartBalance[] = useMemo(
    () => getBalances(currentBalance, filteredTransactions, period, assetName),
    [currentBalance, filteredTransactions, period, assetName, walletId]
  );

  if (!isInitialized) {
    return (
      <Container>
        <LoadingData>
          <Loader />
        </LoadingData>
      </Container>
    );
  }

  const chartData: ChartData = isEmpty(filteredTransactions)
    ? getEmptyChartData(period, currentBalance)
    : balances.slice().reverse();

  return (
    <Container>
      <Header>
        <TitleContainer>
          <IconicText text="ASSET BALANCE" icon={AssetBalanceIcon} />
        </TitleContainer>
        <SingleSelectContainer>
          <SingleSelect
            items={assetIds}
            onSelect={item => handleAssetSelect(item)}
            selectedValue={assetName}
            width="100%"
          />
        </SingleSelectContainer>
      </Header>
      <Content>
        <AssetBalancePeriod activePeriod={period} onChange={setCurrentPeriod} />
        {!currentBalance || isEmpty(transactions) ? (
          <NoData>
            <BoldTitle>No asset history.</BoldTitle>
          </NoData>
        ) : (
          <>
            <AssetBalanceChart chartData={chartData} period={period} />
            <AssetBalanceTable
              balances={balances}
              transactions={filteredTransactions}
            />
          </>
        )}
      </Content>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.midDarkGrey};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  position: relative;
`;

const TitleContainer = styled.div`
  margin: 0 auto;
`;

const SingleSelectContainer = styled.div`
  position: absolute;
  right: 20px;

  .dropdown {
    max-height: 290px;
    overflow-y: auto;
  }
`;

const NoData = styled.div`
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
