import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Link as ReactRouterLink } from 'react-router-dom';
import qs from 'qs';
import history from 'utils/history.utils';
import { get } from 'lodash/fp';

const showTooltip =
  get(
    'typography',
    qs.parse(history.location.search, { ignoreQueryPrefix: true })
  ) === 1;

const tooltip = css`
  position: relative;
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-100%, -50%);
    font-size: 12px;
    font-weight: 400;
    font-family: Montserrat-Regular;
    color: red;
    z-index: 99999999;
    content: var(--title);
  }
`;
export const H1 = styled.h1`
  font-family: Montserrat-Regular;
  font-size: 18px;
  //line-height: 1.22;
  --title: 'H1';
  ${() =>
    showTooltip &&
    css`
      ${tooltip}
    `}
`;

export const H2 = styled.h2`
  font-family: Montserrat-Regular;
  line-height: 1.22;
  font-size: 23px;
  //line-height: 1.22;
  ${() =>
    showTooltip &&
    css`
      ${tooltip}
    `}
  --title: 'H2';
`;

export const BoldTitle = styled.div`
  font-family: Montserrat-Bold;
  //line-height: 1.27;
  font-size: 15px;
  ${() =>
    showTooltip &&
    css`
      ${tooltip}
    `}
  --title: 'BoldTitle';
`;

export const SemiBoldTitle = styled.div`
  font-family: Montserrat-SemiBold;
  font-size: 15px;

  ${() =>
    showTooltip &&
    css`
      ${tooltip}
    `}
  --title: 'SemiBoldTitle';
`;
export const Title = styled.div`
  font-family: Montserrat-Regular;
  //line-height: 1.27;
  font-size: 15px;
  ${() =>
    showTooltip &&
    css`
      ${tooltip}
    `}
  --title: 'Title';
`;

export const LightText = styled.div`
  font-family: Montserrat-Light;
  font-size: 12px;
  font-weight: 300;
  //line-height: 1.25;
  ${() =>
    showTooltip &&
    css`
      ${tooltip}
    `}
  --title: 'LightText';
`;
export const Text = styled.div`
  font-family: Montserrat-Regular;
  font-size: 12px;
  font-weight: 300;
  //line-height: 1.27;
  ${() =>
    showTooltip &&
    css`
      ${tooltip}
    `}
  --title: 'Text';
`;

export const BoldText = styled.div`
  font-family: Montserrat-Bold;
  font-size: 12px;
  font-weight: 300;
  //line-height: 1.27;
  ${() =>
    showTooltip &&
    css`
      ${tooltip}
    `}
  --title: 'BoldText';
`;

export const MediumText = styled.div`
  font-family: Montserrat-Medium;
  font-size: 20px;
  //line-height: 1.2;
  ${() =>
    showTooltip &&
    css`
      ${tooltip}
    `}
  --title: 'MediumText';
`;

export const LargeText = styled.div`
  font-family: Montserrat-Regular;
  font-size: 26px;
  //line-height: 1.23;
  ${() =>
    showTooltip &&
    css`
      ${tooltip}
    `}
  --title: 'LargeText';
`;

export const HugeText = styled.div`
  font-family: Montserrat-Regular;
  font-size: 50px;
  //line-height: 1.23;
  ${() =>
    showTooltip &&
    css`
      ${tooltip}
    `}
  --title: 'HugeText';
`;

export const BaseLink = css`
  font-family: Montserrat-Regular;
  font-size: 15px;
  //line-height: 1.2;
  text-decoration: none;
`;

export const Link = styled(ReactRouterLink)<{
  highlight?: boolean;
}>`
  ${BaseLink};
  color: ${({ theme }) => theme.colors.midDarkGrey};
  &:hover {
    color: ${({ theme }) => theme.colors.lightBlue};
  }
  ${({ highlight, theme }) =>
    highlight &&
    css`
      color: ${theme.colors.lightBlue};
    `}
  ${() =>
    showTooltip &&
    css`
      ${tooltip}
    `}
  --title: 'Link';
`;

//todo: fix duplication for color and hover
export const ExternalLink = styled.a`
  ${BaseLink};
  color: ${({ theme }) => theme.colors.midDarkGrey};
  &:hover {
    color: ${({ theme }) => theme.colors.lightBlue};
  }
  ${() =>
    showTooltip &&
    css`
      ${tooltip}
    `}
  --title: 'ExternalLink';
`;

export const ChartLabelSvg = styled.text`
  font-family: Montserrat-Regular;
  color: ${({ theme }) => theme.colors.midDarkGrey};
  font-size: 12px;
`;
