import React from 'react';
import { Router, Route, Redirect, Switch } from 'react-router-dom';

import history from 'utils/history.utils';

import { AppRoute } from 'components/common/AppRoute';

import { Login } from 'components/login/login';
import { Layout } from 'components/layout/layout';
import { Wallet } from 'components/wallet/wallet';
import { About } from 'components/about/about';
import { NetworkGovernance } from 'components/network-governance/network-governance';
import { SwaggerPort } from 'components/swagger/swagger-port';
import BlockExplorer from './block-explorer/block-explorer';
import Dashboard from './dashboard/dashboard';

export const AppRouter = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/login" />} />
        <AppRoute exact path="/login" component={Login} />
        <AppRoute exact path="/login/reset-password" component={Login} />

        <AppRoute
          exact
          layout={Layout}
          component={Dashboard}
          path="/dashboard"
          isPrivate={true}
        />
        <AppRoute
          exact
          layout={Layout}
          component={Wallet}
          path="/wallet/:id"
          isPrivate={true}
        />
        <AppRoute
          exact
          layout={Layout}
          component={BlockExplorer}
          path="/network/block-explorer"
          isPrivate={true}
        />
        <AppRoute
          exact
          layout={Layout}
          component={NetworkGovernance}
          path="/network/governance"
          isPrivate={true}
        />
        <AppRoute
          exact
          layout={Layout}
          component={About}
          path="/help/about"
          isPrivate={true}
        />
        <AppRoute
          exact
          layout={Layout}
          component={SwaggerPort}
          path="/swagger"
          isPrivate={true}
        />

        <Redirect from="*" to="/login" />
      </Switch>
    </Router>
  );
};
