import css from '@emotion/css';

export const theme = {
  colors: {
    background: '#f5f9ff',
    backdrop: 'rgba(112, 112, 112, 0.5)',
    white: '#FFF',
    dark: '#1e1a35',
    darkGrey: '#1b1f23',
    midDarkGrey: '#707070',
    grey: '#f2f2f2',
    greyLighter: '#f3f3f3',
    midLightGrey: '#e9f0f9',
    lightGrey: '#c1c1c1',
    veryLightGrey: '#9d9d9d',
    switchBorderGrey: '#DBDBDB',
    menuToggleGrey: '#E6E2E2',
    greyGradient: 'linear-gradient(to bottom, #626a72, #0f1012)',
    darkBlue: '#150d40',
    lightBlue: '#38b1df',
    veryLightBlue: '#6dc4e5',
    veryLightTurquoise: '#dff6ff',
    azure: '#d9f0f9',
    blueGradient: `linear-gradient(to bottom,#1c108f, #38b1df)`,
    turquoiseGradient: `linear-gradient(29deg, #38b1df, #53e5cc)`,
    darkRed: '#df3838',
    midDarkRed: '#ff5529',
    darkerGrey: '#474747',
    red: '#db624a',
    errorRed: '#d13107',
    warningRed: '#ffd8c4',
    warningIconRed: '#fc5f5f',
    orange: '#fff0dc',
    darkOrange: '#fbb04e',
    lightYellow: '#fff6bb',
    boxShadow: '0 0 28px 0 rgba(0, 0, 0, 0.11)'
  },
  assetsColors: [
    '#ff6b6b',
    '#3859df',
    '#38dfb7',
    '#9138df',
    '#fbb860',
    '#38dfdf'
  ],
  scrollbar: {
    main: css`
      ::-webkit-scrollbar {
        opacity: 1;
      }
      ::-webkit-scrollbar-thumb {
        background: #888;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    `
  },
  container: {
    paddingX: 38,
    paddingY: 23
  },
  //todo: think about better naming for 'cardSpacing',
  cardSpacing: {
    margin: '20px'
  },
  headerHeight: 60,
  sidebarWidth: {
    opened: '205px',
    closed: '60px'
  },
  cardContentSpace: {
    height: 467
  },
  singleSelect: {
    dropdownMaxHeight: '280px'
  },
  typography: {
    text: css`
      font-family: Montserrat-Regular;
      font-size: 18px;
      line-height: 1.22;
    `,
    boldText: css`
      font-family: Montserrat-Bold;
      font-size: 15px;
      line-height: 1.27;
    `,
    navbarText: css`
      font-family: Montserrat-Regular;
      font-size: 20px;
      line-height: 1.2;
    `,
    loginButton: css`
      font-family: Montserrat-Regular;
      font-size: 18px;
      font-weight: 500;
      line-height: 1.22;
      letter-spacing: 0.9px;
    `,
    transferButton: css`
      font-family: Montserrat-Regular;
      font-size: 15px;
      font-weight: 600;
      line-height: 1.27;
      letter-spacing: 0.5px;
    `,
    modalInput: css`
      font-family: Montserrat-Regular;
      font-size: 15px;
      line-height: 1.27;
    `,
    title: css`
      font-family: Montserrat-Regular;
      font-size: 44px;
      font-weight: 300;
      line-height: 1.23;
      letter-spacing: 2.2px;
    `,
    transaction: css`
      font-family: Montserrat-Regular;
      line-height: 1.22;
      font-size: 16px;
      font-weight: 500;
    `,
    closeButton: css`
      font-family: Montserrat-Regular;
      line-height: 1.19;
      font-size: 21px;
      font-weight: 500;
    `,
    assets: css`
      font-family: Montserrat-Regular;
      line-height: 1.27;
      font-size: 15px;
    `,
    assetsBold: css`
      font-family: Montserrat-Bold;
      line-height: 1.27;
      font-size: 15px;
    `
  }
};

export default theme;
