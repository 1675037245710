import { useState } from 'react';
import { createStore } from 'reusable';

import get from 'lodash/fp/get';
import some from 'lodash/fp/some';
import update from 'lodash/fp/update';
import castArray from 'lodash/fp/castArray';

export type NetworkState = {
  readonly [key: string]: number;
};

const initialState: NetworkState = {};

export const useNetwork = createStore(() => {
  const [value, setValue] = useState(initialState);
  const startNetwork = (label: string) => {
    setValue(update(label, oldVal => (oldVal ? oldVal + 1 : 1)));
  };
  const endNetwork = (label: string) => {
    setValue(update(label, oldVal => (oldVal ? oldVal - 1 : 0)));
  };
  return {
    value,
    startNetwork,
    endNetwork
  };
});

export const useIsLoading = (networkLabel: string | string[]) => {
  const network = useNetwork(get('value'));
  const labels = castArray(networkLabel);

  return some(currentLabel => network[currentLabel] > 0, labels);
};
