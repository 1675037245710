import React from 'react';
import styled from '@emotion/styled';

import { useIsPermissionsModalOpen } from 'stores/ui/permission-modal-ui.store';

import {
  useCurrentWalletPublicKey,
  useCurrentWalletName
} from 'stores/governance/governance.store';

import { Menu } from 'components/common/menu-container';
import { Title } from 'components/common/typography';

interface Props {
  isAdminWallet: boolean;
  isIssuerWallet: boolean;
  isNodeAdmin: boolean;
  publicKey: string;
  name: string;
  handleCloseMenu: () => void;
}

export const WalletMenu = ({
  isAdminWallet,
  isIssuerWallet,
  isNodeAdmin,
  publicKey,
  name,
  handleCloseMenu
}: Props) => {
  const [, setPublicKey] = useCurrentWalletPublicKey();
  const [, setIsPermissionsModalOpen] = useIsPermissionsModalOpen();
  const [, setWalletName] = useCurrentWalletName();

  const handleOpenPermissionsModal = () => {
    setPublicKey(publicKey);
    setWalletName(name);
    handleCloseMenu();
    setIsPermissionsModalOpen(true);
  };

  const getMenuItems = (
    isAdminWallet: boolean,
    isIssuerWallet: boolean,
    isNodeAdmin: boolean
  ) => {
    // TODO: add disabled mode
    if (isIssuerWallet) {
      return (
        <>
          <MenuItem onClick={handleOpenPermissionsModal}>
            View Issuance Permissions
          </MenuItem>
        </>
      );
    }

    if (isNodeAdmin && !isIssuerWallet) {
      return (
        <>
          <MenuItem onClick={handleOpenPermissionsModal}>Make Issuer</MenuItem>
        </>
      );
    }

    return null;
  };

  return (
    <Menu className="governance-wallet-menu">
      {getMenuItems(isAdminWallet, isIssuerWallet, isNodeAdmin)}
    </Menu>
  );
};

const MenuItem = styled(Title)`
  padding-top: 8px;
  padding-left: 12px;
  padding-bottom: 8px;
  min-width: 240px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.midDarkGrey};

  &:first-of-type {
    padding-top: 12px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
  }

  &:last-child {
    padding-bottom: 12px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.lightBlue};
    background-color: ${({ theme }) => theme.colors.greyLighter};
  }
`;
