import { useContext, useState } from 'react';
import { createStore } from 'reusable';
import get from 'lodash/fp/get';

import { AuthContext } from 'context/auth.context';

import { useApiRequest } from 'hooks/api.hooks';

export interface RulesStore {
  fetchRules: () => void;
  rules: Rule[];
}

export interface Rule {
  public_key: string;
  can_issue_confidentially: boolean;
  is_admin: boolean;
  namespace: string;
}

const initialValue: Rule[] = [];

type RuleResponse = { rules: Rule[] };

export const RULES_LABEL = 'rules label';

export const useRules = createStore<RulesStore>(() => {
  const apiRequest = useApiRequest();
  const { user } = useContext(AuthContext);

  const [rules, setRules] = useState<Rule[]>(initialValue);

  const address = get('node.address', user);
  const authToken = get('node.auth_token', user);

  // TODO: work on types

  const fetchRules = () =>
    apiRequest({
      path: 'node/get_rules',
      method: 'POST',
      baseUrl: address,
      headers: { 'x-auth-token': authToken },
      networkLabel: RULES_LABEL,
      onSuccess: (response: RuleResponse) => setRules(response.rules),
      onError: console.error
    });

  return {
    rules,
    fetchRules
  };
});
