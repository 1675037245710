import { db, firebase } from 'config/firebase.config';

export async function fetchFromFireBase(
  collectionName: string,
  docName: string
) {
  const doc = await db.collection(collectionName).doc(docName);
  const res = await doc['get']({});
  return res.data();
}

export function addNewWallet(
  address: string,
  public_key: string,
  wallet_auth: string,
  wallet_id: string
) {
  const addWallet = firebase.functions().httpsCallable('api/addWalletToUser');
  addWallet({ address, public_key, wallet_auth, wallet_id }).catch(e =>
    console.log('error in addWallet', e)
  );
}

export async function deleteWallet(wallet_id: string) {
  const deleteWallet = firebase.functions().httpsCallable('api/deleteWallet');
  return await deleteWallet({ wallet_id });
}

export async function addWalletToNetwork(
  wallet_id: string,
  wallet_auth: string,
  public_key: string,
  address: string
) {
  const addWalletToNetwork = firebase
    .functions()
    .httpsCallable('api/addWalletToNetwork');

  return await addWalletToNetwork({
    wallet_id,
    wallet_auth,
    public_key,
    address
  });
}

export async function deleteWalletFromNetwork(wallet_id: string) {
  const deleteWalletFromNetwork = firebase
    .functions()
    .httpsCallable('api/deleteWalletFromNetwork');
  return await deleteWalletFromNetwork({ wallet_id });
}
