import { useContext, useEffect, useState } from 'react';
import { createStore } from 'reusable';
import map from 'lodash/fp/map';
import keyBy from 'lodash/fp/keyBy';

import { AuthContext } from 'context/auth.context';

import { useRecipientAddresses } from 'stores/wallet/recepient-address.store';
import { usePublicKeysOfWallets } from 'stores/wallet/public-keys-of-wallets.store';
import { AddressBook, User } from 'types/firebase.types';

export interface AddressBookStore {
  addressBook: AddressBook;
}

export const useAddressBook = createStore<AddressBookStore>(() => {
  const { user }: { user: User } = useContext(AuthContext);
  const [addressBook, setAddressBook] = useState<AddressBook>({});
  const { publicKeys, fetchPublicKeys } = usePublicKeysOfWallets();
  const {
    recipientAddresses,
    fetchRecipientAddresses
  } = useRecipientAddresses();

  useEffect(() => {
    if (!user) {
      return;
    }

    const missingWalletsIds: string[] = user.wallets
      .map(wallet => wallet.id)
      .filter(wallet => !user.network.address_book[wallet]);

    fetchPublicKeys(missingWalletsIds);
    fetchRecipientAddresses(missingWalletsIds);
  }, [user]);

  useEffect(() => {
    if (!user) {
      return;
    }

    const publicKeysDic = keyBy('wallet_id', publicKeys);
    const recipientAddressesDic = keyBy('wallet_id', recipientAddresses);

    const missingAddressBook = map(
      item => ({ ...item, ...recipientAddressesDic[item.wallet_id] }),
      publicKeysDic
    );
    const missingAddressBookDic = keyBy('wallet_id', missingAddressBook);

    const finalAddressBook: AddressBook = {
      ...missingAddressBookDic,
      ...user.network.address_book
    };

    setAddressBook(finalAddressBook);
  }, [publicKeys, recipientAddresses]);

  return {
    addressBook
  };
});
