import React, { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import find from 'lodash/fp/find';

import { AuthContext } from 'context/auth.context';

import { useIsCreateNewWalletModalIsOpen } from 'stores/ui/create-new-wallet-ui.store';

import { useCreateNewWallet } from 'hooks/create-new-wallet.hooks';

import { Modal } from 'components/common/modal/modal';
import { ModalTitle } from 'components/common/modal/modal-title';
import { TextField, ValidIcon } from 'components/common/text-field';
import { Button } from 'components/common/button';
import { Text } from 'components/common/typography';
import { WarningLabel } from 'components/common/warning-label';

import { Wallet } from 'types/firebase.types';

export const CreateNewWalletModal = () => {
  const [walletId, setWalletId] = useState<string>('');
  const [authPassword, setAuthPassword] = useState<string>('');
  const [authConfirm, setAuthConfirm] = useState<string>('');

  const [isAuthValid, setIsAuthValid] = useState(false);
  const [isConfirmAuthValid, setIsConfirmAuthValid] = useState(false);

  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  const [isWalletExists, setIsWalletExists] = useState(false);

  const [, setIsCreateNewWalletModalIsOpen] = useIsCreateNewWalletModalIsOpen();
  const { createNewWallet } = useCreateNewWallet();
  const { user } = useContext(AuthContext);

  const handleCreateNewWallet = async () => {
    if (!isAuthValid || !isConfirmAuthValid || !walletId || isWalletExists) {
      return;
    }
    setIsCreateNewWalletModalIsOpen(false);
    await createNewWallet(walletId, authPassword);
  };

  useEffect(() => {
    const existingWallets = find<Wallet>({ id: walletId }, user.wallets);

    if (existingWallets) {
      setIsWalletExists(true);
    } else {
      setIsWalletExists(false);
    }
  }, [user, walletId, isWalletExists]);

  useEffect(() => {
    if (authPassword && !isInitialized) {
      setIsWarningOpen(true);
      setIsInitialized(true);
    }
  }, [authPassword, isInitialized]);

  useEffect(() => {
    if (authPassword) {
      setIsAuthValid(true);
      return;
    }
    setIsAuthValid(false);
  }, [authPassword]);

  useEffect(() => {
    if (authPassword && authConfirm === authPassword) {
      setIsConfirmAuthValid(true);
      return;
    }
    setIsConfirmAuthValid(false);
  }, [authPassword, authConfirm]);

  return (
    <Modal
      onClose={() => setIsCreateNewWalletModalIsOpen(false)}
      paddingRight={0}
      paddingLeft={0}>
      <Container>
        <ModalTitle
          text="CREATE NEW WALLET"
          align="center"
          margin="0 0 50px 0"
        />
        <InputContainer>
          <TextField
            autoFocus={true}
            onChange={setWalletId}
            label="Wallet ID"
            value={walletId}
            type="text"
            helperText={isWalletExists ? 'Wallet ID already exists' : ''}
            error={isWalletExists}
          />
        </InputContainer>
        <InputContainer>
          <TextField
            autoComplete="new-password"
            onChange={setAuthPassword}
            label="Create Authorization Password"
            value={authPassword}
            type="password"
            showIcon={isAuthValid}
            iconComponent={<EnhancedValidIcon />}
            helperText={
              !isInitialized || (isAuthValid && isInitialized)
                ? ''
                : 'Authorization passwords must include at least 1 character'
            }
            error={!isAuthValid && isInitialized}
          />
        </InputContainer>
        <InputContainer>
          <TextField
            autoComplete="new-password"
            onChange={setAuthConfirm}
            label="Re-enter Authorization Password"
            value={authConfirm}
            type="password"
            showIcon={isConfirmAuthValid}
            iconComponent={<EnhancedValidIcon />}
          />
        </InputContainer>
        <WarningLabel isOpen={isWarningOpen}>
          Authorization passwords cannot be reset. Save and store passwords
          securely.
        </WarningLabel>
        <ButtonsContainer>
          <Cancel onClick={() => setIsCreateNewWalletModalIsOpen(false)}>
            Cancel
          </Cancel>
          <Button
            onClick={handleCreateNewWallet}
            text="CREATE NEW WALLET"
            variant="primary"
            width="200px"
            height="45px"
            fontSize="12px"
            fontWeight="600"
          />
        </ButtonsContainer>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
`;

const InputContainer = styled.div`
  margin: 0 130px;
  width: 480px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 135px;
`;

const Cancel = styled(Text)`
  cursor: pointer;
`;

const EnhancedValidIcon = styled(ValidIcon)`
  height: 20px;
`;
