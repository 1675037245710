import { Rule, RulesStore } from 'stores/wallet/rules.store';
import dropRight from 'lodash/fp/dropRight';

export const rulesFromIssueNewAsset = (state: RulesStore): Rule[] => {
  return state.rules.map(item => {
    if (item.namespace === '*') {
      return {
        ...item,
        namespace: item.namespace
      };
    }

    return {
      ...item,
      namespace: dropRight(1, item.namespace).join('')
    };
  });
};
