import { useEffect } from 'react';
import findKey from 'lodash/fp/findKey';
import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';
import forEach from 'lodash/fp/forEach';

import { useGetTasks } from 'stores/wallet/get-tasks.store';
import { useUserTransactions } from 'stores/user/node-transactions.store';
import { useAddressBook } from 'stores/user/address-book';

import { Tasks, Task, ModifiedTask } from 'types/tasks.types';
import { Address } from 'types/firebase.types';

export const useNotificationsPolling = () => {
  const { fetchTasks } = useGetTasks();
  const { setTransactions, transactionsRef, orderRef } = useUserTransactions();
  const { addressBook } = useAddressBook();

  const handleSetTransaction = (tasks: Tasks, addressBook: any) => {
    tasks.forEach((task: Task) => {
      if (transactionsRef.current[task.id] || isEmpty(addressBook)) {
        return;
      }

      const senderPublicKey = get('data.sender_public_key', task);
      const senderName = findKey(
        (address: Address) => address.public_key === senderPublicKey,
        addressBook
      );
      // TODO: extract to method
      const newTaskTransaction: ModifiedTask = {
        id: task.id,
        type: task.type,
        result: task.result,
        createdAt: task.created_at,
        recipient: task.data.wallet_id,
        sender: senderName,
        assetId: task.data.asset_id,
        amount: task.data.amount,
        order: orderRef.current + 1
      };

      transactionsRef.current = {
        ...transactionsRef.current,
        [task.id]: newTaskTransaction
      };
      orderRef.current = orderRef.current + 1;
      setTransactions({ ...transactionsRef.current });
    });
  };

  useEffect(() => {
    const updateNotifications = () => {
      fetchTasks(['awaiting_user_interaction'], ['incoming_confirm_request'])
        .then(({ tasks }: { tasks: Tasks }) => {
          forEach((transfer: Task) => {
            const isExists =
              !isEmpty(tasks) && tasks.some(t => t.id === transfer.id);
            // TODO: extract to method
            if (
              !isExists &&
              transfer.type === 'incoming_confirm_request' &&
              transfer.result !== 'canceled' &&
              transfer.result !== 'confirmed'
            ) {
              transactionsRef.current = {
                ...transactionsRef.current,
                [transfer.id]: {
                  ...transactionsRef.current[transfer.id],
                  result: 'canceled'
                }
              };

              setTransactions({ ...transactionsRef.current });
            }
          }, transactionsRef.current);

          if (!tasks) {
            return;
          }

          handleSetTransaction(tasks, addressBook);
        })
        .catch((e: any) => console.log('error in updateNotifications', e));
    };
    updateNotifications();
    const intervalId = setInterval(updateNotifications, 8000);

    return () => clearInterval(intervalId);
  }, [addressBook, setTransactions]);

  return {};
};
