import moment from 'moment';

//todo: localize
export const formatTransactionDateTime = (date: Date | string): string =>
  date ? moment(date).format('MM-DD-YYYY HH:mm:ss') : 'N/A';

export const normalizeSeconds = (time: number): number => {
  return Math.floor(time % 60);
};

export const normalizeMinutes = (time: number) => {
  return Math.floor((time % 3600) / 60);
};
