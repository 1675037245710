import { useContext, useState } from 'react';
import { createStore } from 'reusable';
import { get, keyBy, values, keys, size } from 'lodash/fp';

import { AuthContext } from 'context/auth.context';

import { useApiRequest } from 'hooks/api.hooks';
import { NetworkActivityTransaction } from 'types/network-activity.types';

interface NetworkActivity {
  transactions: NetworkActivityTransaction[];
}

const initialValue: NetworkActivity = {
  transactions: []
};

const NETWORK_ACTIVITY_LABEL = 'NETWORK-ACTIVITY label';

export const useNetworkActivity = createStore(() => {
  const { user } = useContext(AuthContext);
  const apiRequest = useApiRequest();
  const [networkActivity, setNetworkActivity] = useState<NetworkActivity>(
    initialValue
  );
  const [error, setError] = useState(null);

  const urlAddress = get('node.address', user);
  const authToken = get('node.auth_token', user);

  const fetchNetworkActivity = ({
    startIndex = 0,
    length = 100,
    txHash = ''
  } = {}) =>
    apiRequest({
      path: 'analytics/get_network_activity',
      method: 'POST',
      baseUrl: urlAddress,
      headers: { 'x-auth-token': authToken },
      data: {
        start_index: startIndex,
        number_of_results: length,
        tx_hashes: txHash ? [txHash] : undefined
      },
      networkLabel: NETWORK_ACTIVITY_LABEL,
      onSuccess: (data: NetworkActivity) => {
        const responseTransactionsByHash = keyBy(
          'metadata.tx_hash',
          data.transactions
        );

        setNetworkActivity(currentNetworkActivity => {
          const currentTransactionsByhash = keyBy(
            'metadata.tx_hash',
            currentNetworkActivity.transactions
          );
          const combinedTransactionsByHash = {
            ...responseTransactionsByHash,
            ...currentTransactionsByhash
          };

          const combinedTransactionsArray = values(combinedTransactionsByHash);
          return { transactions: combinedTransactionsArray };
        });
      },

      onError: console.error
    });

  return {
    networkActivity,
    setNetworkActivity,
    error,
    setError,
    fetchNetworkActivity
  };
});
