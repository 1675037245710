import { find } from 'lodash/fp';

import { Wallet, Wallets } from 'types/firebase.types';

export type FormattedWallet = { text: string; endpoint: string };

export function getFormattedWallets(wallets: Wallets): FormattedWallet[] {
  return wallets.map((wallet: Wallet) => ({
    text: wallet.id,
    endpoint: `/wallet/${wallet.id}`
  }));
}

export function getWalletAuth(walletId: string, wallets: Wallets) {
  const wallet = find(wallet => wallet.id === walletId, wallets);
  if (wallet) {
    return wallet.auth;
  }
}
