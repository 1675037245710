import styled from '@emotion/styled';
import { flow, get, isEmpty, size, filter, forEach } from 'lodash/fp';
import React, { useState, useEffect, useMemo } from 'react';

import AssetBalanceChart from 'components/wallet/asset-balance/asset-balance-chart';
import { AssetBalancePeriod } from 'components/wallet/asset-balance/asset-balance-period';

import { BALANCES_LABEL } from 'stores/wallet/balances.store';
import { useIsLoading } from 'stores/network.store';

import { Period } from 'types/asset-balance.types';
import { ChartData } from 'types/chart.types';
import { Transaction, Mode } from 'types/wallet.types';

import {
  getPeriodFilter,
  getEmptyChartData,
  filterTransactionsByType,
  getPeriodTimeToSubtract
} from 'utils/chart.utils';
import { Title } from 'components/common/typography';
import Loader from 'components/common/loader';
import TransactionModeTabs from 'components/common/transaction-mode-tabs';
import moment from 'moment';
import ChartKeyValueTable from 'components/common/chart-keyvalue-table';
import { getNumberOfTransactionsFormatted } from 'utils/wallet.utils';
import { NETWORK_LABEL_FETCH_LATEST } from 'hooks/transactions.polling';

type Props = {
  transactions: Transaction[];
};

const getChartStepsDuration = (period: Period) => {
  switch (period) {
    case 'last 15 minutes':
      return moment.duration(15, 'seconds');
    case 'day':
      return moment.duration(1, 'hour');
    case 'week':
      return moment.duration(1, 'day');
    default:
      return moment.duration(1, 'week');
  }
};

const getChartData = (
  transactions: Transaction[],
  period: Period
): ChartData => {
  const stepDuration = getChartStepsDuration(period);
  let currentStepStart = getPeriodTimeToSubtract(period);
  let currentStepEnd = currentStepStart.clone().add(stepDuration);

  let currentStepNumTransactions = 0;
  const chartData: ChartData = [];
  const now = moment();

  while (currentStepStart < now) {
    forEach((currentTransaction: Transaction) => {
      const currentTransactionTimestamp = moment(
        currentTransaction.metadata.timestamp
      );

      if (
        currentTransactionTimestamp.isBetween(currentStepStart, currentStepEnd)
      ) {
        currentStepNumTransactions++;
      }
    }, transactions);

    chartData.push({
      date: currentStepStart.clone().valueOf(),
      balance: currentStepNumTransactions,
      debugDate: currentStepStart.clone().toDate()
    });
    currentStepEnd = currentStepEnd.clone().add(stepDuration);
    currentStepStart = currentStepStart.clone().add(stepDuration);
    currentStepNumTransactions = 0;
  }

  return chartData;
};

//todo: refactor and unify with Wallet's Asset Balance
const DashboardAssetBalance = (props: Props) => {
  const { transactions } = props;

  const [period, setCurrentPeriod] = useState<Period>('week');
  const [transactionsType, setTransactionsType] = useState<Mode>('transfers');
  const isLoadingChartData = useIsLoading([
    NETWORK_LABEL_FETCH_LATEST,
    BALANCES_LABEL
  ]);

  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  useEffect(() => {
    if (!isLoadingChartData) {
      setIsInitialized(true);
    }
  }, [isLoadingChartData]);

  useEffect(() => {
    setIsInitialized(false);
  }, []);

  const filteredTransactions: Transaction[] = useMemo(
    () =>
      transactions
        .filter(filterTransactionsByType(transactionsType))
        .filter(getPeriodFilter(period)),
    [transactions, period, transactionsType]
  );

  const overAllTransactionsByPeriod: Transaction[] = useMemo(
    () => transactions.filter(getPeriodFilter(period)),
    [transactions, period]
  );

  const chartData = useMemo(() => {
    return isEmpty(filteredTransactions)
      ? getEmptyChartData(period, 0)
      : getChartData(filteredTransactions, period);
  }, [filteredTransactions, period]);

  const {
    totalIncomingTransactions,
    totalOutgoingTransactions,
    totalIssuances
  } = useMemo(() => {
    const totalIncomingTransactions = flow(
      filter((transaction: Transaction) =>
        get(['content', 'is_incoming'], transaction)
      ),
      size
    )(overAllTransactionsByPeriod);

    const totalOutgoingTransactions = flow(
      filter(
        (transaction: Transaction) =>
          !get(['content', 'is_incoming'], transaction)
      ),
      size
    )(overAllTransactionsByPeriod);

    const totalIssuances = flow(
      filter(filterTransactionsByType('issuances')),
      filter((transaction: Transaction) => {
        const transactionTimeStamp = moment(transaction.metadata.timestamp);
        const startPoint = getPeriodTimeToSubtract(period);
        const endPoint = moment();
        return transactionTimeStamp.isBetween(startPoint, endPoint);
      }),
      size
    )(transactions);

    return {
      totalIncomingTransactions: getNumberOfTransactionsFormatted(
        totalIncomingTransactions
      ),
      totalOutgoingTransactions: getNumberOfTransactionsFormatted(
        totalOutgoingTransactions
      ),
      totalIssuances
    };
  }, [filteredTransactions, transactions, overAllTransactionsByPeriod, period]);

  if (!isInitialized) {
    return (
      <Container>
        <LoadingData>
          <Loader />
        </LoadingData>
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <TitleContainer>
          <TransactionModeTabs
            mode={transactionsType}
            onSelect={(newTransactionsType: Mode) =>
              setTransactionsType(newTransactionsType)
            }
          />
        </TitleContainer>
      </Header>
      {isEmpty(transactions) ? (
        <NoData>
          <Title>NO TRANSACTIONS</Title>
        </NoData>
      ) : (
        <Content>
          <AssetBalancePeriod
            activePeriod={period}
            onChange={setCurrentPeriod}
          />
          <AssetBalanceChart
            chartData={chartData}
            period={period}
            chartLineType="monotone"
          />
          <ChartKeyValueTable
            keyValuePairs={{
              'TOTAL ISSUANCES': totalIssuances,
              'TOTAL INCOMING TRANSACTIONS': totalIncomingTransactions,
              'TOTAL OUTGOING TRANSACTIONS': totalOutgoingTransactions
            }}
          />
        </Content>
      )}
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.midDarkGrey};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  margin-bottom: 16px;
  position: relative;
`;

const TitleContainer = styled.div`
  margin: 0 auto;
`;

const NoData = styled.div`
  margin-top: 40px;
  text-align: center;
`;

const LoadingData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export default DashboardAssetBalance;
