import { useContext } from 'react';
import { createStore } from 'reusable';
import { get } from 'lodash/fp';

import { AuthContext } from 'context/auth.context';

import { useApiRequest } from 'hooks/api.hooks';

import { Rule } from 'stores/wallet/rules.store';

const DELETE_RULE = 'delete rule';

export const useDeleteRule = createStore(() => {
  const apiRequest = useApiRequest();
  const { user } = useContext(AuthContext);

  const apiAddress = get('node.address', user);
  const authToken = get('node.auth_token', user);

  const deleteWalletRule = (
    wallet_id: string,
    authorization: string,
    rules_to_delete: Rule[],
    onSuccess: (value: any) => void = () => {}
  ) =>
    apiRequest({
      path: '/wallet/delete_rule',
      method: 'POST',
      baseUrl: apiAddress,
      headers: { 'x-auth-token': authToken },
      data: {
        wallet_id,
        authorization,
        rules_to_delete
      },
      networkLabel: DELETE_RULE,
      onSuccess: onSuccess
    });

  return {
    deleteWalletRule
  };
});
