import { useContext, useState } from 'react';
import { createStore } from 'reusable';
import { get } from 'lodash/fp';

import { AuthContext } from 'context/auth.context';

import { useApiRequest } from 'hooks/api.hooks';
import { Balances } from 'types/wallet.types';

const initialValue = {};

type Props = {
  walletId: string;
  onSuccess?: (balances: Balances) => void;
};

export const BALANCES_LABEL = 'balances label';

export const useBalances = createStore(() => {
  const [balances, setBalances] = useState(initialValue);

  const apiRequest = useApiRequest();
  const { user } = useContext(AuthContext);

  const address = get('node.address', user);
  const authToken = get('node.auth_token', user);

  const fetchBalances = ({ walletId, onSuccess = setBalances }: Props) =>
    apiRequest({
      path: 'wallet/get_balances',
      method: 'POST',
      baseUrl: address,
      headers: { 'x-auth-token': authToken },
      data: { wallet_id: walletId },
      networkLabel: BALANCES_LABEL,
      onSuccess,
      onError: console.error
    });

  return {
    balances,
    fetchBalances
  };
});
