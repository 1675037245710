import React, { useState } from 'react';
import styled from '@emotion/styled';
import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';
import { Box } from '@rebass/grid/emotion';

import {
  Confirmation,
  NoConfirmation
} from 'components/common/confirmationIcons';
import { ReactComponent as Trash } from 'assets/images/trash.svg';
import { MediumText, Title } from 'components/common/typography';

import { Rule } from 'stores/wallet/rules.store';

interface Props {
  rule: Rule;
  index: number;
  isDeleted: boolean;
  isEditable: boolean;
  deletePermission: (index: number) => void;
  removeDelete: (index: number) => void;
}

export const PermissionItem = ({
  rule,
  index,
  isDeleted,
  isEditable,
  deletePermission,
  removeDelete
}: Props) => {
  const [isHovered, setIsHovered] = useState(false);

  const canIssueConfidentially = get('can_issue_confidentially', rule);
  const namespace = get('namespace', rule);

  const getIsConfidentialIssuanceIcon = (canIssueConfidentially: boolean) =>
    canIssueConfidentially ? (
      <Box ml="63px">
        <Confirmation />
      </Box>
    ) : (
      <Box ml="63px">
        <NoConfirmation />
      </Box>
    );

  if (isEmpty(namespace)) {
    return null;
  }

  return (
    <FormContentRow
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <Namespace isHighlighted={isDeleted}>{namespace}</Namespace>
      <ConfidentialIconContainer>
        {getIsConfidentialIssuanceIcon(canIssueConfidentially)}
        {isEditable && (
          <>
            <TrashIcon
              isHighlighted={isHovered}
              onClick={() => deletePermission(index)}
            />
            <DeleteText
              isDisplayed={isDeleted || isHovered}
              onClick={() => deletePermission(index)}>
              Delete
            </DeleteText>
            <RemoveButton
              isDisplayed={isDeleted}
              onClick={() => removeDelete(index)}>
              X
            </RemoveButton>
          </>
        )}
      </ConfidentialIconContainer>
    </FormContentRow>
  );
};

const FormContentRow = styled.div`
  display: grid;
  grid-template-columns: 70% 30%;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 25px;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.lightBlue};
  }
`;

const ConfidentialIconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Namespace = styled(Title)<{ isHighlighted: boolean }>`
  color: ${({ isHighlighted, theme }) =>
    isHighlighted && theme.colors.lightBlue};
`;

const TrashIcon = styled(Trash)<{ isHighlighted: boolean }>`
  height: 25px;
  width: 25px;
  margin-left: 45px;
  cursor: pointer;

  ${({ isHighlighted, theme }) =>
    isHighlighted &&
    `
    .trash-icon {
      fill: ${theme.colors.lightBlue};
    }
  `}
`;

const DeleteText = styled(MediumText)<{ isDisplayed: boolean }>`
  color: ${({ theme }) => theme.colors.lightBlue};
  font-size: 13px;
  font-weight: 600;
  margin-left: 10px;
  cursor: pointer;
  display: ${({ isDisplayed }) => !isDisplayed && 'none'};
`;

const RemoveButton = styled(MediumText)<{ isDisplayed: boolean }>`
  margin: auto 0 auto 10px;
  background-color: ${({ theme }) => theme.colors.midDarkGrey};
  color: ${({ theme }) => theme.colors.white};
  font-weight: 600;
  font-size: 12px;
  height: 18px;
  width: 18px;
  border-radius: 50%;

  cursor: pointer;
  ${({ isDisplayed }) =>
    isDisplayed
      ? `display: flex; 
         justify-content: center;
         align-items: center;`
      : `display: none`};
`;
