import React from 'react';
import styled from '@emotion/styled';

import { ReactComponent as WarningIcon } from 'assets/images/warning-icon.svg';

interface Props {
  children: string;
  isOpen: boolean;
  backgroundColor?: string;
  fill?: string;
}

export const WarningLabel = ({
  isOpen,
  children,
  backgroundColor,
  fill
}: Props) => {
  return (
    <Container isOpen={isOpen} backgroundColor={backgroundColor}>
      <Icon fill={fill} />
      <Label>{children}</Label>
    </Container>
  );
};

export const Container = styled.div<{
  isOpen: boolean;
  backgroundColor?: string;
}>`
  height: ${({ isOpen }) => (isOpen ? '50px' : '0px')};
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor ? backgroundColor : theme.colors.lightYellow};
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: ease-in-out 0.3s;
`;

const Icon = styled(WarningIcon)<{ fill?: string }>`
  width: 30px;
  height: 30px;
  margin-right: 15px;

  ${({ fill }) =>
    fill &&
    `
    .cls-2, cls-3 {
        fill: ${fill};
    }
  `}
`;

const Label = styled.div`
  color: ${({ theme }) => theme.colors.midDarkGrey};
  font-family: Montserrat-Regular;
  font-size: 12px;
  font-weight: 500;
`;
