import styled from '@emotion/styled';
import { css } from '@emotion/core';

type Props = {
  color: string;
  size: number;
  boxShadow?: string;
};
export const Circle = styled.div<Props>`
  background-color: ${({ color }) => color};
  border-radius: 50%;

  ${({ boxShadow }) =>
    boxShadow &&
    `
    box-shadow: ${boxShadow};
  `}

  ${({ size }) => css`
    width: ${size}px;
    height: ${size}px;
  `};
`;
export default Circle;
