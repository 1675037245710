import * as React from 'react';
import { ThemeProvider } from 'emotion-theming';
import { Helmet } from 'react-helmet';

import Localization from 'components/localization';

import theme from 'constants/themes.constants';

import { ReusableProvider } from 'reusable';

import { AuthProvider } from 'context/auth.context';

import { AppRouter } from './app-router';

class App extends React.Component<{}> {
  render() {
    return (
      <AuthProvider>
        <ReusableProvider>
          <Localization>
            <ThemeProvider theme={theme}>
              <Helmet>
                <title>QEDIT</title>
              </Helmet>
              <AppRouter />
            </ThemeProvider>
          </Localization>
        </ReusableProvider>
      </AuthProvider>
    );
  }
}

export default App;
