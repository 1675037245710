import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { Backdrop } from 'components/common/backdrop';
import { Title } from 'components/common/typography';
import OutsideClickHandler from 'react-outside-click-handler';
import isNumber from 'lodash/fp/isNumber';

type Props = {
  children: ReactNode;
  onClose: () => void;
  paddingRight?: number;
  paddingLeft?: number;
  paddingTop?: number;
  paddingBottom?: number;
};

export const Modal = ({
  children,
  onClose,
  paddingTop,
  paddingRight,
  paddingBottom,
  paddingLeft
}: Props) => (
  <Backdrop>
    <OutsideClickHandler onOutsideClick={onClose}>
      <Container
        paddingTop={paddingTop}
        paddingRight={paddingRight}
        paddingBottom={paddingBottom}
        paddingLeft={paddingLeft}>
        <CloseButton onClick={onClose}>
          <Title>X</Title>
        </CloseButton>
        {children}
      </Container>
    </OutsideClickHandler>
  </Backdrop>
);

export default Modal;

const Container = styled.div<{
  paddingRight?: number;
  paddingLeft?: number;
  paddingTop?: number;
  paddingBottom?: number;
}>`
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  box-shadow: 0 0 28px 0 rgba(0, 0, 0, 0.11);
  padding-top: ${({ paddingTop }) =>
    isNumber(paddingTop) ? `${paddingTop}px` : '30px'};
  padding-right: ${({ paddingRight }) =>
    isNumber(paddingRight) ? `${paddingRight}px` : '130px'};
  padding-bottom: ${({ paddingBottom }) =>
    isNumber(paddingBottom) ? `${paddingBottom}px` : '30px'};
  padding-left: ${({ paddingLeft }) =>
    isNumber(paddingLeft) ? `${paddingLeft}px` : '130px'};
`;

const CloseButton = styled.div`
  color: ${({ theme }) => theme.colors.midDarkGrey};
  position: absolute;
  top: 17px;
  right: 17px;
  cursor: pointer;
`;
