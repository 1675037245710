import React from 'react';
import styled from '@emotion/styled';
import css from '@emotion/css';
import { Period } from 'types/asset-balance.types';
import { BoldText, Text } from 'components/common/typography';

const periods: Period[] = [
  'last 15 minutes',
  'day',
  'week',
  'month',
  'last 3 months',
  'year'
];

type Props = {
  activePeriod: Period;
  onChange: (period: Period) => any; //todo: fix return type
};

export const AssetBalancePeriod = ({ activePeriod, onChange }: Props) => {
  return (
    <Container>
      {periods.map(currentPeriod => {
        const isActive = currentPeriod === activePeriod;
        return (
          <PeriodItemContainer
            key={currentPeriod}
            onClick={() => onChange(currentPeriod)}>
            <PeriodItem isActive={isActive}>
              {isActive ? (
                <BoldText>{currentPeriod}</BoldText>
              ) : (
                <Text>{currentPeriod}</Text>
              )}
            </PeriodItem>
          </PeriodItemContainer>
        );
      })}
    </Container>
  );
};

// todo: move to theme
const PeriodItemContainer = styled.div`
  flex: 1 0;
  text-align: center;
  &:first-of-type {
    text-align: left;
  }
  &:last-of-type {
    text-align: right;
  }
  color: ${({ theme }) => theme.colors.midDarkGrey};
  text-transform: uppercase;
  cursor: pointer;
`;

const PeriodItem = styled.div<{ isActive: boolean }>`
  position: relative;
  width: min-content;
  white-space: nowrap;
  margin: 0 auto;

  ${({ isActive }) =>
    isActive &&
    css`
      &:after {
        content: '';
        background-color: #38b1df;
        width: 7px;
        height: 7px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 100%);
        border-radius: 50%;
      }
    `};
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 26px;
  margin-bottom: 15px;
`;
