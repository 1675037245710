import { useContext, useState } from 'react';
import { createStore } from 'reusable';

import { AuthContext } from 'context/auth.context';

import { useApiRequest } from 'hooks/api.hooks';
import { get } from 'lodash/fp';

const GET_ADDRESS_LABEL = 'balances label';

export interface NewAddress {
  recipient_address: string;
  wallet_id: string;
}

export const useGetNewAddress = createStore(() => {
  const apiRequest = useApiRequest();
  const { user } = useContext(AuthContext);
  const [address, setNewAddress] = useState<NewAddress>();
  const [error, setError] = useState(null);

  const apiAddress = get('node.address', user);
  const authToken = get('node.auth_token', user);

  const fetchNewAddress = (walletId: string) =>
    apiRequest({
      path: '/wallet/get_new_address',
      method: 'POST',
      baseUrl: apiAddress,
      headers: { 'x-auth-token': authToken },
      data: { wallet_id: walletId },
      networkLabel: GET_ADDRESS_LABEL,
      onSuccess: setNewAddress,
      onError: console.error
    });

  return {
    address,
    setNewAddress,
    error,
    setError,
    fetchNewAddress
  };
});
