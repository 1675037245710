import React, { useState } from 'react';
import styled from '@emotion/styled';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  LineType
} from 'recharts';
import { debounce } from 'lodash/fp';
import moment from 'moment';

import { AssetBalanceTooltip } from './asset-balance-tooltip';
import ChartLabel from 'components/common/chart-label';
import ChartActiveDot from 'components/common/chart-active-dot';

import { ChartData } from 'types/chart.types';
import { Period } from 'types/asset-balance.types';

import { getFormattedAmount } from 'utils/wallet.utils';

type Props = {
  chartData: ChartData;
  period: Period;
  chartLineType: LineType;
};

const CHART_PERIODS_FOR_HOUR_MINUTE_FORMATTING: Period[] = [
  'last 15 minutes',
  'day'
];

const HOUR_MINUTE_FORMATTER = 'HH:mm';
const DATE_MONTH_FORMATTER = 'DD/MM';

const AssetBalanceChart = (props: Props) => {
  const { chartData, period, chartLineType } = props;
  const [, setTooltipX] = useState<number>(0);

  const onAreaChartMouseOver = debounce(10, (event: any) => {
    const { chartX } = event;
    setTooltipX(chartX);
  });

  const xAxisDateFormatter = (value: number) => {
    const dateFormatter = CHART_PERIODS_FOR_HOUR_MINUTE_FORMATTING.includes(
      period
    )
      ? HOUR_MINUTE_FORMATTER
      : DATE_MONTH_FORMATTER;

    return moment(value).format(dateFormatter);
  };

  return (
    <Container>
      <ResponsiveContainer width="99%" height={170}>
        <AreaChart data={chartData} onMouseMove={onAreaChartMouseOver}>
          <defs>
            <linearGradient id="colorBalance" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#b7ebff" />
              <stop offset="95%" stopColor="#fff" />
            </linearGradient>
          </defs>
          <XAxis
            tick={<ChartLabel dy={10} valueFormatter={xAxisDateFormatter} />}
            dataKey="date"
            domain={['dataMin', 'dataMax']}
            type="number"
            axisLine={false}
            tickLine={false}
            padding={{ left: 10, right: 10 }}
            tickCount={5}
          />
          <YAxis
            domain={['dataMin', 'dataMax']}
            tickCount={3}
            tickSize={4}
            axisLine={false}
            type="number"
            padding={{ top: 10, bottom: 10 }}
            tick={
              <ChartLabel dy={4} dx={0} valueFormatter={getFormattedAmount} />
            }
          />

          <Area
            isAnimationActive={false}
            type={chartLineType}
            dataKey="balance"
            stroke="#38b1df"
            strokeWidth={3}
            fillOpacity={1}
            fill="url(#colorBalance)"
            activeDot={ChartActiveDot}
            dot={false}
          />

          <Tooltip content={AssetBalanceTooltip} />
        </AreaChart>
      </ResponsiveContainer>
    </Container>
  );
};

AssetBalanceChart.defaultProps = {
  chartLineType: 'stepAfter'
};

export default AssetBalanceChart;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
