import React from 'react';
import styled from '@emotion/styled';
import map from 'lodash/fp/map';
import flow from 'lodash/fp/flow';
import isEmpty from 'lodash/fp/isEmpty';
import orderBy from 'lodash/fp/orderBy';

import { Notification } from 'components/notifications-handler/notification';

import { useUserTransactions } from 'stores/user/node-transactions.store';

interface Props {
  isOpen: boolean;
}
export const NotificationsList = ({ isOpen }: Props) => {
  const { transactions } = useUserTransactions();

  if (isEmpty(transactions)) {
    return null;
  }
  return (
    <Container isOpen={isOpen}>
      {flow(
        orderBy(['order'], 'desc'),
        map((transaction: any) => (
          <Notification key={transaction.id} transaction={transaction} />
        ))
      )(transactions)}
    </Container>
  );
};

const Container = styled.div<{ isOpen: boolean }>`
  max-height: 0;
  overflow: hidden;
  transition: 0.3s ease-in-out;

  ${({ isOpen }) =>
    isOpen &&
    `
    max-height: 250px;
    overflow: auto;
  `}
`;
