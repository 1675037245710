import React, { createContext, FunctionComponent } from 'react';

import {
  useAuthenticate,
  useUserData
} from 'hooks/firebase/firebase.auth.hooks';

import { Loader } from 'components/common/loader';

const initialStore: any = {};

export const AuthContext = createContext(initialStore);

export const AuthProvider: FunctionComponent = ({ children }) => {
  const { isAuthenticated } = useAuthenticate();
  const { user, userEmail, userId } = useUserData();

  if (
    isAuthenticated === 'uninitialized' ||
    (isAuthenticated === 'authorized' && !user)
  ) {
    return <Loader />;
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, userEmail, user, userId }}>
      {children}
    </AuthContext.Provider>
  );
};
