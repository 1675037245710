import React from 'react';
import { Text } from 'components/common/typography';
import styled from '@emotion/styled';
import {
  TransactionHashContainer,
  BlockHeightContainer,
  BlockTypeContainer,
  BlockHashContainer,
  ViewTransactionProofContainer,
  TransactionTimeContainer
} from './block-explorer-table-components';

const BlockExplorerTableHeader = () => {
  return (
    <Container>
      <BlockHeightContainer>
        <Text>Block Height</Text>
      </BlockHeightContainer>
      <BlockTypeContainer>
        <Text>Type</Text>
      </BlockTypeContainer>
      <BlockHashContainer>
        <Text>Block Hash</Text>
      </BlockHashContainer>
      <TransactionHashContainer>
        <Text>Tx Hash</Text>
      </TransactionHashContainer>
      <TransactionTimeContainer>
        <Text>Time</Text>
      </TransactionTimeContainer>
      <ViewTransactionProofContainer />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  flex: 0 1 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ theme }) => theme.colors.midDarkGrey};
  white-space: nowrap;
`;

export default BlockExplorerTableHeader;
