import { useGenerateWallet } from 'stores/wallet/generate-wallet.store';
import { useGetNewAddress } from 'stores/wallet/address.store';
import { usePublicKey } from 'stores/wallet/public-key.stores';

import { addNewWallet as addNewWalletToFirebase } from 'hooks/firebase/firebase.hooks';

export const useCreateNewWallet = () => {
  const { generateWallet } = useGenerateWallet();

  const { fetchNewAddress } = useGetNewAddress();
  const { fetchPublicKey } = usePublicKey();

  async function createNewWallet(walletId: string, walletAuth: string) {
    try {
      await generateWallet(walletId, walletAuth);

      const { recipient_address: recipientAddress } = await fetchNewAddress(
        walletId
      );
      const { public_key: publicKey } = await fetchPublicKey(
        walletId,
        () => {}
      );

      addNewWalletToFirebase(recipientAddress, publicKey, walletAuth, walletId);
    } catch (e) {
      console.log('error in createNewWallet', e);
    }
  }

  return {
    createNewWallet
  };
};
