import { createStore } from 'reusable';
import { useRef, useState } from 'react';
import { Dictionary } from 'lodash';

import { ModifiedTask, RuleTask } from 'types/tasks.types';

export const useUserTransactions = createStore(() => {
  const [transactions, setTransactions] = useState<
    Dictionary<ModifiedTask | RuleTask>
  >({});

  const transactionsRef = useRef({});
  const blackListRef = useRef({});
  const orderRef = useRef<null | number>(null);
  const failedTransactionsCount = useRef<Dictionary<number>>({});

  return {
    transactions,
    setTransactions,
    transactionsRef,
    blackListRef,
    orderRef,
    failedTransactionsCount
  };
});
