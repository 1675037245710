import React, { ElementType } from 'react';
import styled from '@emotion/styled';
import { Title, BoldTitle } from 'components/common/typography';

interface Props {
  text: string;
  icon: ElementType;
  onClick: () => void;
  isOpen: boolean;
}

export const IconTextButton = ({
  text,
  icon: Icon,
  onClick,
  isOpen
}: Props) => {
  return (
    <Container onClick={onClick} isOpen={isOpen}>
      <Icon />
      <Text isOpen={isOpen}>
        {isOpen ? <BoldTitle>{text}</BoldTitle> : <Title>{text}</Title>}
      </Text>
    </Container>
  );
};

const Container = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  padding-bottom: 5px;
  cursor: pointer;

  svg {
    height: 20px;
    padding-left: 5px;
    margin-right: 5px;
  }

  ${({ isOpen, theme }) =>
    isOpen &&
    `
    border-bottom: 2px solid ${theme.colors.lightBlue};
    svg {
      #Ellipse_1,
      #Ellipse_2,
      #Ellipse_3,
      #Path_1,
      #Path_2 {
      stroke: ${theme.colors.lightBlue};
      box-shadow: 0 0 28px 0 rgba(0, 0, 0, 0.11);
    }
    }
  `};
`;

const Text = styled.div<{ isOpen: boolean }>`
  color: ${({ theme }) => theme.colors.midDarkGrey};
  margin-right: 5px;
`;
