import { AddressBookStore } from 'stores/user/address-book';
import { mapWithKeys } from 'utils/lodash.utils';

export const addressesSelector = (state: AddressBookStore) => {
  const { addressBook } = state;
  return mapWithKeys(
    (value: string, key: string) => ({ value: addressBook[key], text: key }),
    addressBook
  );
};
