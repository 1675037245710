import { pick, compact, castArray } from 'lodash/fp';
import { useNetwork } from 'stores/network.store';
import { request, Request } from 'utils/api.utils';

interface RequestParams<Response, TransformedResponse> extends Request {
  networkLabel?: string;
  transform?: (data: Response) => TransformedResponse;
  onSuccess?: (
    data: TransformedResponse
  ) => void | Array<(data: TransformedResponse) => void>;
  onError?: (data: Error) => void;
  args?: any;
}

const noop = (val: any) => val;

export function dispatchActions(
  onSuccess: (
    data: any,
    args?: any
  ) => void | Array<(data: any, args?: any) => void>,
  result: any,
  args?: any
) {
  compact(castArray(onSuccess)).forEach(onSuccess => {
    onSuccess(result, args);
  });
}

export const useApiRequest = () => {
  const { startNetwork, endNetwork } = useNetwork(
    pick(['startNetwork', 'endNetwork'])
  );

  return async <Response, TransformedResponse = Response>({
    networkLabel,
    onSuccess,
    transform,
    onError,
    args,
    ...rest
  }: RequestParams<Response, TransformedResponse>) => {
    startNetwork(networkLabel);

    try {
      const body = await request(rest);
      const result = transform ? transform(body) : body;
      onSuccess && dispatchActions(onSuccess, result, args);

      endNetwork(networkLabel);
      return result;
    } catch (error) {
      onError && dispatchActions(onError, error);
      endNetwork(networkLabel);
    }
  };
};
