import React, { useState, useRef } from 'react';
import styled from '@emotion/styled';

import loader from 'assets/images/spinner.svg';

import theme from 'constants/themes.constants';

import { Backdrop } from 'components/common/backdrop';
import { ModalTitle as Title } from 'components/common/modal/modal-title';
import { ModalSubtitle as Subtitle } from 'components/common/modal/modal-subtitle';
import { Button } from 'components/common/button';

import { useIsForgotPasswordOpen } from 'stores/ui/password-modal-ui.store.ts';

import { sendPasswordResetEmailRequest } from 'hooks/firebase/firebase.auth.hooks';
import { TextField } from 'components/common/text-field';

//todo: fix forgot password flow (show message and close after a few seconds without showing an error message)
export const ForgotPasswordModal = () => {
  const [isValid, setIsValid] = useState(true);
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [, setIsForgotPasswordOpen] = useIsForgotPasswordOpen();

  const handleSubmit = () => {
    sendPasswordResetEmailRequest(email, `http://localhost:3000/login`)
      .then(() => {
        setIsLoading(true);
        setTimeout(() => setIsForgotPasswordOpen(false), 1500);
      })
      .catch(e => {
        setIsValid(false);
        console.log('error: ', e);
      });
  };

  if (isLoading) {
    return (
      <Backdrop background={theme.colors.backdrop}>
        <Container isLoading={isLoading}>
          <Loader />
        </Container>
      </Backdrop>
    );
  }

  return (
    <Backdrop background="rgba(112, 112, 112, 0.5)">
      <Container isLoading={isLoading}>
        <CloseButton onClick={() => setIsForgotPasswordOpen(false)}>
          X
        </CloseButton>
        <Title text="Forgot your password?" margin="0 0 30px" align="center" />
        <Subtitle
          text="Enter your email address and we'll send you instructions how to reset
          your password."
          margin="0 0 60px"
          align="center"
        />
        <TextFieldContainer error={!isValid}>
          <TextField
            type="text"
            label="Email address"
            autoFocus={true}
            value={email}
            onChange={setEmail}
            error={!isValid}
            helperText={
              !isValid
                ? 'There is no account associated with this email address'
                : ''
            }
          />
        </TextFieldContainer>
        <ButtonContainer>
          <Button
            onClick={handleSubmit}
            variant={'primary'}
            text={'Send reset instructions'}
            width="280px"
            fontSize={'15px'}
          />
        </ButtonContainer>
      </Container>
    </Backdrop>
  );
};

const Container = styled.div<{ isLoading: boolean }>`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.colors.boxShadow};
  width: 740px;
  height: 415px;
  padding: 40px 130px 55px;
  border-radius: 8px;
  position: relative;

  ${({ isLoading }) =>
    isLoading &&
    `
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
`;

const Loader = styled.div`
  background: url(${loader}) no-repeat center;
  background-size: contain;
  width: 100px;
  height: 100px;
`;

const CloseButton = styled.div`
  ${({ theme }) => theme.typography.closeButton};
  color: ${({ theme }) => theme.colors.midDarkGrey};
  position: absolute;
  top: 17px;
  right: 17px;
  cursor: pointer;
`;

const TextFieldContainer = styled.div<{
  error?: boolean;
}>`
  .input {
    margin-bottom: ${({ error }) => (error ? '4px' : '80px')};
  }

  .helper-text {
    margin-bottom: ${({ error }) => (error ? '64px' : '4px')};
  }
`;
