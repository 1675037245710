import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';
import get from 'lodash/fp/get';
import size from 'lodash/fp/size';
import find from 'lodash/fp/find';
import isEmpty from 'lodash/fp/isEmpty';

import { Card } from 'components/common/card';
import { Header } from 'components/wallet/header';
import { NotFound } from 'components/common/not-found';
import { AssetsList } from 'components/wallet/assets/assets-list';
import { TotalAssets } from 'components/wallet/assets/total-assets';
import { AssetBalance } from 'components/wallet/asset-balance/wallet-asset-balance';
import { Transactions } from 'components/wallet/transactions/transactions';
import { ScrollableContainer } from 'components/common/page-containers/scrollable-container';

import history from 'utils/history.utils';
import { getOr } from 'utils/lodash.utils';
import { scrollToRef } from 'utils/common.utils';
import { getWalletIdFromUrl, getWalletAuth } from 'utils/wallet.utils';

import { usePublicKey } from 'stores/wallet/public-key.stores';
import { BALANCES_LABEL, useBalances } from 'stores/wallet/balances.store';

import theme from 'constants/themes.constants';

import { Address, Wallet as UserWallet } from 'types/firebase.types';
import { Asset, Transaction as TransactionType } from 'types/wallet.types';

import useTransactionsPolling from 'hooks/transactions.polling';

import { AuthContext } from 'context/auth.context';
import { useIsLoading } from 'stores/network.store';
import { useAddressBook } from 'stores/user/address-book';

export const Wallet = () => {
  const rightSideRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [rightSideStickyPosition, setRightSideStickyPosition] = useState(0);
  const wallets = get('user.wallets', useContext(AuthContext));

  const { balances } = useBalances();
  const { fetchPublicKey } = usePublicKey();
  const [isTotalAssetsInitialized, setIsTotalAssetsInitialized] = useState<
    boolean
  >(false);
  const isLoadingBalances = useIsLoading(BALANCES_LABEL);

  const walletId =
    get('walletId', history.location.state) ||
    getWalletIdFromUrl(history.location.pathname);

  const address: Address | undefined = useAddressBook(
    state => state.addressBook[walletId]
  );
  const walletAuth = useMemo(() => getWalletAuth(walletId, wallets), [
    walletId,
    wallets
  ]);

  const {
    transactions,
    fetchPreviousTransactions,
    fetchLatestTransactions,
    isLoading,
    hasMore,
    isInitialLoading
  } = useTransactionsPolling(walletId);

  const publicKey = get('public_key', address);
  const wallet = find<UserWallet>({ id: walletId }, wallets);

  const fetchTransactionsOnNewWalletEmptyTransactionState = (
    isInitialized: boolean,
    transactions: TransactionType[],
    assets: Asset[] | undefined
  ) => {
    // this handles fetchLatest when issuing new asset to new wallet & transactions is empty ("No transaction history.")
    if (!isInitialLoading && isEmpty(transactions) && !isEmpty(assets)) {
      fetchLatestTransactions();
    }
  };

  useEffect(() => {
    const assets = get('assets', balances);

    fetchTransactionsOnNewWalletEmptyTransactionState(
      isInitialLoading,
      transactions,
      assets
    );
  }, [balances.assets, transactions, isInitialLoading]);

  useEffect(() => {
    if (walletId) {
      fetchPublicKey(walletId);
    }
    setIsTotalAssetsInitialized(false);
  }, [walletId]);

  useEffect(() => {
    const assets = get('assets', balances);
    if (assets && !isLoadingBalances) {
      setIsTotalAssetsInitialized(true);
    }
  }, [balances, isLoadingBalances]);

  useEffect(() => {
    if (containerRef && containerRef.current) {
      scrollToRef(containerRef);
    }
  }, [history.location.pathname]);

  useEffect(() => {
    if (rightSideRef && rightSideRef.current) {
      const rightSideHeight = rightSideRef.current.offsetHeight;
      const windowInnerHeight = window.innerHeight;
      const heightFromViewPortTop = rightSideRef.current!.getBoundingClientRect()
        .top;
      // Position from bottom (same as wallet bottom padding)
      const spaceFromBottom = theme.container.paddingY;
      // Position from top (header + 10px buffer)
      const topPositionWhenNoOverflow = theme.headerHeight;

      if (rightSideHeight <= windowInnerHeight - heightFromViewPortTop) {
        setRightSideStickyPosition(topPositionWhenNoOverflow);
        return;
      }

      const rightSideHide =
        heightFromViewPortTop + rightSideHeight - windowInnerHeight;
      const topPosition =
        -rightSideHide + heightFromViewPortTop - spaceFromBottom;

      setRightSideStickyPosition(topPosition);
    }
  }, [
    size(balances.assets),
    rightSideRef && rightSideRef.current && rightSideRef.current.offsetHeight
  ]);

  if (!wallet) {
    return <NotFound />;
  }

  return (
    <ScrollableContainer>
      <Container ref={containerRef}>
        <Helmet>{walletId && <title>{walletId} | QEDIT</title>}</Helmet>
        <Header
          walletId={walletId}
          publicKey={publicKey}
          walletAuth={walletAuth}
          recipientAddress={address && address.recipient_address}
        />
        <Content>
          <LeftSide>
            <TopSide>
              <Card>
                <AssetBalance transactions={transactions} walletId={walletId} />
              </Card>
            </TopSide>
            <BottomSide>
              <Card padding={{ top: 0 }}>
                <Transactions
                  transactions={transactions}
                  walletId={walletId}
                  fetchPreviousTransactions={fetchPreviousTransactions}
                  isLoading={isLoading}
                  hasMore={hasMore}
                  isInitialLoading={isInitialLoading}
                />
              </Card>
            </BottomSide>
          </LeftSide>
          <RightSide position={rightSideStickyPosition} ref={rightSideRef}>
            <TopSide>
              <Card>
                <TotalAssets
                  assets={getOr([], 'assets', balances)}
                  isLoading={!isTotalAssetsInitialized}
                />
              </Card>
            </TopSide>
            <BottomSide>
              <Card>
                <AssetsList balances={balances} walletId={walletId} />
              </Card>
            </BottomSide>
          </RightSide>
        </Content>
      </Container>
    </ScrollableContainer>
  );
};

const Container = styled.div`
  ${({ theme }) => theme.scrollbar.main};
  width: 100%;
  padding: 25px 38px;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  position: relative;
`;
const LeftSide = styled.div`
  height: 100%;
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  padding: 25px ${({ theme }) => theme.cardSpacing.margin};
  margin-left: -${({ theme }) => theme.cardSpacing.margin};
  overflow-x: hidden;
`;

const RightSide = styled.div<{ position: number }>`
  height: fit-content;
  width: 288px;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: ${({ position }) => position}px;
  padding-top: 25px;
  padding-bottom: 25px;
`;

const TopSide = styled.div`
  height: 330px;
  margin-bottom: ${({ theme }) => theme.cardSpacing.margin};
`;

const BottomSide = styled.div`
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  min-height: fit-content;
`;
