import React from 'react';
import styled from '@emotion/styled';

import loader from 'assets/images/spinner.svg';

export const Loader = () => {
  return (
    <Container>
      <LoaderStyle />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoaderStyle = styled.div`
  background: url(${loader}) no-repeat center;
  background-size: contain;
  width: 100px;
  height: 100%;
`;

export default Loader;
