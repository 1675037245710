import React from 'react';
import styled from '@emotion/styled';
import { flatten, flow, find } from 'lodash/fp';
import moment from 'moment';
import { LightText, BoldText, Text } from 'components/common/typography';

import { TooltipContainer } from 'components/common/chart-tooltip-styles';

import { getOr } from 'utils/lodash.utils';

import {
  DailyTransactionTimes,
  ColorRange,
  HourlyTransactionTime
} from 'types/transaction-times.types';

import { shortWeekDaysDays } from 'constants/time.constants';
import theme from 'constants/themes.constants';

type Props = {
  dailyTransactionTimes: DailyTransactionTimes[];
  colorRanges: ColorRange[];
};

const getColorByNumberOfTransactions = (
  colorRanges: ColorRange[],
  numberOfTransactions: number
) => {
  if (numberOfTransactions === 0) {
    return theme.colors.grey;
  }

  return flow(
    find(
      (range: ColorRange) =>
        numberOfTransactions >= range.start && numberOfTransactions <= range.end
    ),
    getOr('', 'color')
  )(colorRanges);
};

const TransactionTimesTable = ({
  dailyTransactionTimes,
  colorRanges
}: Props) => {
  const items = flatten(
    dailyTransactionTimes.map(
      (currentDay: DailyTransactionTimes, dayIndex: number) => {
        return currentDay.hourlyTransactionTimes.map(
          (
            currentHourlyTransactionTime: HourlyTransactionTime,
            hourIndex: number
          ) => {
            const { numberOfTransactions, hour } = currentHourlyTransactionTime;
            const key = `${currentDay.date.getFullYear()}-${currentDay.date.getMonth()}-${currentDay.date.getDate()}-${hour}`;

            return (
              <HourlyTransactions
                style={{
                  gridColumnStart: dayIndex + 2,
                  gridRowStart: hourIndex + 1
                }}
                key={key}
                color={getColorByNumberOfTransactions(
                  colorRanges,
                  numberOfTransactions
                )}>
                <Tooltip>
                  <TooltipContainer>
                    <BoldText>{numberOfTransactions}</BoldText>
                    <Text>Transactions</Text>
                    <SingleLine>
                      <Text>
                        {moment(currentDay.date).format('dddd')}{' '}
                        {moment()
                          .hour(hourIndex)
                          .format('h A')}
                        -
                        {moment()
                          .hour(hourIndex + 1)
                          .format('h A')}{' '}
                      </Text>
                    </SingleLine>
                  </TooltipContainer>
                </Tooltip>
              </HourlyTransactions>
            );
          }
        );
      }
    )
  );
  //todo: use styled components to pass row/col props
  const hoursLabels = Array(24)
    .fill(0)
    .map((_, hourIndex) => (
      <HourLabel
        key={`hour-${hourIndex}`}
        style={{ gridRowStart: hourIndex + 1 }}>
        <LightText>
          {hourIndex % 2 === 0
            ? moment()
                .hour(hourIndex)
                .format('h A')
            : ''}
        </LightText>
      </HourLabel>
    ));

  const datesLabels = dailyTransactionTimes.map(
    (currentDay: DailyTransactionTimes, dayIndex: number) => {
      return (
        <DateLabel
          key={currentDay.date.toString()}
          style={{ gridColumnStart: dayIndex + 2 }}>
          <DayLabel>
            {dayIndex % 2 === 0 ? shortWeekDaysDays[dayIndex] : ''}
          </DayLabel>
        </DateLabel>
      );
    }
  );

  return (
    <Container>
      {items}
      {hoursLabels}
      {datesLabels}
    </Container>
  );
};

export default TransactionTimesTable;

const Container = styled.div`
  flex: 1 0 0;
  display: grid;
  grid-template-columns: 1.5fr repeat(7, 1fr);
  grid-template-rows: repeat(24, 1fr) 1.5fr;
  grid-gap: 1px;
  color: ${({ theme }) => theme.colors.midDarkGrey};
  max-height: 520px;
`;

//todo: remove text and font-size
const HourlyTransactions = styled.div<{
  color: string;
}>`
  background-color: ${({ color }) => color};
  position: relative;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.midDarkGrey};
  }
`;

const Tooltip = styled.div`
  display: none;
  position: absolute;
  left: 50%;
  top: -30px;
  transform: translate(-50%, -50%);
  z-index: 9999;
  text-align: center;
  pointer-events: none;

  ${HourlyTransactions}:hover & {
    display: block;
  }
`;

const HourLabel = styled.div`
  grid-column-start: 1;
  display: flex;
  align-items: center;
`;

const DateLabel = styled.div`
  grid-row-start: 25;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SingleLine = styled.div`
  white-space: nowrap;
`;

const DayLabel = styled(LightText)`
  font-size: 10px;
`;
