export const taskResultEnum = {
  success: 'success',
  inProgress: 'in_progress',
  pending: 'pending',
  failure: 'failure',
  awaitingUserInteraction: 'awaiting_user_interaction',
  confirmed: 'confirmed',
  canceled: 'canceled'
};

export const taskTypeEnum = {
  unlockWallet: 'unlock_wallet',
  transferAsset: 'transfer_asset',
  issueAsset: 'issue_asset',
  alterRule: 'alter_rule',
  importWallet: 'import_wallet',
  deleteWallet: 'delete_wallet',
  outGoingConfirmRequest: 'outgoing_confirm_request',
  incomingConfirmRequest: 'incoming_confirm_request',
  cancellation: 'cancellation',
  createRule: 'create_rule',
  deleteRule: 'delete_rule'
};
