import { useContext } from 'react';
import { createStore } from 'reusable';
import { get } from 'lodash/fp';

import { AuthContext } from 'context/auth.context';

import { useApiRequest } from 'hooks/api.hooks';

export const GENERATE_WALLET_LABEL = 'generate wallet label';

export const useGenerateWallet = createStore(() => {
  const apiRequest = useApiRequest();
  const { user } = useContext(AuthContext);

  const address = get('node.address', user);
  const authToken = get('node.auth_token', user);

  const generateWallet = (wallet_id: string, authorization: string) =>
    apiRequest({
      path: 'node/generate_wallet',
      method: 'POST',
      baseUrl: address,
      headers: { 'x-auth-token': authToken },
      data: { wallet_id, authorization },
      networkLabel: GENERATE_WALLET_LABEL,
      onError: console.error
    });

  return {
    generateWallet
  };
});
