import superagent from 'superagent';
import urljoin from 'url-join';

export type HttpMethod =
  | 'GET'
  | 'POST'
  | 'PUT'
  | 'DELETE'
  | 'PATCH'
  | 'HEAD'
  | 'OPTIONS';

export type Request = {
  method: HttpMethod;
  baseUrl?: string;
  path: string;
  data?: any;
  headers?: {
    [key: string]: string | undefined;
  };
};

const BASE_URL: string = process.env.REACT_APP_BASE_URL || '';

export const request = ({
  method = 'GET',
  baseUrl = '',
  path = '',
  data = null,
  headers = {}
}: Request) => {
  const url = urljoin(baseUrl || BASE_URL, path);
  const request = superagent(method, url).set('Accept', 'application/json');
  if (data) {
    if (method.toUpperCase() === 'GET') {
      request.query(data);
    } else {
      request.send(data);
    }
  }

  request.set(headers);

  return request.then(({ body }) => {
    return body;
  });
};
