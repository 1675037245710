import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import moment from 'moment';
import { filter, size, flow, max, map, flatten, uniq } from 'lodash/fp';

import { ReactComponent as HeaderIcon } from 'assets/images/transaction-times.svg';

import TransactionTimesTable from './transaction-times-table';
import IconicText from 'components/common/iconic-text';
import TransactionTimesLegend from './transaction-times-legend';

import { getChartColorRange } from 'utils/chart.utils';
import {
  ColorRange,
  DailyTransactionTimes,
  HourlyTransactionTime
} from 'types/transaction-times.types';
import { Transaction } from 'types/wallet.types';
import Loader from 'components/common/loader';

type Props = {
  transactions: Transaction[];
  isLoading: boolean;
};

const getDailyTransactionTimes = (
  transactions: Transaction[]
): DailyTransactionTimes[] => {
  const previousWeekStart = moment()
    .startOf('week')
    .subtract(1, 'week');

  const transactionsByDate: DailyTransactionTimes[] = Array(7)
    .fill(0)
    .map((_, dayIndex) => {
      const currentDay = previousWeekStart.clone().add(dayIndex, 'day');
      const currentDayStart = currentDay.clone().startOf('day');
      const currentDayEnd = currentDay.clone().endOf('day');

      const currentDayTransactions = filter((transaction: Transaction) => {
        return moment(transaction.metadata.timestamp).isBetween(
          currentDayStart,
          currentDayEnd
        );
      }, transactions);

      const hourlyTransactionTimes = Array(24)
        .fill(0)
        .map((_, hourIndex) => {
          const currentHour = hourIndex + 1;
          const currentHourStart = currentDay
            .clone()
            .hour(currentHour)
            .startOf('hour');
          const currentHourEnd = currentDay
            .clone()
            .hour(currentHour)
            .endOf('hour');

          const currentHourTransactionsCount = flow(
            filter((transaction: Transaction) => {
              return moment(transaction.metadata.timestamp).isBetween(
                currentHourStart,
                currentHourEnd
              );
            }),
            size
          )(currentDayTransactions);

          return {
            hour: currentHour,
            numberOfTransactions: currentHourTransactionsCount
          };
        });

      return {
        date: currentDay.toDate(),
        hourlyTransactionTimes
      };
    });

  return transactionsByDate;
};

const TransactionTimes = ({ transactions, isLoading }: Props) => {
  const dailyTransactionTimes = useMemo(
    () => getDailyTransactionTimes(transactions),
    [transactions]
  );
  const transactionsNumberOfHighestDay: number | undefined = useMemo(
    () =>
      flow(
        map((dailyTransaction: DailyTransactionTimes) => {
          return dailyTransaction.hourlyTransactionTimes.map(
            (hourlyTransaction: HourlyTransactionTime) =>
              hourlyTransaction.numberOfTransactions
          );
        }),
        flatten,
        uniq,
        max
      )(dailyTransactionTimes),
    [dailyTransactionTimes]
  );

  const colorRanges = useMemo(
    () => getChartColorRange(transactionsNumberOfHighestDay),
    [transactionsNumberOfHighestDay]
  );

  return (
    <Container>
      <TitleContainer>
        <IconicText text="BUSIEST TIME OF DAY" icon={HeaderIcon} />
      </TitleContainer>

      {!isLoading ? (
        <>
          <TransactionTimesTable
            dailyTransactionTimes={dailyTransactionTimes}
            colorRanges={colorRanges}
          />
          <TransactionTimesLegend colorRanges={colorRanges} />
        </>
      ) : (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

const TitleContainer = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export default TransactionTimes;
