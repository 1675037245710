import { RefObject } from 'react';
import get from 'lodash/fp/get';
import isNumber from 'lodash/fp/isNumber';
import startsWith from 'lodash/fp/startsWith';

import history from 'utils/history.utils';
import { getWalletIdFromUrl } from 'utils/wallet.utils';

import { ModifiedTask, Task, RuleTask } from 'types/tasks.types';

export const getNotificationOrder = (orderRef: any) => {
  const orderRefValue = get('current', orderRef);
  let order: number | null = null;

  if (!orderRefValue && orderRefValue !== 0) {
    order = 0;
    orderRef.current = 0;
  } else if (isNumber(orderRefValue)) {
    order = orderRefValue + 1;
    orderRef.current = orderRefValue + 1;
  }

  return order;
};

export const setTransferAndIssuance = (
  status: Task,
  amount: string,
  assetId: string,
  sender: string,
  recipient: string,
  orderRef: RefObject<number>,
  transactionsRef: { [key: string]: any },
  setTransactions: (transactions: { [key: string]: ModifiedTask }) => void
) => {
  const order = getNotificationOrder(orderRef);
  const newStatus: ModifiedTask = {
    id: status.id,
    type: status.type,
    result: status.result,
    createdAt: status.created_at,
    recipient,
    sender,
    assetId,
    amount,
    order
  };

  transactionsRef.current = {
    ...transactionsRef.current,
    [status.id]: newStatus
  };

  setTransactions({ ...transactionsRef.current });
};

export const setNotificationResultChange = (
  task: Task,
  transactionsRef: any,
  setTransactions: (transactions: { [key: string]: ModifiedTask }) => void
) => {
  transactionsRef.current = {
    ...transactionsRef.current,
    [task.id]: {
      ...transactionsRef.current[task.id],
      result: task.result
    }
  };

  setTransactions({ ...transactionsRef.current });
};

export const updateCurrentWalletBalancesAfterSuccessfulTask = (
  sender: string,
  recipient: string,
  fetchBalances: ({ walletId }: { walletId: string }) => void
) => {
  const walletId = startsWith('/wallet/', history.location.pathname)
    ? getWalletIdFromUrl(history.location.pathname)
    : undefined;

  if (!walletId) {
    return;
  }

  if (walletId === recipient || walletId === sender) {
    fetchBalances({ walletId });
  }
};

export const setFailedTransactionCount = (
  taskId: string,
  failedTransactionsCount: any
) => {
  const count = get('current[taskId]', failedTransactionsCount);

  failedTransactionsCount.current = {
    ...failedTransactionsCount.current,
    [taskId]: count ? 2 : 1
  };
};

export const setNewFailedTransaction = (
  taskId: string,
  amount: string,
  assetId: string,
  sender: string,
  recipient: string,
  type: string,
  transactionsRef: any,
  setTransactions: (transactions: { [key: string]: ModifiedTask }) => void
) => {
  transactionsRef.current = {
    ...transactionsRef.current,
    [taskId]: {
      amount,
      assetId,
      created_at: Date.now(),
      taskId,
      result: 'failure',
      sender,
      recipient,
      type
    }
  };

  setTransactions({ ...transactionsRef.current });
};

export const setTransactionIsFailed = (
  taskId: string,
  transactionsRef: any,
  setTransactions: (transactions: { [key: string]: ModifiedTask }) => void
) => {
  transactionsRef.current = {
    ...transactionsRef.current,
    [taskId]: {
      ...transactionsRef.current[taskId],
      result: 'failure'
    }
  };

  setTransactions({ ...transactionsRef.current });
};

export const setRule = (
  task: Task,
  influencedWallet: string,
  orderRef: any,
  transactionsRef: any,
  setTransactions: (transactions: { [key: string]: ModifiedTask }) => void
) => {
  const order = getNotificationOrder(orderRef);

  const newStatus: RuleTask = {
    id: task.id,
    type: task.type,
    result: task.result,
    createdAt: task.created_at,
    influencedWallet,
    order
  };

  transactionsRef.current = {
    ...transactionsRef.current,
    [task.id]: newStatus
  };

  setTransactions({ ...transactionsRef.current });
};
