import React from 'react';
import styled from '@emotion/styled';

import { SemiBoldTitle } from 'components/common/typography';

import { getFormattedAmount } from 'utils/wallet.utils';

import { taskResultEnum, taskTypeEnum } from 'constants/tasks.constants';

import { ModifiedTask } from 'types/tasks.types';

interface Props {
  transaction: ModifiedTask;
}

export const NotificationTitle = ({ transaction }: Props) => {
  const {
    recipient,
    result,
    amount,
    type,
    assetId,
    sender,
    influencedWallet
  } = transaction;

  const formattedAmount: string = getFormattedAmount(amount);
  const amountAssetText: string = `${formattedAmount} ${assetId}`;

  if (type === taskTypeEnum.issueAsset) {
    return result === taskResultEnum.success ? (
      <Title>
        Issuance completed<SubTitle>to {recipient}</SubTitle>
      </Title>
    ) : (
      <>
        <Title>
          Issuance of {amountAssetText} in progress
          <SubTitle>to {recipient}</SubTitle>
        </Title>
      </>
    );
  }

  if (type === taskTypeEnum.incomingConfirmRequest) {
    return result === taskResultEnum.confirmed ? (
      <Title>
        Transfer completed of {amountAssetText}
        <SubTitle>from {sender}</SubTitle>
      </Title>
    ) : result === taskResultEnum.canceled ? (
      <Title>
        Cancelled transfer of {amountAssetText}
        <SubTitle>from {sender}</SubTitle>
      </Title>
    ) : (
      <Title>
        Confirm transfer of {amountAssetText}
        <SubTitle>from {sender}</SubTitle>
      </Title>
    );
  }

  if (
    (type === taskTypeEnum.outGoingConfirmRequest &&
      result === taskResultEnum.inProgress) ||
    result === taskResultEnum.pending
  ) {
    return (
      <Title>
        Transfer of {amountAssetText} pending confirmation
        <SubTitle>from {recipient}</SubTitle>
      </Title>
    );
  }

  if (type === taskTypeEnum.cancellation) {
    return result === taskResultEnum.inProgress ? (
      <Title>
        Cancelling transfer of {amountAssetText}
        <SubTitle>to {recipient}</SubTitle>
      </Title>
    ) : (
      <Title>
        Cancellation complete of {amountAssetText}
        <SubTitle>to {recipient}</SubTitle>
      </Title>
    );
  }

  if (type === taskTypeEnum.createRule) {
    return result === taskResultEnum.inProgress ? (
      <Title>
        Issuance permission created for
        <SubTitle>{influencedWallet}</SubTitle>
      </Title>
    ) : (
      <Title>
        Issuance permission created for
        <SubTitle>{influencedWallet}</SubTitle>
      </Title>
    );
  }

  if (type === taskTypeEnum.deleteRule) {
    return result === taskResultEnum.inProgress ? (
      <Title>
        Issuance permission deleted from
        <SubTitle>{influencedWallet}</SubTitle>
      </Title>
    ) : (
      <Title>
        Issuance permission deleted from
        <SubTitle>{influencedWallet}</SubTitle>
      </Title>
    );
  }

  return result === taskResultEnum.success ? (
    <Title>
      Transfer completed<SubTitle>to {recipient}</SubTitle>
    </Title>
  ) : (
    <Title>
      Transfer of {amountAssetText} in progress
      <SubTitle>to {recipient}</SubTitle>
    </Title>
  );
};

const Title = styled(SemiBoldTitle)`
  color: ${({ theme }) => theme.colors.midDarkGrey};
  max-width: 260px;
  min-height: 30px;
  margin-bottom: 5px;
`;

const SubTitle = styled.span`
  color: ${({ theme }) => theme.colors.midDarkGrey};
  font-family: Montserrat-Light;
  font-size: 13px;
  margin-left: 5px;
`;
