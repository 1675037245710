import React, { FunctionComponent } from 'react';
import styled from '@emotion/styled';
import { Text } from 'components/common/typography';

interface Props {
  text: string;
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
}

export const ToolTip: FunctionComponent<Props> = ({
  text,
  top,
  right,
  bottom,
  left
}) => {
  return (
    <Container top={top} right={right} bottom={bottom} left={left}>
      <TooltipText>
        <Text>{text}</Text>
      </TooltipText>
    </Container>
  );
};

export const ToolTipBaseStyle = styled.div`
  background-color: ${({ theme }) => theme.colors.midDarkGrey};
  padding: 10px;
  border-radius: 5px;
`;

const Container = styled(ToolTipBaseStyle)<{
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
}>`
  position: absolute;
  top: ${({ top }) => (top ? top : '')};
  right: ${({ right }) => (right ? right : '')};
  bottom: ${({ bottom }) => (bottom ? bottom : '')};
  left: ${({ left }) => (left ? left : '')};

  z-index: 3;
`;

export const TooltipText = styled.div`
  color: ${({ theme }) => theme.colors.white};
`;
