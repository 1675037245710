import { RefObject } from 'react';
import { sortBy } from 'lodash/fp';

export function getPathName(pathname: string) {
  const [path]: any = pathname.match(new RegExp(/^\/[\w\-]+/g));
  return path ? path : '';
}

export function getFromLocalStorage(key: string) {
  localStorage.getItem(key);
}

export function saveToLocalStorage(key: string, value: string) {
  localStorage.setItem(key, JSON.stringify(value));
}

export function removeFromLocalStorage(key: string) {
  const obj = localStorage.getItem(key);
  if (obj) {
    return JSON.parse(obj);
  }
  return {};
}

export function scrollToRef(ref: RefObject<HTMLDivElement>) {
  if (ref && ref.current) {
    window.scrollTo(0, ref.current.offsetTop);
  }
}

export function isPrefixOf(prefix: string, target: string): boolean {
  if (!prefix) {
    return false;
  }

  return target.toLowerCase().startsWith(prefix.toLowerCase());
}

export function sortByTextField(array: any) {
  return sortBy(item => item.text.toUpperCase(), array);
}
