import { useContext, useState } from 'react';
import { createStore } from 'reusable';
import { get } from 'lodash/fp';

import { AuthContext } from 'context/auth.context';

import { useApiRequest } from 'hooks/api.hooks';

import { Transactions, Transaction } from 'types/wallet.types';

//todo: fix initial value
const initialValue: Transaction[] = [];

export const TRANSACTIONS_LABEL = 'activity label';

type FetchTransactionsProps = {
  walletId: string;
  startIndex?: number;
  length?: number;
  onSuccess?: (data: Transactions) => void;
  label: string;
};

export const useTransactions = createStore(() => {
  const apiRequest = useApiRequest();
  const { user } = useContext(AuthContext);
  const [transactions, setTransactions] = useState<Transaction[]>(initialValue);
  const [error, setError] = useState(null);

  const address = get('node.address', user);
  const authToken = get('node.auth_token', user);

  const defaultOnSuccess = (data: Transactions) => {
    setTransactions(data.transactions);
  };

  const fetchTransactions = ({
    walletId,
    startIndex = 0,
    length = 100,
    onSuccess = defaultOnSuccess,
    label = TRANSACTIONS_LABEL
  }: FetchTransactionsProps) => {
    return apiRequest({
      path: 'wallet/get_activity',
      method: 'POST',
      baseUrl: address,
      headers: { 'x-auth-token': authToken },
      data: {
        wallet_id: walletId,
        start_index: startIndex,
        number_of_results: length
      },
      networkLabel: label,
      onSuccess,
      onError: console.error
    });
  };

  const clearTransactions = () => setTransactions(initialValue);

  return {
    transactions,
    setTransactions,
    clearTransactions,
    fetchTransactions,
    error,
    setError
  };
});

export const viewedTransactionTx = createStore(() => {
  const [transactionTx, setTransactionTx] = useState();

  return {
    transactionTx,
    setTransactionTx
  };
});
