import React, {
  ReactNode,
  RefObject,
  useEffect,
  useRef,
  useState
} from 'react';
import styled from '@emotion/styled';

import { Text } from 'components/common/typography';
import check from 'assets/images/green-check-icon.svg';

interface Props {
  type?: string;
  label?: string;
  value: string;
  onChange: (value: string, event?: any) => void;
  onFocus?: (e?: any) => void;

  id?: string;
  name?: string;
  autoFocus?: boolean;
  disabled?: boolean; // give some style if input disabled (ask sara?)
  placeholder?: string;
  error?: boolean;
  iconComponent?: ReactNode;
  showIcon?: boolean;
  iconHighlight?: string;
  iconClass?: string;
  helperText?: string;
  outsideRef?: RefObject<HTMLInputElement>;
  [key: string]: any; // for other props if required
  autoComplete?: 'new-password' | 'on' | 'off';
}

const KEY_E: number = 69;

export const TextField = (props: Props) => {
  const {
    id,
    name,
    type,
    label,
    value,
    onChange,
    disabled,
    placeholder,
    autoFocus,
    error,
    helperText,
    iconComponent,
    showIcon,
    outsideRef,
    onFocus,
    autoComplete,
    iconHighlight,
    iconClass,
    ...rest
  } = props;

  const inputRef2 = useRef<HTMLInputElement>(null);
  const inputRef = outsideRef ? outsideRef : inputRef2;

  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
  useEffect(() => {
    if (inputRef && inputRef.current && autoFocus) {
      inputRef.current.focus();
      setIsInputFocused(true);
    }

    if (inputRef && inputRef.current && type === 'number') {
      inputRef.current.addEventListener('keydown', (event: KeyboardEvent) => {
        const { keyCode } = event;
        if (keyCode === KEY_E) {
          event.preventDefault();
        }
      });
    }
  }, []);

  const handleFocusInput = (e: any) => {
    setIsInputFocused(true);
    onFocus && onFocus(e);
  };

  return (
    <Container className="container">
      {label && (
        <Label className="label" focused={isInputFocused} error={error}>
          {label}
        </Label>
      )}
      <InputContainer>
        <Input
          {...rest}
          id={id}
          name={name}
          ref={inputRef}
          type={type}
          value={value}
          disabled={disabled}
          onChange={e => onChange(e.target.value, e)}
          autoComplete={autoComplete}
          placeholder={placeholder}
          onFocus={handleFocusInput}
          onBlur={() => setIsInputFocused(false)}
          error={error}
          showIcon={showIcon}
          className="input"
        />
        {showIcon && (
          <IconContainer
            className="icon"
            iconHighlight={iconHighlight}
            iconClass={iconClass}
            focused={isInputFocused}>
            {iconComponent}
          </IconContainer>
        )}
      </InputContainer>
      {helperText && (
        // TODO: handle margin and padding of helper text inside component
        <HelperText error={error} className="helper-text">
          {helperText}
        </HelperText>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled(Text)<{
  focused: boolean;
  error?: boolean;
}>`
  line-height: 1.22;
  color: ${({ theme }) => theme.colors.midDarkGrey};
  ${({ theme, focused }) => focused && `color: ${theme.colors.lightBlue}`};
  ${({ theme, error }) => error && `color: ${theme.colors.errorRed}`};
`;

const HelperText = styled(Text)<{
  error?: boolean;
}>`
  color: ${({ theme }) => theme.colors.midDarkGrey};
  ${({ theme, error }) => error && `color: ${theme.colors.errorRed}`};
  margin-bottom: ${({ error }) => (error ? '24px' : '40px')};
`;

const Input = styled.input<{
  error?: boolean;
  showIcon?: boolean;
  isFocused?: boolean;
}>`
  font-family: Montserrat-Regular;
  width: 100%;
  padding-top: 7px;
  padding-right: ${({ showIcon }) => (showIcon ? '25px' : 0)};
  padding-bottom: ${({ error }) => (error ? '1px' : '2px')};
  outline: none;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.midDarkGrey};
  border: none;
  border-bottom-width: ${({ error }) => (error ? '2px' : '1px')};
  border-bottom-style: solid;
  border-bottom-color: ${({ theme, error }) =>
    error ? theme.colors.errorRed : theme.colors.midDarkGrey};
  font-size: 15px;
  margin-bottom: ${({ error }) => (error ? '4px' : '40px')};

  &:focus {
    padding-bottom: 1px;
    border-bottom-width: 2px;
    border-bottom-color: ${({ theme, error }) =>
      error ? theme.colors.errorRed : theme.colors.lightBlue};
  }

  // hide left side arrows if input of type number
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const InputContainer = styled.div`
  position: relative;
`;

const IconContainer = styled.div<{
  iconHighlight?: string;
  iconClass?: string;
  focused: boolean;
}>`
  position: absolute;
  top: 4px;
  right: 0;
  width: 25px;
  height: 25px;

  ${({ iconHighlight, iconClass, focused }) =>
    iconHighlight &&
    focused &&
    `
    ${
      iconClass
        ? `svg {
            .${iconClass} {
              fill: ${iconHighlight}
             }
          }`
        : `svg {
            fill: ${iconHighlight};
           }`
    }
    
  `};
`;

export const TextFieldContainer = styled.div`
  .input {
    font-size: 20px;
  }
`;

export const ValidIcon = styled.div`
  height: 100%;
  background: url(${check}) no-repeat center;
  background-size: contain;
`;
