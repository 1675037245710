import { useState, useEffect } from 'react';
import { createStore } from 'reusable';
import moment from 'moment';

export const useLocalization = createStore(() => {
  const [locale, setLocale] = useState('en-US');

  useEffect(() => {
    moment.locale(locale);
  }, [locale]);

  return [locale, setLocale];
});
