import { useContext } from 'react';
import { createStore } from 'reusable';
import { get } from 'lodash/fp';

import { AuthContext } from 'context/auth.context';

import { useApiRequest } from 'hooks/api.hooks';

import { Results as TasksResults, Types } from 'types/tasks.types';

const GET_TASKS_STATUS_LABEL = 'GET-TASKS label';

export const useGetTasks = createStore(() => {
  const { user } = useContext(AuthContext);

  const apiRequest = useApiRequest();

  const urlAddress = get('node.address', user);
  const authToken = get('node.auth_token', user);

  const fetchTasks = (
    results: TasksResults,
    types: Types = ['transfer_asset', 'issue_asset']
  ) => {
    return apiRequest({
      path: 'node/get_tasks',
      method: 'POST',
      data: {
        start_index: 0,
        number_of_results: 100,
        types,
        results,
        order: 'desc'
      },
      baseUrl: urlAddress,
      headers: { 'x-auth-token': authToken },
      networkLabel: GET_TASKS_STATUS_LABEL,
      onError: console.error
    });
  };

  return {
    fetchTasks
  };
});
