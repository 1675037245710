import React, { useContext, useEffect, useState, useMemo } from 'react';
import styled from '@emotion/styled';

import map from 'lodash/fp/map';
import get from 'lodash/fp/get';
import flow from 'lodash/fp/flow';
import size from 'lodash/fp/size';
import keyBy from 'lodash/fp/keyBy';
import sortBy from 'lodash/fp/sortBy';
import filter from 'lodash/fp/filter';
import values from 'lodash/fp/values';
import flattenDeep from 'lodash/fp/flattenDeep';

import moment from 'moment';

import { AuthContext } from 'context/auth.context';

import { useBalances } from 'stores/wallet/balances.store';
import { useGetTasks } from 'stores/wallet/get-tasks.store';

import Card from 'components/common/card';
import { TotalAssets } from 'components/wallet/assets/total-assets';
import { H2, Title } from 'components/common/typography';
import StatsCard, { StatItem } from 'components/common/stats-card';
import ProcessingTimeChart from './processing-time/processing-time-chart';
import TransactionTimes from './transaction-times/transaction-times';
import DashboardAssetBalance from 'components/dashboard/dashboard-asset-balance';
import { ScrollableContainer } from 'components/common/page-containers/scrollable-container';
import { CardTitle } from 'components/common/page-containers/title-container';

import { useTransactions } from 'stores/wallet/transactions.store';

import { mapWithKeys } from 'utils/lodash.utils';
import { filterTransactionsByType } from 'utils/chart.utils';
import { getNumberOfTransactionsFormatted } from 'utils/wallet.utils';

import { Balances, Asset, Transaction, Transactions } from 'types/wallet.types';

type TransactionsByHash = {
  [key: string]: Transaction;
};

type AssetsSumById = {
  [key: string]: { asset_id: string; amount: number };
};

const getUniqAssetsArray = (sumAllAssetsArray: Asset[]): Asset[] => {
  const sumUniqAssetsByAssetsId: { [key: string]: number } = {} as any;
  sumAllAssetsArray.forEach((asset: Asset) => {
    const { asset_id, amount } = asset;
    if (sumUniqAssetsByAssetsId[asset_id]) {
      sumUniqAssetsByAssetsId[asset_id] =
        sumUniqAssetsByAssetsId[asset_id] + amount;
    } else {
      sumUniqAssetsByAssetsId[asset_id] = amount;
    }
  });

  return mapWithKeys(
    (value: number, key: string) => ({ asset_id: key, amount: value }),
    sumUniqAssetsByAssetsId
  );
};

const Dashboard = () => {
  const userContext = useContext(AuthContext);

  const { fetchBalances } = useBalances();
  const { fetchTasks } = useGetTasks();
  const { fetchTransactions } = useTransactions();

  const [totalAssetsLoading, setTotalAssetsLoading] = useState<boolean>(false);
  const [transactionsLoading, setTransactionsLoading] = useState<boolean>(
    false
  );
  const [pendingTasks, setPendingTasks] = useState([]);
  const [allAssets, setAllAssets] = useState<Asset[]>([]);
  const [allWalletsTransactions, setAllWalletsTransactions] = useState<
    TransactionsByHash
  >({});

  const bankName = get('user.name', userContext);

  const allWalletIds = useMemo(
    () =>
      flow(
        get('user.wallets'),
        map(get('id'))
      )(userContext),
    [userContext]
  );

  const updateAssetsBalances = () => {
    Promise.all(
      allWalletIds.map(
        walletId =>
          new Promise(resolve => {
            return fetchBalances({
              walletId,
              onSuccess: (currentWalletBalances: Balances) => {
                resolve(currentWalletBalances);
              }
            });
          })
      )
    ).then(walletBalanceResponses => {
      const flatten: Asset[] = flattenDeep(
        walletBalanceResponses.map((item: any) => item.assets)
      );
      const sumAllAssetsById: AssetsSumById = {};

      flatten.forEach((item: Asset) => {
        const { amount, asset_id } = item;

        if (sumAllAssetsById[asset_id]) {
          const sum = sumAllAssetsById[asset_id].amount + amount;
          sumAllAssetsById[asset_id] = { asset_id, amount: sum };
        } else {
          sumAllAssetsById[asset_id] = { asset_id, amount };
        }
      });

      const sumAllAssetsArray = values(sumAllAssetsById);
      const sumUniqAssetsArray = getUniqAssetsArray(sumAllAssetsArray);

      setAllAssets(sumUniqAssetsArray);
      setTotalAssetsLoading(false);
    });
  };

  const updateTransactions = () => {
    allWalletIds.forEach(walletId => {
      fetchTransactions({
        startIndex: 0,
        length: 1000,
        walletId,
        onSuccess: (data: Transactions) => {
          const { transactions: currentWalletTransactions } = data;
          //todo: sort by date after merging
          const currentWalletTransactionsByHash = keyBy(
            'metadata.tx_hash',
            currentWalletTransactions
          );
          setAllWalletsTransactions(
            (currentAllWalletsTransactions: TransactionsByHash) => {
              const mergedAllWalletsTransactions = {
                ...currentWalletTransactionsByHash,
                ...currentAllWalletsTransactions
              };
              return mergedAllWalletsTransactions;
            }
          );
          setTransactionsLoading(false);
        }
      });
    });
  };

  const updatePendingTransactions = () => {
    fetchTasks(['in_progress', 'pending']).then(({ tasks }: any) => {
      setPendingTasks(tasks);
    });
  };

  useEffect(() => {
    setTotalAssetsLoading(true);
    setTransactionsLoading(true);
    updateAssetsBalances();
    updateTransactions();
    updatePendingTransactions();
    const interval = setInterval(() => {
      updateAssetsBalances();
      updateTransactions();
      updatePendingTransactions();
    }, 15000);

    return () => clearInterval(interval);
  }, [allWalletIds]);

  const allWalletsTransactionsArray = useMemo(
    () =>
      flow(
        values,
        sortBy((transaction: Transaction) => {
          return (
            new Date(get('metadata.timestamp', transaction)).getTime() * -1
          );
        })
      )(allWalletsTransactions),
    [allWalletsTransactions]
  );

  const nodeStats = useMemo(() => {
    const fifteenMinutesAgo = moment().subtract(15, 'minutes');
    const fifteenSecondsAgo = moment().subtract(15, 'seconds');
    const now = moment();
    const last15MinTransactions = filter(
      (transaction: Transaction) =>
        moment(transaction.metadata.timestamp).isBetween(
          fifteenMinutesAgo,
          now
        ),
      allWalletsTransactionsArray
    );

    //TODO: fix task typing when merged with processing-time PR
    const last15MinutesPendingTransactions = filter(
      (task: any) => moment(task.created_at).isBetween(fifteenSecondsAgo, now),
      pendingTasks
    );

    const numPendingTransactions = size(last15MinutesPendingTransactions);
    const numTransactions = flow(
      filter(filterTransactionsByType('transfers')),
      size
    )(last15MinTransactions);
    const numIssuances = flow(
      filter(filterTransactionsByType('issuances')),
      size
    )(last15MinTransactions);
    const transactionsPerMinute = getNumberOfTransactionsFormatted(
      numTransactions / 15
    );
    return [
      { label: 'TRANSACTIONS PER MINUTE', value: transactionsPerMinute },
      { label: 'TOTAL TRANSFERS', value: numTransactions },
      { label: 'TOTAL ISSUANCES', value: numIssuances },
      { label: 'PENDING TRANSACTIONS', value: numPendingTransactions }
    ] as StatItem[];
  }, [allWalletsTransactionsArray, pendingTasks]);

  return (
    <ScrollableContainer>
      <Container>
        <Header>
          <TitleContainer>
            <H2>{bankName}</H2>
          </TitleContainer>
          <CardTitle>
            <Title>What has happened in the last 15 minutes?</Title>
          </CardTitle>
        </Header>
        <StatsContainer>
          <StatsCard data={nodeStats} />
        </StatsContainer>
        <Content>
          <LeftSide>
            <TopSide>
              <CardTitle>
                <Title>How many transactions occurred over time?</Title>
              </CardTitle>
              <CardContainer height={'330px'}>
                <Card>
                  <DashboardAssetBalance
                    transactions={allWalletsTransactionsArray}
                  />
                </Card>
              </CardContainer>
            </TopSide>
            <BottomSide>
              <CardTitle>
                <Title>How long are your transactions taking?</Title>
              </CardTitle>
              <CardContainer height={'620px'}>
                <Card>
                  <ProcessingTimeChart />
                </Card>
              </CardContainer>
            </BottomSide>
          </LeftSide>
          <RightSide>
            <TopSide>
              <CardTitle>
                <Title>What are your current asset balances?</Title>
              </CardTitle>
              <CardContainer height={'330px'}>
                <Card>
                  <TotalAssets
                    assets={allAssets}
                    isLoading={totalAssetsLoading}
                  />
                </Card>
              </CardContainer>
            </TopSide>
            <BottomSide>
              <CardTitle>
                <Title>When do most transactions occur?</Title>
              </CardTitle>
              <CardContainer height={'620px'}>
                <Card>
                  <TransactionTimes
                    transactions={allWalletsTransactionsArray}
                    isLoading={transactionsLoading}
                  />
                </Card>
              </CardContainer>
            </BottomSide>
          </RightSide>
        </Content>
      </Container>
    </ScrollableContainer>
  );
};

const Container = styled.div`
  padding: 23px 38px;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
`;

const LeftSide = styled.div`
  height: 100%;
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  padding: 0 ${({ theme }) => theme.cardSpacing.margin} 20px;
  margin-left: -${({ theme }) => theme.cardSpacing.margin};
  overflow-x: hidden;
`;

const RightSide = styled.div`
  height: fit-content;
  flex: 0 1 288px;
  display: flex;
  flex-direction: column;
`;

const TopSide = styled.div`
  margin-bottom: ${({ theme }) => theme.cardSpacing.margin};
  display: flex;
  flex-direction: column;
`;

const BottomSide = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.midDarkGrey};
`;

const TitleContainer = styled.div`
  text-transform: uppercase;
  margin-bottom: 15px;
`;

const StatsContainer = styled.div`
  margin-bottom: 20px;
`;

const CardContainer = styled.div<{ height?: string }>`
  height: ${({ height }) => (height ? height : '100%')};
`;

export default Dashboard;
