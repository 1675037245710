import styled from '@emotion/styled';

const Container = styled.div`
  position: relative;
  &:not(:last-child) {
    margin-right: 30px;
  }
`;
export const BlockHeightContainer = styled(Container)`
  flex: 1.5;
`;
export const BlockTypeContainer = styled(Container)`
  flex: 2;
`;
export const BlockHashContainer = styled(Container)`
  flex: 2;
`;
export const TransactionHashContainer = styled(Container)`
  flex: 2;
`;
export const TransactionTimeContainer = styled(Container)`
  flex: 2;
`;

export const ViewTransactionProofContainer = styled(Container)`
  flex: 2;

  @media (max-width: 1700px) {
    flex: 3;
  }
  @media (max-width: 1440px) {
    flex: 4;
  }
`;
