import { useEffect, useState } from 'react';
import { get } from 'lodash/fp';

import * as fb from 'firebase';
import { db, firebase } from 'config/firebase.config';

import { AuthStatus, DocumentData, Network } from 'types/firebase.types';

export const signIn = (email: string, password: string) => {
  return firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then((res: any) => res);
};

export const logOut = () => {
  firebase.auth().signOut();
};

// URL is not really used (required by TS)
export const sendPasswordResetEmailRequest = (
  email: string,
  continueUrl: string
) => {
  return firebase.auth().sendPasswordResetEmail(email, {
    url: continueUrl
  });
};

export const handleFirebasePasswordReset = (
  actionCode: string,
  newPassword: string
) => {
  return firebase
    .auth()
    .verifyPasswordResetCode(actionCode)
    .then(email => {
      return firebase
        .auth()
        .confirmPasswordReset(actionCode, newPassword)
        .then(() => email);
    });
};

export const reauthenticateUser = (currentPassword: string): Promise<any> => {
  const user = get('currentUser', firebase.auth());
  const email = get('currentUser.email', firebase.auth()) as string;

  if (user && email) {
    const credentials = fb.auth.EmailAuthProvider.credential(
      email,
      currentPassword
    );

    return user.reauthenticateWithCredential(credentials);
  }

  return new Promise((resolve, reject) => reject);
};

export const handleFirebaseUpdatePassword = (
  newPassword: string
): Promise<any> => {
  const currentUser = get('currentUser', firebase.auth());
  if (currentUser) {
    return currentUser.updatePassword(newPassword);
  }

  return new Promise((resolve, reject) => reject);
};

export function useAuthenticate() {
  const [isAuthenticated, setIsAuthenticated] = useState<AuthStatus>(
    'uninitialized'
  );

  useEffect(() => {
    firebase.auth().onAuthStateChanged(
      user => {
        if (!user) {
          setIsAuthenticated('unauthorized');
          return;
        }
        setIsAuthenticated('authorized');
      },
      () => setIsAuthenticated('unauthorized')
    );
  }, []);

  return {
    isAuthenticated
  };
}

export function useUserData() {
  const userId = get('currentUser.uid', firebase.auth());
  const userEmail = get('currentUser.email', firebase.auth());

  const { isAuthenticated } = useAuthenticate();
  const [user, setUser] = useState();

  useEffect(() => {
    if (!userId || isAuthenticated !== 'authorized') {
      return;
    }

    db.collection('node_data')
      .doc(userId)
      .onSnapshot(
        doc => {
          const userData: DocumentData | undefined = doc.data();

          if (!userData) {
            return;
          }

          userData.network.onSnapshot((network: any) => {
            const networkData: Network = network.data();
            const user = { ...userData, network: networkData };

            setUser(user);
          });
        },
        () => setUser(null)
      );
  }, [userId, isAuthenticated]);

  return { user, userId, userEmail };
}
