import { createStore } from 'reusable';
import { find } from 'lodash/fp';

import { useNetworkActivity } from 'stores/wallet/network-activity.store';
import { viewedTransactionTx } from 'stores/wallet/transactions.store';

export const transactionProofSelector = createStore(() => {
  const {
    networkActivity: { transactions }
  } = useNetworkActivity();
  const { transactionTx } = viewedTransactionTx();

  return find(
    transaction => transaction.metadata.tx_hash === transactionTx,
    transactions
  );
});
