import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';
import { get } from 'lodash/fp';

import { AuthContext } from 'context/auth.context';

import { useIsApiKeyModalIsOpen } from 'stores/ui/api-key-ui.store';

import { CopyToClipBoard } from 'components/common/copy-to-clip-board';
import { BoldTitle, Text } from './typography';
import Modal from './modal/modal';

export const ApiKeyModal = () => {
  const { user } = useContext(AuthContext);
  const [, setIsApiKeyModalOpen] = useIsApiKeyModalIsOpen();

  const apiKey = get('node.auth_token', user);
  const serverAddress = get('node.address', user);

  return (
    <Modal onClose={() => setIsApiKeyModalOpen(false)}>
      <Container>
        <Title>
          <BoldTitle>API Key</BoldTitle>
        </Title>
        <CopyToClipBoardContainer>
          <CopyToClipBoardLabel>API key</CopyToClipBoardLabel>
          <CopyToClipBoard
            toolTip={{ top: '-35px', left: '0' }}
            value={apiKey}
            highlightOnCopy={true}
          />
        </CopyToClipBoardContainer>
        <CopyToClipBoardContainer>
          <CopyToClipBoardLabel>Server Address</CopyToClipBoardLabel>
          <CopyToClipBoard
            toolTip={{ top: '-35px', left: '0' }}
            value={serverAddress}
            highlightOnCopy={true}
          />
        </CopyToClipBoardContainer>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  min-width: 400px;
`;

const Title = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.midDarkGrey};
  margin-bottom: 40px;
`;

const CopyToClipBoardContainer = styled.div`
  height: 50px;
  width: 360px;
  color: ${({ theme }) => theme.colors.midDarkGrey};
  background-color: ${({ theme }) => theme.colors.midLightGrey};
  border-radius: 15px;
  padding: 3px 12px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.11);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto 15px;
  padding: 3px 12px;

  * {
    transition: ease-in 0.2s;
  }

  #copy-to-clip-board {
    height: 20px;
  }
`;

const CopyToClipBoardLabel = styled(Text)`
  color: ${({ theme }) => theme.colors.midDarkGrey};
`;
