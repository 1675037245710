import React from 'react';
import styled from '@emotion/styled';

import { TooltipContainer } from 'components/common/chart-tooltip-styles';
import { Circle } from 'components/common/circle';

import { Text, BoldText } from 'components/common/typography';

import { getFormattedAmount } from 'utils/wallet.utils';
import { Flex } from '@rebass/grid/emotion';

type Props = {
  id: string;
  value: number;
  color: string;
};
const PieChartTooltip = (props: Props) => {
  const { id: assetName, value: amount, color } = props;
  const formattedAmount = getFormattedAmount(amount);
  return (
    <TooltipContainer>
      <Flex flexDirection="column" alignItems="center">
        <BoldText>{formattedAmount}</BoldText>
        <TooltipNameAndColorContainer>
          <CircleContainer>
            <Circle color={color} size={10} />
          </CircleContainer>
          <Text>{assetName}</Text>
        </TooltipNameAndColorContainer>
      </Flex>
    </TooltipContainer>
  );
};

const CircleContainer = styled.div`
  margin-right: 8px;
`;

const TooltipNameAndColorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 3px;
  white-space: nowrap;
`;

export default PieChartTooltip;
