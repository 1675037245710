import React from 'react';
import styled from '@emotion/styled';
import { get, keys } from 'lodash/fp';
import { Text, LargeText } from 'components/common/typography';

type KeyValuePairs = {
  [key: string]: string | number;
};

type Props = {
  keyValuePairs: KeyValuePairs;
};

const ChartKeyValueTable = (props: Props) => {
  const { keyValuePairs } = props;

  return (
    <Container>
      {keys(keyValuePairs).map((key: string) => {
        return (
          <ListItem key={key}>
            <ItemContent>
              <ItemValue>
                <LargeText>{get(key, keyValuePairs)}</LargeText>
              </ItemValue>
              <Text>{key}</Text>
            </ItemContent>
          </ListItem>
        );
      }, keyValuePairs)}
    </Container>
  );
};

export default ChartKeyValueTable;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.midDarkGrey};
`;

const ItemContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ListItem = styled.div`
  display: flex;
  flex-direction: row;
`;

const ItemValue = styled.div`
  margin-right: 10px;
`;
