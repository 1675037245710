import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { map } from 'lodash/fp';
import { HugeText, MediumText } from 'components/common/typography';

export type StatItem = {
  label: string;
  value: string | number;
};

type Props = {
  data: StatItem[];
};
const StatsCard = (props: Props) => {
  return (
    <Container>
      {map(
        (statItem: StatItem) => (
          <ListItem key={statItem.label}>
            <Value>
              <HugeText>{statItem.value}</HugeText>
            </Value>
            <Label>
              <MediumText>{statItem.label}</MediumText>
            </Label>
          </ListItem>
        ),
        props.data
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-evenly;
  background-color: ${({ theme }) => theme.colors.darkBlue};
  padding: 20px 35px;
  border-radius: 8px;
`;

//todo: add theme color
const ListItem = styled.div`
  display: flex;
  align-items: center;
  border-radius: 24px;
  background-color: #233c6e;
  padding: 10px 30px;
  & + & {
    margin-left: 45px;
  }
`;

const Value = styled.div`
  color: #38dfb7;
  margin-right: 10px;
`;

const Label = styled.div`
  color: ${({ theme }) => theme.colors.white};
`;
export default StatsCard;
