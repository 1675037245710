import React from 'react';
import ReactSwitch from 'react-switch';
import { theme } from 'constants/themes.constants';

interface Props {
  height: number;
  width: number;
  isChecked: boolean;
  disabled: boolean;
  onChange: (
    checked: boolean,
    event: React.SyntheticEvent<MouseEvent | KeyboardEvent> | MouseEvent,
    id: string
  ) => void;
  color: string;
  boxShadow: string;
  onHandleColor: string;
  handleDiameter: number;
  uncheckedIcon: JSX.Element | boolean;
  checkedIcon?: JSX.Element | boolean;
  className?: string;
}

export const Switch = ({
  height,
  width,
  isChecked,
  uncheckedIcon,
  checkedIcon,
  disabled,
  onChange,
  color,
  onHandleColor,
  handleDiameter,
  boxShadow,
  className
}: Props) => {
  return (
    <ReactSwitch
      height={height}
      width={width}
      checked={isChecked}
      uncheckedIcon={uncheckedIcon}
      checkedIcon={checkedIcon}
      disabled={disabled}
      onChange={onChange}
      onColor={color}
      onHandleColor={onHandleColor}
      handleDiameter={handleDiameter}
      boxShadow={boxShadow}
      className={className}
    />
  );
};

Switch.defaultProps = {
  color: theme.colors.veryLightTurquoise,
  onHandleColor: theme.colors.lightBlue,
  boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.6)',
  handleDiameter: 20,
  height: 15,
  width: 35,
  uncheckedIcon: false,
  checkedIcon: false
};
