import React from 'react';
import styled from '@emotion/styled';

import { Text } from 'components/common/typography';

import { Mode } from 'types/wallet.types';

interface Props {
  mode: Mode;
}

export const TableCategories = ({ mode }: Props) => {
  return (
    <Container mode={mode}>
      <Text>Type</Text>
      <Text>Address/Date</Text>
      <Text>Asset ID</Text>
      <Text>Amount</Text>
      {mode === 'transfers' && <Text>Confirmed</Text>}
    </Container>
  );
};

const Container = styled.div<{ mode: Mode }>`
  color: ${({ theme }) => theme.colors.midDarkGrey};
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0 15px;
  display: grid;
  grid-template-columns: ${({ mode }) =>
    mode === 'transfers' ? '7% 18% 15% 15% 15% 30%' : '7% 17% 23% 23% 31%'};
`;
