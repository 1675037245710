import React, { ReactNode, useEffect } from 'react';
import styled from '@emotion/styled';
import ReactInfiniteScroller from 'react-infinite-scroller';
import loaderIcon from 'assets/images/spinner.svg';

type Props = {
  hasMore: boolean;
  loadMore: () => void;
  children: ReactNode;
  isInitialLoading: boolean;
  isLoadingMore: boolean;
  useWindow: boolean;
};

const renderInitialLoading = () => {
  return (
    <InitialLoadingContainer>
      <LoadingSpinner />
    </InitialLoadingContainer>
  );
};

const renderLoadingMore = () => {
  return (
    <LoadingMoreContainer key="loadingMoreKey">
      <LoadingSpinner />
    </LoadingMoreContainer>
  );
};

const InfiniteScroll = (props: Props) => {
  const {
    hasMore,
    loadMore,
    children,
    isInitialLoading,
    isLoadingMore,
    useWindow
  } = props;

  return isInitialLoading ? (
    renderInitialLoading()
  ) : (
    <ReactInfiniteScroller
      useWindow={useWindow}
      loadMore={loadMore}
      hasMore={hasMore}>
      {children}
      {isLoadingMore && renderLoadingMore()}
    </ReactInfiniteScroller>
  );
};

InfiniteScroll.defaultProps = {
  useWindow: false
};

const InitialLoadingContainer = styled.div`
  height: ${({ theme }) => `${theme.cardContentSpace.height}px`};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingMoreContainer = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.div`
  background: url(${loaderIcon}) no-repeat center;
  background-size: contain;
  width: 80px;
  height: 80px;
`;

export default InfiniteScroll;
