import { ProcessingChartColors } from 'types/chart.types';

export const CHART_COLORS: ProcessingChartColors = {
  max: '#ffca1a',
  average: '#38b1df',
  min: '#38dfb7'
};

export const CHART_TOOLTIP_LABEL = {
  max: 'Max',
  average: 'Avg',
  min: 'Min'
};
