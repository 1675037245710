import { useState } from 'react';
import { createStore } from 'reusable';

export const useCurrentWalletPublicKey = createStore(() => useState(null));

export const useCurrentWalletName = createStore(() => useState(null));

export const useIsNodeAdmin = createStore(() => useState());

export const useNodeAdminWallet = createStore(() => useState({}));
