import React, { useContext, useEffect } from 'react';
import styled from '@emotion/styled';
import isEmpty from 'lodash/fp/isEmpty';
import get from 'lodash/fp/get';

import { AuthContext } from 'context/auth.context';

import { useAddressBook } from 'stores/user/address-book';
import { useRules, Rule } from 'stores/wallet/rules.store';
import {
  useIsNodeAdmin,
  useNodeAdminWallet
} from 'stores/governance/governance.store';
import { usePublicKeysOfWallets } from 'stores/wallet/public-keys-of-wallets.store';

import { FixedContainer } from 'components/common/page-containers/fixed-container';
import { Card } from 'components/common/card';
import { TableHeader } from 'components/network-governance/table-header';
import { TableRow } from 'components/network-governance/table-row';
import { H2 } from 'components/common/typography';

import { mapWithKeys } from 'utils/lodash.utils';
import {
  getIsIssuer,
  getIsAdmin,
  getIsNodeAdmin,
  getNodeAuth
} from 'utils/network-governance.utils';

import { Address, Wallet } from 'types/firebase.types';
import { PublicKey } from 'types/wallet.types';

export const NetworkGovernance = () => {
  const [isNodeAdmin, setIsNodeAdmin] = useIsNodeAdmin();
  const [, setNodeAdminWallet] = useNodeAdminWallet();
  const { addressBook } = useAddressBook();
  const { fetchRules, rules } = useRules();
  const { publicKeys, fetchPublicKeys } = usePublicKeysOfWallets();

  const wallets: Wallet[] = get('user.wallets', useContext(AuthContext));

  const setNodeAccessAbilities = (
    rules: Rule[],
    publicKeys: PublicKey[],
    setNodeAdminWallet: (value: object) => void
  ) => {
    const nodeAdmin = getIsNodeAdmin(publicKeys, rules);
    const nodeAuth = !!nodeAdmin
      ? getNodeAuth(nodeAdmin.wallet_id, wallets)
      : undefined;

    if (nodeAuth) {
      setNodeAdminWallet({ ...nodeAdmin, auth: nodeAuth });
    }

    setIsNodeAdmin(!!nodeAdmin);
  };

  useEffect(() => {
    const nodeWallets: string[] = wallets.map(wallet => wallet.id);

    fetchRules();
    fetchPublicKeys(nodeWallets);
  }, []);

  useEffect(() => {
    if (!isEmpty(rules) && !isEmpty(publicKeys)) {
      setNodeAccessAbilities(rules, publicKeys, setNodeAdminWallet);
    }
  }, [rules, wallets, setIsNodeAdmin, publicKeys]);

  return (
    <FixedContainer>
      <Container className="network-governance-container">
        <Header>
          <H2>NETWORK GOVERNANCE</H2>
        </Header>
        <TableContainer>
          <Card padding={{ right: 20, left: 20 }}>
            <TableHeader />
            <RowsContainer>
              {mapWithKeys(
                (value: Address, key: string) => (
                  <TableRow
                    key={key}
                    name={key}
                    publicKey={value.public_key}
                    walletAddress={value.recipient_address}
                    isNodeAdmin={isNodeAdmin}
                    isAdminWallet={getIsAdmin(value.public_key, rules)}
                    isIssuerWallet={getIsIssuer(value.public_key, rules)}
                  />
                ),
                addressBook
              )}
            </RowsContainer>
          </Card>
        </TableContainer>
      </Container>
    </FixedContainer>
  );
};

const Container = styled.div`
  padding: 23px 38px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  color: ${({ theme }) => theme.colors.midDarkGrey};
  margin-bottom: 20px;
`;

const TableContainer = styled.div`
  width: 100%;
  height: 100%;
  flex: 1 0 0;
`;

const RowsContainer = styled.div`
  margin-top: 20px;
  padding: 0 10px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 95%;
`;
