export const ROUTES = {
  DASHBOARD: '/dashboard',
  WALLET: '/wallet',
  BLOCK_EXPLORER: '/network',
  ABOUT: '/help'
};

export const ROUTES_MAP = {
  [ROUTES.DASHBOARD]: 'Dashboard',
  [ROUTES.WALLET]: 'Wallets',
  [ROUTES.BLOCK_EXPLORER]: 'Network',
  [ROUTES.ABOUT]: 'Help'
};
