import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import size from 'lodash/fp/size';
import filter from 'lodash/fp/filter';
import isEmpty from 'lodash/fp/isEmpty';

import downArrow from 'assets/images/down-arrow.svg';

import { useUserTransactions } from 'stores/user/node-transactions.store';

import { NotificationsList } from 'components/notifications-handler/notifications-list';
import { Title } from 'components/common/typography';

import { ModifiedTask } from 'types/tasks.types';

export const NotificationsHandler = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [
    pendingConfirmationTransactions,
    setPendingConfirmationTransactions
  ] = useState<ModifiedTask[]>([]);
  const [pendingTransactions, setPendingTransactions] = useState<
    ModifiedTask[]
  >([]);

  const { transactions } = useUserTransactions();
  const { setTransactions, transactionsRef } = useUserTransactions();

  const isAllTransactionsCompleted =
    isEmpty(pendingTransactions) &&
    isEmpty(pendingConfirmationTransactions) &&
    !isEmpty(transactions);

  const transactionsSize = size(transactions);
  const pendingTransactionsSize = size(pendingTransactions);
  const pendingConfirmationTransactionsSize = size(
    pendingConfirmationTransactions
  );

  const handleToggle = () => {
    if (!size(transactions)) {
      return;
    }
    setIsOpen(!isOpen);
  };

  const handleClearAll = () => {
    transactionsRef.current = {};
    setTransactions({});
  };

  useEffect(() => {
    const pendingConfirmationTransactions = filter(
      transaction => transaction.result === 'awaiting_user_interaction',
      transactions
    );

    if (!isEmpty(pendingConfirmationTransactions)) {
      setPendingConfirmationTransactions(pendingConfirmationTransactions);
      return;
    }

    const pendingTransactions = filter(transaction => {
      if (
        transaction.result === 'in_progress' ||
        transaction.result === 'pending'
      ) {
        return transaction;
      }
    }, transactions);
    setPendingTransactions(pendingTransactions);
    setPendingConfirmationTransactions([]);
  }, [transactions, setPendingConfirmationTransactions]);

  useEffect(() => {
    if (!size(transactions)) {
      setIsOpen(false);
    }
  }, [size(transactions)]);

  return (
    <Container isDisplayed={!!size(transactions)}>
      <Header>
        <HeaderTitle>
          {isAllTransactionsCompleted ? (
            <HeaderTitle>
              {transactionsSize}
              {transactionsSize === 1
                ? ' TRANSACTION COMPLETE'
                : ' TRANSACTIONS COMPLETE'}
            </HeaderTitle>
          ) : !isEmpty(pendingConfirmationTransactions) ? (
            <HeaderTitle>
              {pendingConfirmationTransactionsSize}
              {pendingConfirmationTransactionsSize === 1
                ? ' TRANSACTION PENDING'
                : ' TRANSACTIONS PENDING'}
            </HeaderTitle>
          ) : (
            <HeaderTitle>
              {pendingTransactionsSize}
              {pendingTransactionsSize === 1
                ? ' TRANSACTION IN PROGRESS'
                : ' TRANSACTIONS IN PROGRESS'}
            </HeaderTitle>
          )}
        </HeaderTitle>
        <DropDownToggleIcon isOpen={isOpen} onClick={handleToggle} />
        {isAllTransactionsCompleted && (
          <ClearAllNotifications onClick={handleClearAll}>
            X
          </ClearAllNotifications>
        )}
      </Header>
      <NotificationsList isOpen={isOpen} />
    </Container>
  );
};

const Container = styled.div<{ isDisplayed: boolean }>`
  width: 500px;
  position: fixed;
  bottom: 25px;
  right: 0;
  box-shadow: 0px 3px 6px #00000029;
  transition: ease-in-out 0.3s;
  z-index: 1;

  ${({ isDisplayed }) =>
    !isDisplayed &&
    `
    opacity: 0;
    pointer-events: none;
  `}
`;

const Header = styled.div`
  background-color: ${({ theme }) => theme.colors.darkBlue};
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

const HeaderTitle = styled(Title)`
  color: ${({ theme }) => theme.colors.white};
  font-weight: 500;
`;

const DropDownToggleIcon = styled.div<{ isOpen: boolean }>`
  background: url(${downArrow}) no-repeat center;
  background-size: contain;
  width: 15px;
  height: 10px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  margin-left: auto;

  ${({ isOpen }) =>
    !isOpen &&
    `
      transform: rotate(-180deg);
    `};
`;

const ClearAllNotifications = styled(Title)`
  color: ${({ theme }) => theme.colors.white};
  margin-left: 25px;
  cursor: pointer;
`;
