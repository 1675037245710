import React from 'react';
import styled from '@emotion/styled';
import css from '@emotion/css';

type Variant = 'primary' | 'secondary' | 'tertiary' | 'quaternary';

const primary = (props: any) => css`
  ${props.variant === 'primary'
    ? `
      ${props.theme.typography.loginButton.styles};
      color: ${props.theme.colors.white};
      background-color: ${props.theme.colors.lightBlue};
      box-shadow: 0 0 9px 0 rgba(56, 177, 223, 0.26);
      border-radius: 33px;
      height: ${props.height ? props.height : '50px'};
      text-transform: uppercase;
      font-size: ${props.fontSize && props.fontSize};
      font-weight: ${props.fontWeight ? props.fontWeight : 500};
      &:hover {
        background-color:${props.theme.colors.veryLightBlue};
      }
  `
    : null}
`;

const secondary = (props: any) => css`
  ${props.variant === 'secondary'
    ? `
      ${props.theme.typography.transferButton.styles};
      color: ${props.theme.colors.white};
      background-color: ${props.theme.colors.lightBlue};
      box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.37);
      width: 288px;
      height: 40px;
      border-radius: 31px;
      
       &:hover {
        background-color:${props.theme.colors.veryLightBlue};
      }
      
  `
    : null}
`;

const tertiary = (props: any) => css`
  ${props.variant === 'tertiary'
    ? `
      ${props.theme.typography.loginButton.styles};
      color: ${props.theme.colors.lightBlue};
      border: 1px solid ${props.theme.colors.lightBlue};
      border-radius: 33px;
      height: 50px;
      margin-bottom: 30px;
      
      &:hover {
        background-color: rgba(56, 177, 223, 0.09);
      }
      
      &:active {
        background-color: rgba(56, 177, 223, 0.26);
      }
      
      :
  `
    : null}
`;

const quaternary = (props: any) => css`
  ${props.variant === 'quaternary'
    ? `
      ${props.theme.typography.loginButton.styles};
      color: ${props.theme.colors.lightBlue};
      background-color: ${props.theme.colors.azure};
      border-radius: 33px;
      height: 50px;
      text-transform: uppercase;
  `
    : null}
`;

interface Props {
  onClick: () => void;
  text: string;
  variant: Variant;
  type?: 'submit';
  width?: string;
  height?: string;
  align?: string;
  fontSize?: string;
  fontWeight?: string;
  isDisabled?: boolean;
}

export const Button = ({
  onClick,
  text,
  variant,
  type,
  width,
  height,
  align,
  fontSize,
  fontWeight,
  isDisabled
}: Props) => {
  return (
    <Container
      onClick={onClick}
      type={type ? type : 'button'}
      variant={variant}
      width={width}
      height={height}
      align={align}
      fontSize={fontSize}
      fontWeight={fontWeight}
      disabled={isDisabled}>
      {text}
    </Container>
  );
};

const Container = styled.button<{
  variant: Variant;
  width?: string;
  height?: string;
  align?: string;
  fontSize?: string;
  fontWeight?: string;
}>`
  width: ${({ width }) => (width ? width : '100%')};
  align-self: ${props => props.align};
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:disabled {
    pointer-events: none;
    cursor: auto;
  }

  ${props => primary(props)}
  ${props => secondary(props)}
  ${props => tertiary(props)} 
  ${props => quaternary(props)}
`;
