import React from 'react';
import styled from '@emotion/styled';
import { LargeText, MediumText } from './typography';

export const NotFound = () => {
  return (
    <Wrapper>
      <Container>
        <Error>
          <LargeText>404</LargeText>
        </Error>
        <Description>
          <MediumText>Page Not Found</MediumText>
        </Description>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Error = styled.div`
  color: ${({ theme }) => theme.colors.lightBlue};
  margin-bottom: 10px;
`;

const Description = styled.div`
  color: ${({ theme }) => theme.colors.midDarkGrey};
`;
