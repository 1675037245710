import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@rebass/grid/emotion';
import { isEmpty, upperFirst } from 'lodash/fp';
import HamburgerMenu from 'react-hamburger-menu';

// SVG ICONS to SVG COMPONENTS:
import { ReactComponent as HouseIcon } from 'assets/images/house.svg';
import { ReactComponent as WalletIcon } from 'assets/images/wallets.svg';
import { ReactComponent as NetworkIcon } from 'assets/images/network.svg';
import { ReactComponent as HelpIcon } from 'assets/images/help.svg';
import { ReactComponent as ForDevelopersIcon } from 'assets/images/developers-icon.svg';

import { AuthContext } from 'context/auth.context';

import {
  useSelectedCategory,
  useToggleSidebar,
  useSidebarMenuWallets
} from 'stores/ui/sidebar-ui.store';
import { useIsApiKeyModalIsOpen } from 'stores/ui/api-key-ui.store';

import history from 'utils/history.utils';
import { getPathName, sortByTextField } from 'utils/common.utils';
import { getFormattedWallets } from 'utils/user.utils';

import {
  Link,
  ExternalLink,
  Title,
  MediumText,
  SemiBoldTitle
} from 'components/common/typography';
import { TransferMenu } from 'components/layout/sidebar/transfer-menu';

export const Sidebar = () => {
  const { user } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useToggleSidebar();
  const [activeCategory, setActiveCategory] = useSelectedCategory();
  const [, setIsApiKeyModalIsOpen] = useIsApiKeyModalIsOpen();
  const [sidebarWallets, setSideBarWallets] = useSidebarMenuWallets();
  const sidebarRef = useRef<HTMLDivElement>(null);

  const [activeCategoryItem, setActiveCategoryItem] = useState();

  const handleCategoryClick = (category: string, categoryItem?: string) => {
    if (category && !categoryItem) {
      history.push(`/${category}`);
      setIsOpen(true);
      return;
    }

    if (category && categoryItem) {
      history.push(`/${category}/${categoryItem}`);
      setIsOpen(true);
    }
  };

  const handleGetApiKey = () => {
    setIsApiKeyModalIsOpen(true);
  };

  useEffect(() => {
    const pathName = history.location.pathname;
    let category = getPathName(pathName).substring(1);
    const categoryItem = pathName.substr(pathName.lastIndexOf('/') + 1);

    setActiveCategoryItem(categoryItem);
    if (categoryItem === 'swagger') {
      category = 'for-developers';
    }

    setActiveCategory(category);
  }, [history.location.pathname]);

  useEffect(() => {
    if (!user) {
      return;
    }

    if (!isEmpty(user.wallets)) {
      const formattedWallets = getFormattedWallets(user.wallets);
      const sortedFormattedWallet = sortByTextField(formattedWallets);

      setSideBarWallets(sortedFormattedWallet);
    }
  }, [user]);

  return (
    <>
      <div id="menu" />
      <Container className="sidebar" ref={sidebarRef} isOpen={isOpen}>
        <Toggle>
          <HamburgerContainer
            isOpen={isOpen}
            onClick={() => setIsOpen(!isOpen)}>
            <HamburgerMenu
              isOpen={false}
              width={20}
              height={13}
              color="#707070"
              menuClicked={() => setIsOpen(!isOpen)}
              animationDuration={0.3}
            />
          </HamburgerContainer>
          <Text isOpen={isOpen}>MENU</Text>
        </Toggle>
        <TransferMenu isOpen={isOpen} sidebarEl={sidebarRef.current} />
        <CategoryItemContainer
          isOpen={isOpen}
          isActiveCategory={activeCategory === 'dashboard'}>
          <IconContainer>
            <HouseIcon onClick={() => handleCategoryClick('dashboard')} />
          </IconContainer>
          <CategoryTextAndItemContainer>
            <CategoryTextContainer
              style={{ marginBottom: '0px' }}
              onClick={() => handleCategoryClick('dashboard')}
              isOpen={isOpen}
              isActive={activeCategory === 'dashboard'}>
              {activeCategory === 'dashboard' ? (
                <BoldCategoryTitle>Dashboard</BoldCategoryTitle>
              ) : (
                <MediumCategoryTitle>Dashboard</MediumCategoryTitle>
              )}
            </CategoryTextContainer>
          </CategoryTextAndItemContainer>
        </CategoryItemContainer>
        <CategoryItemContainer
          isOpen={isOpen}
          isActiveCategory={activeCategory === 'wallet'}>
          <IconContainer>
            <WalletIcon
              onClick={() =>
                handleCategoryClick(
                  'wallet',
                  !isEmpty(sidebarWallets) && sidebarWallets[0].text
                )
              }
            />
          </IconContainer>
          <CategoryTextAndItemContainer>
            <CategoryTextContainer
              isOpen={isOpen}
              isActive={activeCategory === 'wallet'}
              onClick={() =>
                handleCategoryClick(
                  'wallet',
                  !isEmpty(sidebarWallets) && sidebarWallets[0].text
                )
              }>
              {activeCategory === 'wallet' ? (
                <BoldCategoryTitle>Wallets</BoldCategoryTitle>
              ) : (
                <MediumCategoryTitle>Wallets</MediumCategoryTitle>
              )}
            </CategoryTextContainer>
            <Box>
              {!isEmpty(sidebarWallets) &&
                isOpen &&
                sidebarWallets.map((wallet: any) => (
                  <ListItem key={wallet.text}>
                    <Link
                      to={{
                        pathname: wallet.endpoint,
                        state: { walletId: wallet.text }
                      }}
                      highlight={wallet.text === activeCategoryItem}>
                      {upperFirst(wallet.text)}
                    </Link>
                  </ListItem>
                ))}
            </Box>
          </CategoryTextAndItemContainer>
        </CategoryItemContainer>
        <CategoryItemContainer
          isOpen={isOpen}
          isActiveCategory={activeCategory === 'network'}>
          <IconContainer>
            <NetworkIcon
              onClick={() => handleCategoryClick('network', 'block-explorer')}
            />
          </IconContainer>
          <CategoryTextAndItemContainer>
            <CategoryTextContainer
              isOpen={isOpen}
              isActive={activeCategory === 'network'}
              onClick={() => handleCategoryClick('network', 'block-explorer')}>
              {activeCategory === 'network' ? (
                <BoldCategoryTitle>Network</BoldCategoryTitle>
              ) : (
                <MediumCategoryTitle>Network</MediumCategoryTitle>
              )}
            </CategoryTextContainer>
            <Box>
              {isOpen && (
                <>
                  <ListItem>
                    <Link
                      to={{
                        pathname: '/network/block-explorer'
                      }}
                      highlight={activeCategoryItem === 'block-explorer'}>
                      Block Explorer
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link
                      to={{
                        pathname: '/network/governance'
                      }}
                      highlight={activeCategoryItem === 'governance'}>
                      Governance
                    </Link>
                  </ListItem>
                </>
              )}
            </Box>
          </CategoryTextAndItemContainer>
        </CategoryItemContainer>
        <HelpCategoryContainer
          order={isOpen ? 1 : 2}
          isOpen={isOpen}
          isActiveCategory={activeCategory === 'help'}>
          <IconContainer>
            <HelpIcon onClick={() => handleCategoryClick('help', 'about')} />
          </IconContainer>
          <CategoryTextAndItemContainer>
            <CategoryTextContainer
              isOpen={isOpen}
              isActive={activeCategory === 'help'}
              onClick={() => handleCategoryClick('help', 'about')}>
              {activeCategory === 'help' ? (
                <BoldCategoryTitle>Help</BoldCategoryTitle>
              ) : (
                <MediumCategoryTitle>Help</MediumCategoryTitle>
              )}
            </CategoryTextContainer>
            <Box>
              {isOpen && (
                <>
                  <ListItem>
                    <Link
                      to={{
                        pathname: '/help/about'
                      }}
                      highlight={activeCategoryItem === 'about'}>
                      About QEDIT
                    </Link>
                  </ListItem>
                  <ListItem>
                    <ExternalLink
                      target="_blank"
                      href={
                        'https://docs.qed-it.com/docs/qed-solution-docs/en/latest/index.html'
                      }>
                      Documentation
                    </ExternalLink>
                  </ListItem>
                </>
              )}
            </Box>
          </CategoryTextAndItemContainer>
        </HelpCategoryContainer>
        <CategoryItemContainer
          order={isOpen ? 2 : 1}
          isOpen={isOpen}
          isActiveCategory={activeCategory === 'for-developers'}
          hideBorder={true}>
          {isOpen ? (
            <IconContainer>
              <ForDevelopersIcon
                onClick={() => handleCategoryClick('swagger')}
              />
            </IconContainer>
          ) : (
            <IconPlaceHolder />
          )}
          <CategoryTextAndItemContainer>
            <CategoryTextContainer
              isOpen={isOpen}
              isActive={activeCategory === 'for-developers'}
              onClick={() => handleCategoryClick('swagger')}>
              {activeCategory === 'for-developers' ? (
                <BoldCategoryTitle>For Developers</BoldCategoryTitle>
              ) : (
                <MediumCategoryTitle>For Developers</MediumCategoryTitle>
              )}
            </CategoryTextContainer>
            <Box>
              {isOpen && (
                <>
                  <ListItem>
                    <GetApiKey onClick={handleGetApiKey}>
                      <Title>Get API Key</Title>
                    </GetApiKey>
                  </ListItem>
                  <ListItem>
                    <ExternalLink
                      target="_blank"
                      href={
                        'https://docs.qed-it.com/docs/qed-solution-docs/en/latest/docs/tutorial.html'
                      }>
                      Tutorial
                    </ExternalLink>
                  </ListItem>
                  <ListItem>
                    <Link
                      to={{
                        pathname: '/swagger'
                      }}
                      highlight={activeCategoryItem === 'swagger'}>
                      Dev API
                    </Link>
                  </ListItem>
                </>
              )}
            </Box>
          </CategoryTextAndItemContainer>
        </CategoryItemContainer>
      </Container>
    </>
  );
};

const Container = styled.div<{ isOpen: boolean }>`
  height: ${({ theme }) => `calc(100vh - ${theme.headerHeight}px)`};
  position: fixed;
  z-index: 5;
  top: ${({ theme }) => theme.headerHeight}px;
  width: ${({ theme, isOpen }) =>
    isOpen ? theme.sidebarWidth.opened : theme.sidebarWidth.closed};
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.2);
  transition: ease-in 0.3s width;
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
`;

const Toggle = styled.div`
  display: flex;
  align-items: center;
  margin: 0 8px 25px;
`;

const HamburgerContainer = styled.div<{ isOpen: boolean }>`
  transition: ease-in 0.3s;
  min-width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  background-color: ${({ theme, isOpen }) =>
    isOpen ? 'transparent' : theme.colors.menuToggleGrey};

  &:hover {
    ${({ isOpen, theme }) =>
      isOpen &&
      `
      background-color: ${theme.colors.menuToggleGrey};
    `}
  }
`;

const Text = styled.div<{ isOpen: boolean }>`
  ${({ theme }) => theme.typography.navbarText};
  color: ${({ theme }) => theme.colors.midDarkGrey};
  overflow-x: hidden;
  flex: 1;
  margin-left: 10px;
`;

// Category Level --------------------- //
const CategoryItemContainer = styled.div<{
  isOpen: boolean;
  isActiveCategory: boolean;
  hideBorder?: boolean;
  order?: number;
}>`
  min-height: fit-content;
  padding: 10px 0;
  margin-left: 12px;
  display: flex;
  order: ${({ order }) => (order ? order : 0)};

  ${({ isActiveCategory, theme, isOpen, hideBorder }) =>
    isActiveCategory &&
    !isOpen &&
    !hideBorder &&
    `
    border-right: 2px solid ${theme.colors.lightBlue};
  `}

  svg {
    transition: ease-in 0.2s;
    height: 20px;

    ${({ isActiveCategory, theme }) =>
      isActiveCategory &&
      `
        // wallet-icon
        #wallet_1,
        #wallet_2,
        #wallet_3,
        #house_1,
        #house_2,
        #house_3,

        #net_1,
        #net_2,
        #net_3,
        #net_4,
        #net_5,
        #net_6{
          stroke: ${theme.colors.lightBlue};
        }
        // help-icon
        #question-mark,
        #dev_1,
        #dev_2,
        #dev_3,
        #house_1,
        #house_2,
        #house_3{
          fill: ${theme.colors.lightBlue};
        }
    `}
  }
`;

const HelpCategoryContainer = styled(CategoryItemContainer)`
  ${({ isOpen }) =>
    !isOpen &&
    `
    margin-top: auto;
    margin-bottom: 15px;
  `}
`;

const IconContainer = styled.div`
  svg {
    width: 30px;
  }

  cursor: pointer;
  margin-left: 2px;
`;

const IconPlaceHolder = styled.div`
  width: 30px;
`;

const CategoryTextAndItemContainer = styled.div`
  margin-left: 15px;
  margin-top: 3px;
`;

const CategoryTextContainer = styled.div<{
  isOpen: boolean;
  isActive: boolean;
}>`
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.lightBlue : theme.colors.midDarkGrey};
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  margin-bottom: 10px;
  cursor: pointer;

  ${({ isOpen }) =>
    isOpen &&
    `
    height: 25px;
  `}
`;

const ListItem = styled.div`
  min-width: 145px;
  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const MediumCategoryTitle = styled(MediumText)`
  font-size: 15px;
`;

const BoldCategoryTitle = styled(SemiBoldTitle)``;

const GetApiKey = styled.div`
  color: ${({ theme }) => theme.colors.lightBlue};
  background-color: ${({ theme }) => theme.colors.veryLightTurquoise};
  max-width: 130px;
  padding: 10px 20px;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
`;
