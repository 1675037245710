import * as React from 'react';
import { Route } from 'react-router';
import styled from '@emotion/styled';

import { Header } from 'components/layout/header/header';
import { Sidebar } from 'components/layout/sidebar/sidebar';
import { NotificationsHandler } from 'components/notifications-handler/notifications-handler';
import { TransactionProofModal } from 'components/common/transaction-proof-modal';
import { TransferModal } from 'components/common/transfer-modal';
import { ApiKeyModal } from 'components/common/api-key';
import { ChangePasswordModal } from 'components/user/change-password-modal';
import { CreateNewWalletModal } from 'components/wallet/create-new-wallet/create-new-wallet-modal';
import { useNotificationsPolling } from 'hooks/notification.polling';
import { PermissionModal } from 'components/network-governance/permission-modal';

import { useIsTransferModalOpen } from 'stores/ui/transfer-modal-ui.store';
import { useIsApiKeyModalIsOpen } from 'stores/ui/api-key-ui.store';
import { useIsChangePasswordOpen } from 'stores/ui/password-modal-ui.store.ts';
import { useIsIssueNewAssetModalOpen } from 'stores/ui/issue-new-asset-modal-ui.store';
import { useIsCreateNewWalletModalIsOpen } from 'stores/ui/create-new-wallet-ui.store';
import { useIsProofModalOpen } from 'stores/ui/proof-modal-ui.store';
import { useIsPermissionsModalOpen } from 'stores/ui/permission-modal-ui.store';

import { getPathName } from 'utils/common.utils';

import { ROUTES_MAP } from 'constants/route.constants';
import { IssueNewAssetModal } from 'components/common/issue-new-asset-modal';

type Props = {
  component: React.ElementType;
  path: string;
  name: string;
};

export const Layout = ({ component: Component, ...rest }: Props) => {
  const [isOpenTransferOpen] = useIsTransferModalOpen();
  const [isApiKeyModalOpen] = useIsApiKeyModalIsOpen();
  const [isChangePasswordModalOpen] = useIsChangePasswordOpen();
  const [isProofModalOpen] = useIsProofModalOpen();
  const [isIssueNewAssetModalOpen] = useIsIssueNewAssetModalOpen();
  const [isCreateNewWalletModalOpen] = useIsCreateNewWalletModalIsOpen();
  const [isPermissionModalOpen] = useIsPermissionsModalOpen();

  useNotificationsPolling();

  return (
    <Route
      {...rest}
      render={props => {
        const pathName = getPathName(props.location.pathname);
        return (
          <>
            {isProofModalOpen && <TransactionProofModal />}
            {isOpenTransferOpen && <TransferModal />}
            {isApiKeyModalOpen && <ApiKeyModal />}
            {isIssueNewAssetModalOpen && <IssueNewAssetModal />}
            {isChangePasswordModalOpen && <ChangePasswordModal />}
            {isCreateNewWalletModalOpen && <CreateNewWalletModal />}
            {isPermissionModalOpen && <PermissionModal />}

            <Header section={ROUTES_MAP[pathName]} />
            <Flex>
              <Sidebar />
              <Component {...props} />
              <NotificationsHandler />
            </Flex>
          </>
        );
      }}
    />
  );
};

const Flex = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  display: flex;
  width: 100%;
`;
