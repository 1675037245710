import moment, { Moment } from 'moment';

import get from 'lodash/fp/get';
import size from 'lodash/fp/size';
import last from 'lodash/fp/last';
import toLower from 'lodash/fp/toLower';
import isEmpty from 'lodash/fp/isEmpty';
import castArray from 'lodash/fp/castArray';

import { Period } from 'types/asset-balance.types';
import { ColorRange } from 'types/transaction-times.types';
import { ChartBalance } from 'types/chart.types';
import { Transaction, Mode } from 'types/wallet.types';

export const filterTransactionsByType = (mode: Mode | Mode[]) => {
  return (transaction: Transaction) => {
    const modes: Mode[] = castArray(mode);

    const type = toLower(get('metadata.type', transaction));
    let fixedType: Mode;
    switch (type) {
      case 'issue':
        fixedType = 'issuances';
        break;
      case 'transfer':
        fixedType = 'transfers';
        break;
      default:
        fixedType = 'rules';
        break;
    }

    return modes.includes(fixedType);
  };
};

export const getEmptyChartData = (period: Period, balance: number) => {
  const periodTimeToSubtract = getPeriodTimeToSubtract(period);
  const emptyChartData = [
    {
      date: periodTimeToSubtract.valueOf(),
      balance
    },
    {
      date: moment().valueOf(),
      balance
    }
  ];

  return emptyChartData;
};
export const getPeriodTimeToSubtract = (period: Period): Moment => {
  switch (period) {
    case 'year': {
      return moment().subtract(1, 'year');
    }
    case 'last 3 months': {
      return moment().subtract(3, 'month');
    }
    case 'month': {
      return moment().subtract(1, 'month');
    }
    case 'week': {
      return moment().subtract(1, 'week');
    }
    case 'day': {
      return moment().subtract(1, 'day');
    }
    case 'last 15 minutes': {
      return moment().subtract(15, 'minutes');
    }
  }
};
export const getPeriodFilter = (period: Period): any => {
  const timeToSubtract = getPeriodTimeToSubtract(period);

  return (transaction: Transaction) => {
    const transactionDate = moment(transaction.metadata.timestamp);
    return transactionDate.isBetween(timeToSubtract, moment());
  };
};

export const getBalances = (
  currentBalance: number,
  transactions: any,
  period: Period,
  assetName: string
): ChartBalance[] => {
  const balances: ChartBalance[] = [];

  if (isEmpty(transactions)) {
    return [];
  }

  balances[0] = {
    date: new Date().getTime(),
    debugDate: new Date(),
    balance: currentBalance,
    assetName
  };
  balances[1] = {
    date: new Date(transactions[0].metadata.timestamp).getTime(),
    debugDate: new Date(transactions[0].metadata.timestamp),
    balance: currentBalance,
    assetName
  };

  const numBalances = size(transactions) + 2;
  for (let balanceIndex = 2; balanceIndex < numBalances - 1; balanceIndex++) {
    const currentTransaction: Transaction = transactions[balanceIndex - 2];
    const isIncomingTransaction = get(
      ['content', 'is_incoming'],
      currentTransaction
    );
    const transactionAmount = get(['content', 'amount'], currentTransaction);
    const transactionAmountAbsolute =
      (isIncomingTransaction ? 1 : -1) * transactionAmount;

    const prevTransactionDate = new Date(
      get([balanceIndex - 1, 'metadata', 'timestamp'], transactions)
    ).getTime();

    const prevBalance = balances[balanceIndex - 1].balance;
    const balance = prevBalance - transactionAmountAbsolute;

    balances[balanceIndex] = {
      date: prevTransactionDate,
      debugDate: new Date(prevTransactionDate),
      balance,
      assetName
    };
  }

  const lastTransaction: any = last(transactions);
  const isIncomingTransaction = get(
    ['content', 'is_incoming'],
    lastTransaction
  );
  const transactionAmount = get(['content', 'amount'], lastTransaction);
  const transactionAmountAbsolute =
    (isIncomingTransaction ? 1 : -1) * transactionAmount;

  const lastBalanceAmount =
    balances[balances.length - 1].balance - transactionAmountAbsolute;

  const lastBalanceDate = getPeriodTimeToSubtract(period).valueOf();
  balances.push({
    date: lastBalanceDate,
    debugDate: new Date(lastBalanceDate),
    balance: lastBalanceAmount,
    assetName
  });

  return balances;
};

export const getChartColorRange = (
  highestNumber: number | undefined
): ColorRange[] => {
  if (!highestNumber) {
    return [];
  }

  const step = Math.ceil(highestNumber / 4);

  return [
    {
      start: 0,
      end: step - 1,
      color: '#c1e8f6'
    },
    {
      start: step,
      end: step * 2 - 1,
      color: '#97d0e6'
    },
    {
      start: step * 2,
      end: step * 3 - 1,
      color: '#38b1df'
    },
    { start: step * 3, end: Infinity, color: '#1492c5' }
  ];
};
