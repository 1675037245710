import React from 'react';
import { ColorRange } from 'types/transaction-times.types';
import { map } from 'lodash/fp';
import styled from '@emotion/styled';
import { Text } from 'components/common/typography';
import Circle from 'components/common/circle';

type Props = {
  colorRanges: ColorRange[];
};

const TransactionTimesLegend = (props: Props) => {
  return (
    <Container>
      <LegendsTitle>Transactions</LegendsTitle>
      {map(
        (colorRange: ColorRange) => (
          <LegendItem key={colorRange.color}>
            <Circle size={13} color={colorRange.color} />
            <TextContainer>
              <Text>{colorRange.start === 0 ? 1 : colorRange.start}</Text>
            </TextContainer>
          </LegendItem>
        ),
        props.colorRanges
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  & + & {
    margin-left: 8px;
  }
`;

const LegendsTitle = styled(Text)`
  color: ${({ theme }) => theme.colors.midDarkGrey};
  text-transform: uppercase;
  font-size: 10px;
  margin-right: 4px;
`;

const TextContainer = styled.div`
  color: ${({ theme }) => theme.colors.midDarkGrey};
  margin-left: 4px;
`;

export default TransactionTimesLegend;
