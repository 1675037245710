import map from 'lodash/fp/map';
import get from 'lodash/fp/get';
import isNil from 'lodash/fp/isNil';
import curry from 'lodash/fp/curry';

//@ts-ignore
const doGetOr = (defaultValue, path, container) => {
  const value = get(path, container);

  return isNil(value) ? defaultValue : value;
};

export const getOr = curry(doGetOr);

//@ts-ignore
export const mapWithKeys = map.convert({ cap: false });
