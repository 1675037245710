import React from 'react';
import styled from '@emotion/styled';

import Card from 'components/common/card';
import BlockExplorerHeader from './block-explorer-header';
import BlockExplorerTable from './block-explorer-table';
import { FixedContainer } from 'components/common/page-containers/fixed-container';

import { useToggleSidebar } from 'stores/ui/sidebar-ui.store';

const BlockExplorer = () => {
  const [isOpen] = useToggleSidebar();
  return (
    <FixedContainer>
      <Container className="block-explorer-container" isSideBarOpen={isOpen}>
        <HeaderContainer>
          <BlockExplorerHeader />
        </HeaderContainer>
        <TableContainer>
          <Card>
            <BlockExplorerTable />
          </Card>
        </TableContainer>
      </Container>
    </FixedContainer>
  );
};

const Container = styled.div<{ isSideBarOpen: boolean }>`
  width: 100%;
  height: 100%;
  transition: ease-in 0.3s width;
  padding: 23px 38px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const HeaderContainer = styled.div`
  flex: 0 1 0;
  width: 100%;
  margin-bottom: 20px;
`;

const TableContainer = styled.div`
  width: 100%;
  flex: 1 0 0;
  max-height: 100%;
  overflow-y: auto;
  box-shadow: rgba(0, 0, 0, 0.11) 0px 0px 28px 0px;
`;

export default BlockExplorer;
