import { useContext, useState } from 'react';
import { createStore } from 'reusable';
import get from 'lodash/fp/get';

import { AuthContext } from 'context/auth.context';
import { useApiRequest } from 'hooks/api.hooks';
import { RecipientAddress } from 'types/wallet.types';

const RECIPIENT_ADDRESSES_LABEL = 'RECIPIENT ADDRESSES label';

export const useRecipientAddresses = createStore(() => {
  const apiRequest = useApiRequest();
  const { user } = useContext(AuthContext);
  const [recipientAddresses, setRecipientAddresses] = useState<
    RecipientAddress[]
  >([]);
  const [error, setError] = useState(null);

  const address = get('node.address', user);
  const authToken = get('node.auth_token', user);

  const fetchRecipientAddresses = async (walletIds: string[]) => {
    const allRequests: Promise<RecipientAddress>[] = [];
    walletIds.forEach(walletId => {
      allRequests.push(
        apiRequest({
          path: '/wallet/get_new_address',
          method: 'POST',
          baseUrl: address,
          headers: { 'x-auth-token': authToken },
          data: { wallet_id: walletId },
          networkLabel: RECIPIENT_ADDRESSES_LABEL,
          onError: console.error
        })
      );
    });

    const recipientAddresses: RecipientAddress[] = await Promise.all(
      allRequests
    );
    setRecipientAddresses(recipientAddresses);
  };

  return {
    recipientAddresses,
    fetchRecipientAddresses
  };
});
