import { useContext, useState } from 'react';
import { createStore } from 'reusable';
import get from 'lodash/fp/get';

import { AuthContext } from 'context/auth.context';

import { useApiRequest } from 'hooks/api.hooks';
import { useTransferAndIssuanceNotifications } from 'hooks/notifications/transfer-issuance-notifications.hooks';

import { getWalletAuth } from 'utils/user.utils';

import { NewIssuanceDetails } from 'types/wallet.types';

const NEW_ISSUANCE = 'new issuance label';

export const useNewIssuanceAsset = createStore(() => {
  const { user } = useContext(AuthContext);

  const apiRequest = useApiRequest();
  const [error, setError] = useState(null);
  const {
    fetchAndSetTransferAndIssuanceNotifications
  } = useTransferAndIssuanceNotifications();

  const urlAddress = get('node.address', user);
  const authToken = get('node.auth_token', user);
  const wallets = get('wallets', user);

  const issueNewAsset = (
    issuanceDetails: NewIssuanceDetails,
    fromWalletId: string
  ) => {
    const { asset_id, amount, to, confidential } = issuanceDetails;
    const transferAuth = getWalletAuth(fromWalletId, wallets);
    const recipient_address: string = get('value.recipient_address', to);
    const recipientName: string = get('text', to);

    return apiRequest({
      path: 'wallet/issue_asset',
      method: 'POST',
      baseUrl: urlAddress,
      headers: { 'x-auth-token': authToken },
      data: {
        amount,
        asset_id,
        confidential,
        recipient_address,
        wallet_id: fromWalletId,
        authorization: transferAuth,
        memo: ''
      },
      args: {
        amount,
        assetId: asset_id,
        sender: fromWalletId,
        recipient: recipientName,
        type: 'issue_asset'
      },
      networkLabel: NEW_ISSUANCE,
      onSuccess: fetchAndSetTransferAndIssuanceNotifications,
      onError: console.error //TODO: what to do on error
    });
  };

  return {
    error,
    setError,
    issueNewAsset
  };
});
