import React, { ReactNode } from 'react';
import styled from '@emotion/styled';

interface Props {
  children: ReactNode;
  background?: string;
  opacity?: string;
}

export const Backdrop = ({ children, background, opacity }: Props) => {
  return (
    <Container background={background} opacity={opacity}>
      {children}
    </Container>
  );
};

const Container = styled.div<{ background?: string; opacity?: string }>`
  background-color: ${({ theme, background }) =>
    background ? background : theme.colors.backdrop};
  opacity: ${({ opacity }) => opacity};
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
`;
