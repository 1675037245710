import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import OutsideClickHandler from 'react-outside-click-handler';

import styled from '@emotion/styled';

import { BoldText, Title } from 'components/common/typography';
import { Menu as SidebarTransfersMenu } from 'components/common/menu-container';

import { ReactComponent as Transfers } from 'assets/images/transfers.svg';
import { ReactComponent as Issuances } from 'assets/images/issuance.svg';
import { ReactComponent as CreateNewWalletIcon } from 'assets/images/create-wallet-icon.svg';

import { useIsTransferModalOpen } from 'stores/ui/transfer-modal-ui.store';
import { useIsIssueNewAssetModalOpen } from 'stores/ui/issue-new-asset-modal-ui.store';
import { useIsCreateNewWalletModalIsOpen } from 'stores/ui/create-new-wallet-ui.store';

interface PrimaryButtonProps {
  isOpen: boolean;
  sidebarEl: HTMLDivElement | null;
}

export const TransferMenu = ({ isOpen, sidebarEl }: PrimaryButtonProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [topMenu, setTopMenu] = useState<number>(0);
  const [leftMenu, setLeftMenu] = useState<number>(0);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (!sidebarEl || !buttonRef) {
      return;
    }

    const handleSidebarScroll = () => {
      if (buttonRef && buttonRef.current) {
        const {
          top,
          left
        }: ClientRect = buttonRef.current.getBoundingClientRect();
        setTopMenu(top + 5);
        setLeftMenu(left + 5);
      }
    };

    handleSidebarScroll();
    sidebarEl.addEventListener('scroll', handleSidebarScroll);
    return () => sidebarEl.removeEventListener('scroll', handleSidebarScroll);
  }, [sidebarEl, buttonRef]);

  const renderMenu = () => (
    <OutsideClickHandler onOutsideClick={() => setIsMenuOpen(false)}>
      <SidebarTransfersMenu position="fixed" top={topMenu} left={leftMenu}>
        <TransfersMenuItems handleMenuClose={() => setIsMenuOpen(false)} />
      </SidebarTransfersMenu>
    </OutsideClickHandler>
  );

  const menuElement = document.getElementById('menu');
  return (
    <>
      <PrimaryButton ref={buttonRef} onClick={() => setIsMenuOpen(true)}>
        <ButtonContent>
          <Plus>+</Plus>
          {isOpen && (
            <NewLabel>
              <BoldText>NEW</BoldText>
            </NewLabel>
          )}
        </ButtonContent>
      </PrimaryButton>
      {isMenuOpen &&
        menuElement &&
        ReactDOM.createPortal(renderMenu(), menuElement)}
    </>
  );
};

interface TransfersMenuProps {
  handleMenuClose: () => void;
}

export const TransfersMenuItems = ({ handleMenuClose }: TransfersMenuProps) => {
  const [, setIsOpenTransferModal] = useIsTransferModalOpen();
  const [, setIsOpenIssueAssetModal] = useIsIssueNewAssetModalOpen();
  const [, setIsCreateNewWalletModalIsOpen] = useIsCreateNewWalletModalIsOpen();

  const handleOpenModal = (setIsModalOpen: any) => () => {
    handleMenuClose();
    setIsModalOpen(true);
  };

  return (
    <OutsideClickHandler onOutsideClick={() => handleMenuClose()}>
      <MenuList>
        <MenuItemTop onClick={handleOpenModal(setIsOpenTransferModal)}>
          <Icon>
            <Transfers />
          </Icon>
          <Title>Transfer asset</Title>
        </MenuItemTop>
        <MenuItem onClick={handleOpenModal(setIsOpenIssueAssetModal)}>
          <Icon>
            <Issuances />
          </Icon>
          <Title>Issue asset</Title>
        </MenuItem>

        <MenuItemBottom
          onClick={handleOpenModal(setIsCreateNewWalletModalIsOpen)}>
          <Icon>
            <CreateNewWalletIcon />
          </Icon>
          <Title>Create wallet</Title>
        </MenuItemBottom>
      </MenuList>
    </OutsideClickHandler>
  );
};

const Plus = styled(Title)`
  font-size: 35px;
  height: 42px;
  font-weight: bold;
`;

const NewLabel = styled.div`
  letter-spacing: 0.3px;
`;

const ButtonContent = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
`;

// TODO: refactor and create common button
const PrimaryButton = styled.button`
  border: none;
  outline: none;
  padding: 2px 10px;
  min-height: 45px;
  min-width: 45px;
  max-width: 121px;
  border-radius: 25px;
  margin-bottom: 25px;
  margin-right: 8px;
  margin-left: 8px;
  background-color: ${({ theme }) => theme.colors.lightBlue};
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  box-shadow: 0 0 9px 0 rgba(56, 177, 223, 0.26);
  &:hover {
    background-color: ${({ theme }) => theme.colors.veryLightBlue};
  }
`;

const Icon = styled.div`
  svg {
    width: 20px;
  }
  max-height: 21px;
  margin-right: 15px;
`;

const MenuList = styled.div``;

const MenuItem = styled.div`
  padding-top: 8px;
  padding-left: 12px;
  padding-bottom: 8px;
  min-width: 240px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  &:first-of-type {
    padding-top: 12px;
  }

  &:last-child {
    padding-bottom: 12px;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.lightBlue};
    background-color: ${({ theme }) => theme.colors.greyLighter};
    svg {
      #Ellipse_1,
      #Ellipse_2,
      #Ellipse_3 {
        stroke: ${({ theme }) => theme.colors.lightBlue};
      }
      ,
      #Ellipse_1_inside,
      rect {
        fill: ${({ theme }) => theme.colors.greyLighter};
      }
    }
  }
`;

const MenuItemTop = styled(MenuItem)`
  &:hover {
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    svg {
      #Path_1,
      #Path_2 {
        stroke: ${({ theme }) => theme.colors.lightBlue};
        fill: ${({ theme }) => theme.colors.greyLighter};
      }
      ,
      rect {
        fill: ${({ theme }) => theme.colors.greyLighter};
      }
    }
  }
`;

const MenuItemBottom = styled(MenuItem)`
  &:hover {
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    svg {
      #Rectangle_12,
      #Path_16,
      #Path_17,
      #Path_18 {
        stroke: ${({ theme }) => theme.colors.lightBlue};
      }

      #Path_18 {
        fill: ${({ theme }) => theme.colors.lightBlue};
      }
    }
  }
`;
