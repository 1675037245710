import React, { useState } from 'react';
import styled from '@emotion/styled';
import { get } from 'lodash/fp';
import CopyToClipboard from 'react-copy-to-clipboard';

import incoming from 'assets/images/incoming.png';
import outgoing from 'assets/images/outgoing.png';
import shield from 'assets/images/shield.svg';

import { ToolTip } from 'components/common/tool-tip';

import { viewedTransactionTx } from 'stores/wallet/transactions.store';
import { useIsProofModalOpen } from 'stores/ui/proof-modal-ui.store';

import { getFormattedAmount } from 'utils/wallet.utils';
import { formatTransactionDateTime } from 'utils/date.utils';

import { Transaction as TransactionType, Mode } from 'types/wallet.types';
import {
  SemiBoldTitle,
  Title,
  Text,
  BoldTitle
} from 'components/common/typography';
import TooltipWrapper from 'components/common/tooltip-wrapper';
import {
  Confirmation,
  NoConfirmation
} from 'components/common/confirmationIcons';

interface Props {
  transaction: TransactionType;
  addressee: string;
  mode: Mode;
}

const getAmount = (amount: number, is_incoming: boolean, mode: Mode) => {
  if (!amount) {
    return 'N/A';
  }
  if (mode === 'issuances' && !is_incoming) {
    return getFormattedAmount(amount);
  }

  return getFormattedAmount(is_incoming ? amount : -amount);
};

const getAmountForTooltip = (amount: number, is_incoming: boolean): string => {
  if (!amount) {
    return 'N/A';
  }

  return (is_incoming ? amount : -amount).toString();
};

export const Transaction = ({ transaction, addressee, mode }: Props) => {
  const { setTransactionTx } = viewedTransactionTx();
  const [, setIsOpen] = useIsProofModalOpen();
  const [isHovered, setIsHovered] = useState(false);
  const [isToolTipOpen, setIsToolTipOpen] = useState(false);
  const [isCopied, setCopied] = useState(false);

  const {
    amount,
    is_incoming,
    asset_id,
    sender_id,
    confirmed_by: confirmedBy
  } = get('content', transaction);
  const { tx_hash, timestamp } = get('metadata', transaction);
  const timeStamp = formatTransactionDateTime(timestamp);

  const transactionAmount = getAmount(amount, is_incoming, mode);
  const tooltipAmount = getAmountForTooltip(amount, is_incoming);

  const handleViewTransaction = () => {
    setTransactionTx(tx_hash);
    setIsOpen(true);
  };

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  };

  return (
    <Container
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      mode={mode}>
      <ItemContainer>
        {is_incoming ? <IncomingIcon /> : <OutgoingIcon />}
      </ItemContainer>
      <ItemContainer>
        <AddressDate>
          <CopyToClipboard text={addressee} onCopy={handleCopy}>
            <FromToContainer
              onMouseEnter={() => setIsToolTipOpen(true)}
              onMouseLeave={() => setIsToolTipOpen(false)}>
              {isToolTipOpen && (
                <ToolTip text={sender_id} top="-40px" left="0" />
              )}
              <FromTo>
                <Title>{is_incoming ? 'From' : 'To'}:</Title>
              </FromTo>
              <RecipientAddress isCopied={isCopied}>
                {addressee ? addressee : 'N/A'}
              </RecipientAddress>
            </FromToContainer>
          </CopyToClipboard>
          <Text>{timeStamp}</Text>
        </AddressDate>
      </ItemContainer>
      <ItemContainer>
        <Asset>{asset_id}</Asset>
      </ItemContainer>
      <ItemContainer>
        <TooltipWrapper top={-45} left={0} tooltipText={tooltipAmount}>
          <SemiBoldTitle>{transactionAmount}</SemiBoldTitle>
        </TooltipWrapper>
      </ItemContainer>
      {mode !== 'transfers' ? null : confirmedBy ? (
        <Confirmation />
      ) : (
        <NoConfirmation />
      )}
      <ButtonItemContainer>
        {isHovered ? (
          <Button onClick={handleViewTransaction}>
            <BoldTitle>view transaction proof</BoldTitle>
          </Button>
        ) : (
          <Signature>
            <ButtonText>
              <BoldTitle>QEDIT SECURED</BoldTitle>
              {/*<BoldTitle>VIEW TRANSACTION PROOF</BoldTitle>*/}
            </ButtonText>
            <ShieldIcon />
          </Signature>
        )}
      </ButtonItemContainer>
    </Container>
  );
};

const Container = styled.div<{ mode: Mode }>`
  padding: 15px;
  color: ${({ theme }) => theme.colors.midDarkGrey};
  display: grid;
  grid-template-columns: ${({ mode }) =>
    mode === 'transfers' ? '7% 18% 15% 15% 15% 30%' : '7% 17% 23% 23% 31%'};

  &:hover {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.09);
    border-radius: 13px;
  }
`;

const IncomingIcon = styled.div`
  background-image: url("${incoming}"), ${({ theme }) =>
  theme.colors.turquoiseGradient};
  background-repeat: no-repeat, no-repeat;
  background-position: center, center;
  background-size: contain, contain;
  border-radius: 10px;
  width: 40px;
  height: 40px;
`;

const OutgoingIcon = styled(IncomingIcon)`
  background-image: url("${outgoing}"), ${({ theme }) =>
  theme.colors.blueGradient};
`;

const AddressDate = styled.div`
  display: flex;
  flex-direction: column;
`;

const FromToContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
  cursor: pointer;
  position: relative;
`;

const FromTo = styled.div`
  margin-right: 5px;
`;

const RecipientAddress = styled(Title)<{ isCopied: boolean }>`
  overflow: hidden;
  width: 50%;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: ease-in 0.2s;

  ${({ isCopied, theme }) =>
    isCopied &&
    `
    color: ${theme.colors.lightBlue};
  `}
`;

const Asset = styled(Title)`
  white-space: nowrap;
  width: 90%;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ButtonText = styled.div`
  margin-right: 15px;
`;

const ShieldIcon = styled.div`
  background: url(${shield}) no-repeat center;
  background-size: contain;
  width: 20px;
  height: 20px;
`;

const TransactionButton = styled.div`
  max-width: 330px;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
`;

const Signature = styled(TransactionButton)`
  line-height: 1.27;
  border: 1.5px solid ${({ theme }) => theme.colors.lightBlue};
  border-radius: 25px;
  padding: 0 25px;
  font-size: 15px;
  color: ${({ theme }) => theme.colors.lightBlue};
  ${({ theme }) => theme.typography.transaction};
`;

const Button = styled(TransactionButton)`
  background-color: ${({ theme }) => theme.colors.azure};
  border-radius: 33px;
  padding: 5px;
  text-transform: uppercase;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.lightBlue};
  ${({ theme }) => theme.typography.transferButton};

  @media (max-width: 1650px) {
    font-size: 14px;
  }

  @media (max-width: 1500px) {
    padding: 5px;
  }
`;

const ItemContainer = styled.div``;

const ButtonItemContainer = styled(ItemContainer)`
  display: flex;
  justify-content: flex-end;
`;
