import React, { ElementType, useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Layout } from 'components/layout/layout';
import { RouteProps } from 'react-router';

import { AuthContext } from 'context/auth.context';

interface Props {
  component: ElementType;
  isPrivate?: boolean;
  layout?: ElementType;
}

export const AppRoute = ({
  component: Component,
  layout: Layout,
  isPrivate,
  ...rest
}: Props & RouteProps) => {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={props => {
        if (isPrivate && isAuthenticated === 'authorized') {
          if (Layout) {
            return <Layout component={Component} {...rest} {...props} />;
          }
          return <Component {...props} {...rest} />;
        }

        if (!isPrivate) {
          return <Component {...props} {...rest} />;
        }

        return <Redirect to="/login" />;
      }}
    />
  );
};
