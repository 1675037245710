import { useEffect, useState } from 'react';
import { useNetworkActivity } from 'stores/wallet/network-activity.store';
import { NetworkActivityTransaction } from 'types/network-activity.types';
import { values, size, keyBy, get, flow } from 'lodash/fp';

const NUM_TRANSACTIONS_TO_LOAD_INITIALLY = 30;
const NUM_TRANSACTIONS_TO_LOAD_AFTER_SCROLL = 10;

type NetworkActivityRespone = {
  transactions: NetworkActivityTransaction[];
};

const getTransactionsByHash = flow(
  get('transactions'),
  keyBy('metadata.tx_hash')
);

const useBlockExplorerPolling = () => {
  const [allTransactionsByHash, setAllTransactionsByHash] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const { fetchNetworkActivity } = useNetworkActivity();

  const fetchLatestNetworkActivity = () => {
    fetchNetworkActivity({
      startIndex: 0,
      length: NUM_TRANSACTIONS_TO_LOAD_INITIALLY
    }).then((response: NetworkActivityRespone) => {
      const latestTransactionsByHash = getTransactionsByHash(response);

      setAllTransactionsByHash(current => ({
        ...latestTransactionsByHash,
        ...current
      }));
    });
  };

  useEffect(() => {
    fetchLatestNetworkActivity();

    const interval = setInterval(() => {
      fetchLatestNetworkActivity();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const fetchPreviousTransactions = () => {
    setIsLoading(true);
    const startIndex = size(allTransactionsByHash);
    fetchNetworkActivity({
      startIndex,
      length: NUM_TRANSACTIONS_TO_LOAD_AFTER_SCROLL
    })
      .then((response: NetworkActivityRespone) => {
        const previousTransactionsByHash = getTransactionsByHash(response);
        setAllTransactionsByHash(current => ({
          ...current,
          ...previousTransactionsByHash
        }));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    transactions: values(allTransactionsByHash),
    fetchPreviousTransactions,
    isLoading
  };
};

export default useBlockExplorerPolling;
