import React from 'react';
const ChartActiveDot = ({
  cx,
  cy,
  fill
}: {
  cx: number;
  cy: number;
  fill: string;
}) => {
  return (
    <circle cx={cx} cy={cy} r={5} stroke={fill} strokeWidth={4} fill="white" />
  );
};

export default ChartActiveDot;
