import styled from '@emotion/styled';
import { get, map, capitalize } from 'lodash/fp';
import React from 'react';
import { TooltipContainer } from 'components/common/chart-tooltip-styles';
import { BoldText, Text } from 'components/common/typography';
import Circle from 'components/common/circle';

import { CHART_COLORS, CHART_TOOLTIP_LABEL } from './processing-time-colors';
import { ProcessingChartColorsTypes } from 'types/chart.types';
import moment from 'moment';

import { normalizeSeconds, normalizeMinutes } from 'utils/date.utils';

type Props = {
  active: boolean;
  payload: object[];
};

const ProcessingTimeTooltip = ({ active, payload, ...rest }: Props) => {
  if (!active) {
    return null;
  }

  const data = get(['0', 'payload'], payload);
  const legendTypes: ProcessingChartColorsTypes[] = ['min', 'average', 'max'];

  const handleNormalizeTime = (time: number) => {
    const minutes = normalizeMinutes(time);
    const seconds = normalizeSeconds(time);

    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

    if (minutes === 0) {
      return (
        <>
          <Text>
            <span>&nbsp;</span>.
          </Text>
          <BoldText>{formattedSeconds}s</BoldText>
        </>
      );
    }

    return (
      <>
        <BoldText>
          <span>&nbsp;</span>
          {minutes}.{formattedSeconds}m
        </BoldText>
      </>
    );
  };

  return (
    <TooltipContainer>
      <Container>
        <Legends>
          {legendTypes.map(type => (
            <Legend key={type}>
              <Circle color={CHART_COLORS[type]} size={10} />
              <LegendTitle>
                <ValueContainer>
                  {data && (
                    <>
                      <Text>{`${CHART_TOOLTIP_LABEL[type]}`}</Text>
                      {handleNormalizeTime(data[type])}
                    </>
                  )}
                </ValueContainer>
              </LegendTitle>
            </Legend>
          ))}
        </Legends>
        <TimeStamp>{data && moment(data.date).format('hh:mm A')}</TimeStamp>
      </Container>
    </TooltipContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Legends = styled.div`
  display: flex;
  align-items: center;
`;
const TimeStamp = styled(Text)`
  margin-top: 5px;
`;

const Legend = styled.div`
  display: flex;
  align-items: center;
  & + & {
    margin-left: 16px;
  }
`;

const LegendTitle = styled.div`
  display: flex;
  align-items: center;
  margin-left: 4px;
`;

const ValueContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
`;

export default ProcessingTimeTooltip;
