import styled from '@emotion/styled';

import checkIcon from 'assets/images/confirmation-check.svg';
import tiltedDash from 'assets/images/reject-icon.svg';

const Circle = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
`;

export const Confirmation = styled(Circle)`
  background: ${({ theme }) => theme.colors.veryLightTurquoise}
    url(${checkIcon}) no-repeat center;
  background-size: 15px 15px;
`;

export const NoConfirmation = styled(Circle)`
  background: ${({ theme }) => theme.colors.grey} url(${tiltedDash}) no-repeat
    center;
  background-size: 25px 25px;
  transform: rotate(-45deg);
`;
