import React, { useState, ReactNode, useRef, useEffect } from 'react';
import styled from '@emotion/styled';

import { ToolTip } from 'components/common/tool-tip';

interface Props {
  children?: ReactNode;
  tooltipText?: string;
  tooltipComponent?: React.ComponentType;
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}

const TooltipWrapper = ({
  children,
  tooltipText,
  tooltipComponent: TooltipComponent,
  top,
  left,
  right,
  bottom
}: Props) => {
  const [leftSide, setLeftSide] = useState<number>(left ? left : 0);
  const [isToolTipOpen, setIsToolTipOpen] = useState(false);

  const tooltipRef = useRef<HTMLDivElement>(null);

  const onEnter = () => setIsToolTipOpen(true);
  const onLeave = () => setIsToolTipOpen(false);

  useEffect(() => {
    if (tooltipRef && tooltipRef.current && isToolTipOpen) {
      const { right } = document.body.getBoundingClientRect();
      const elementRect = tooltipRef.current.getBoundingClientRect();
      const elementRightCorner = (elementRect as DOMRect).x + elementRect.width;
      if (elementRightCorner > right) {
        const realLeft = left ? left : 0;
        const difference = elementRightCorner - realLeft - right + 10;
        setLeftSide(difference * -1);
      }
    }
  }, [isToolTipOpen]);

  return (
    <Container onMouseEnter={onEnter} onMouseLeave={onLeave}>
      {children}
      {isToolTipOpen && tooltipText && (
        <ToolTip
          text={tooltipText}
          top={getPXString(top)}
          left={getPXString(left)}
          bottom={getPXString(bottom)}
          right={getPXString(right)}
        />
      )}

      {TooltipComponent && (
        <TooltipComponentWrapper
          ref={tooltipRef}
          top={top}
          left={leftSide}
          bottom={bottom}
          right={right}
          isVisible={isToolTipOpen}>
          <TooltipComponent />
        </TooltipComponentWrapper>
      )}
    </Container>
  );
};

TooltipWrapper.defaultProps = {};

const Container = styled.div`
  position: relative;
`;

const TooltipComponentWrapper = styled.div<{
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
  isVisible?: boolean;
}>`
  position: absolute;
  visibility: ${({ isVisible }) => (isVisible ? undefined : 'hidden')};
  top: ${({ top }) => (top ? `${top}px` : '')};
  right: ${({ right }) => (right ? `${right}px` : '')};
  bottom: ${({ bottom }) => (bottom ? `${bottom}px` : '')};
  left: ${({ left }) => (left ? `${left}px` : '')};
`;
export default TooltipWrapper;

function getPXString(value: number | undefined) {
  if (value === 0 || value) {
    return `${value}px`;
  }
}
