import React, { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';

import get from 'lodash/fp/get';
import find from 'lodash/fp/find';
import isEmpty from 'lodash/fp/isEmpty';
import isNumber from 'lodash/fp/isNumber';

import { AuthContext } from 'context/auth.context';

import Modal from 'components/common/modal/modal';
import { Button } from 'components/common/button';
import { TextField } from 'components/common/text-field';
import { SingleSelect } from 'components/common/single-select';
import { BoldTitle, Text } from 'components/common/typography';
import { IntegrationAutosuggest } from 'components/common/autosuggest';
import { Switch } from 'components/common/switch';

import { useAddressBook } from 'stores/user/address-book';
import { Rule, useRules } from 'stores/wallet/rules.store';
import { useNewIssuanceAsset } from 'stores/wallet/new-issuances-assets.store';
import { usePublicKeysOfWallets } from 'stores/wallet/public-keys-of-wallets.store';
import { useIsIssueNewAssetModalOpen } from 'stores/ui/issue-new-asset-modal-ui.store';

import { addressesSelector } from 'selectors/addressBook.selectors';
import { rulesFromIssueNewAsset } from 'selectors/rules.selectors';

import { Wallet } from 'types/firebase.types';
import { ValueType } from 'types/common.types';
import { NewIssuanceDetails, PublicKey } from 'types/wallet.types';

import theme from 'constants/themes.constants';

import { isPrefixOf } from 'utils/common.utils';
import { sortByTextField } from 'utils/common.utils';

interface UIAssetMapping {
  namespace: string;
  walletId: string;
  isConfidential: boolean;
}

const newIssuanceAssetDetailsInitialState: NewIssuanceDetails = {
  asset_id: '',
  amount: '',
  to: { value: '', text: '' },
  confidential: false
};

export const IssueNewAssetModal = () => {
  const [, setIsOpen] = useIsIssueNewAssetModalOpen();
  const { addresses } = useAddressBook(state => ({
    addresses: sortByTextField(addressesSelector(state))
  }));
  const { issueNewAsset } = useNewIssuanceAsset();
  const { fetchPublicKeys, publicKeys } = usePublicKeysOfWallets();
  const { fetchRules, rules } = useRules(state => ({
    fetchRules: state.fetchRules,
    rules: rulesFromIssueNewAsset(state)
  }));

  const [isAmountError, setIsAmountError] = useState<boolean>(false);
  const [autosuggestError, setAutosuggestError] = useState<boolean>(false);

  const { user } = useContext(AuthContext);
  const wallets: Wallet[] = get('wallets', user);
  const [suggestions, setSuggestions] = useState<ValueType<string>[]>([]);
  const [assetMappings, setAssetMappings] = useState<UIAssetMapping[]>([]);
  const [disabledSwitch, setDisabledSwitch] = useState<boolean>(true);
  const [newIssuanceDetails, setNewIssuanceDetails] = useState<
    NewIssuanceDetails
  >(newIssuanceAssetDetailsInitialState);

  useEffect(() => {
    const walletsIds: string[] = wallets.map(item => item.id);
    fetchPublicKeys(walletsIds);
    fetchRules();
  }, []);

  useEffect(() => {
    if (isEmpty(newIssuanceDetails.amount)) {
      if (isAmountError) {
        setIsAmountError(false);
      }
      return;
    }

    const amount = parseInt(newIssuanceDetails.amount);
    if (amount <= 0 || isNaN(amount) || !isNumber(amount)) {
      setIsAmountError(true);
    } else {
      setIsAmountError(false);
    }
  }, [newIssuanceDetails.amount]);

  useEffect(() => {
    if (isEmpty(rules) || isEmpty(publicKeys)) {
      return;
    }
    const suggestions: ValueType<string>[] = rules.map((rule: Rule) => ({
      value: rule.namespace,
      label: rule.namespace
    }));

    const allAssetMapping: UIAssetMapping[] = rules.reduce(
      (result: UIAssetMapping[], rule: Rule) => {
        const publicKey = find<PublicKey>(
          { public_key: rule.public_key },
          publicKeys
        );

        if (!publicKey) {
          return result;
        }

        result.push({
          isConfidential: rule.can_issue_confidentially,
          namespace: rule.namespace,
          walletId: publicKey.wallet_id
        });

        return result;
      },
      [] as UIAssetMapping[]
    );

    setAssetMappings(allAssetMapping);
    setSuggestions(suggestions);
  }, [publicKeys, rules]);

  const getAssetData = (assetMappings: UIAssetMapping[], assetId: string) => {
    const assetData: UIAssetMapping | undefined = assetMappings.find(item =>
      isPrefixOf(item.namespace, assetId)
    );

    if (assetData) {
      return assetData;
    }

    const wildCard: UIAssetMapping | undefined = assetMappings.find(
      item => item.namespace === '*'
    );

    return wildCard ? wildCard : undefined;
  };

  const handleAssetIdChanged = (assetId: string) => {
    if (!assetMappings) {
      return;
    }

    const assetData = getAssetData(assetMappings, assetId);

    if (!assetData) {
      setDisabledSwitch(true);
      setAutosuggestError(true);
      setNewIssuanceDetails({
        ...newIssuanceDetails,
        confidential: false,
        asset_id: ''
      });
    } else {
      setDisabledSwitch(!assetData.isConfidential);
      setAutosuggestError(false);
      setNewIssuanceDetails({
        ...newIssuanceDetails,
        confidential: assetData.isConfidential,
        asset_id: assetId
      });
    }
  };

  const handleFormChanged = (key: string) => (item: any) =>
    setNewIssuanceDetails({ ...newIssuanceDetails, [key]: item });

  const handleSubmit = () => {
    const { asset_id, amount, to } = newIssuanceDetails;

    const assetMapping = getAssetData(assetMappings, asset_id);

    if (
      !asset_id ||
      !assetMapping ||
      isEmpty(amount) ||
      isEmpty(to.value) ||
      isAmountError
    ) {
      return;
    }

    issueNewAsset(newIssuanceDetails, assetMapping.walletId);
    setIsOpen(false);
  };

  return (
    <Modal onClose={() => setIsOpen(false)}>
      <Container>
        <Title>
          <BoldTitle>ISSUE ASSET</BoldTitle>
        </Title>
        <IntegrationAutosuggest
          suggestions={suggestions}
          onInputChange={handleAssetIdChanged}
          error={autosuggestError}
          helperText={
            autosuggestError
              ? 'You do not have permission to issue this asset id'
              : ''
          }
        />
        <TextFieldContainer>
          <TextField
            type="number"
            label="Amount"
            value={newIssuanceDetails.amount}
            onChange={handleFormChanged('amount')}
            error={isAmountError}
            helperText={isAmountError ? 'Amount must be greater than 0' : ''}
          />
        </TextFieldContainer>
        <SingleSelect
          items={addresses}
          onSelect={handleFormChanged('to')}
          width="100%"
          label="To"
          selectedValue={newIssuanceDetails.to.text}
          margin="0 0 40px"
          dropDownMaxHeight={theme.singleSelect.dropdownMaxHeight}
        />
        <SwitchContainer>
          <SwitchLabel>
            <Text>Private Issuance</Text>
          </SwitchLabel>
          <Switch
            isChecked={newIssuanceDetails.confidential}
            onChange={handleFormChanged('confidential')}
            disabled={disabledSwitch}
          />
        </SwitchContainer>
        <ActionsContainer>
          <Cancel onClick={() => setIsOpen(false)}>
            <Text>Cancel</Text>
          </Cancel>
          <Button
            onClick={handleSubmit}
            text="issue asset"
            variant="primary"
            width="200px"
            fontSize="12px"
            fontWeight="600"
          />
        </ActionsContainer>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  min-width: 500px;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colors.midDarkGrey};
  padding: 35px 0 50px;
  text-align: center;
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Cancel = styled.div`
  color: ${({ theme }) => theme.colors.midDarkGrey};
  cursor: pointer;
`;

const SwitchLabel = styled.div`
  color: ${({ theme }) => theme.colors.midDarkGrey};
  margin-right: 20px;
`;

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
`;

const TextFieldContainer = styled.div`
  .label {
    margin-bottom: 6px;
  }
`;
