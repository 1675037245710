import React from 'react';
import styled from '@emotion/styled';
import { H2 } from 'components/common/typography';

const BlockExplorerHeader = () => {
  return (
    <Container>
      <H2>NETWORK BLOCK EXPLORER</H2>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.midDarkGrey};
  padding: 0 5px;
`;

export default BlockExplorerHeader;
