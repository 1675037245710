import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { isEmpty } from 'lodash/fp';

import Modal from 'components/common/modal/modal';
import { Button } from 'components/common/button';
import { ModalTitle } from 'components/common/modal/modal-title';
import { ModalSubtitle as Subtitle } from 'components/common/modal/modal-subtitle';
import {
  TextField,
  TextFieldContainer,
  ValidIcon
} from 'components/common/text-field';

import { useIsChangePasswordOpen } from 'stores/ui/password-modal-ui.store.ts';

import {
  handleFirebaseUpdatePassword,
  reauthenticateUser
} from 'hooks/firebase/firebase.auth.hooks';

import { validatePassword } from 'utils/login.utils';

const INITIAL_STATE = '';

export const ChangePasswordModal = () => {
  const [newPassword, setNewPassword] = useState<string>(INITIAL_STATE);
  const [currentPassword, setCurrentPassword] = useState<string>(INITIAL_STATE);
  const [confirmPassword, setConfirmPassword] = useState<string>(INITIAL_STATE);

  const [isDifferent, setDifferent] = useState(true);
  const [isPasswordUpdated, setPasswordUpdated] = useState(false);
  const [isNewPasswordValid, setIsNewPasswordValid] = useState(false);
  const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(false);

  const [isConfirmTouched, setIsConfirmTouched] = useState(false);
  const [isNewPasswordTouched, setIsNewPasswordTouched] = useState(false);

  const [, setIsChangePasswordModalOpen] = useIsChangePasswordOpen();

  const handleSubmit = () => {
    setIsNewPasswordTouched(true);
    setIsConfirmTouched(true);
    if (
      newPassword === confirmPassword &&
      isNewPasswordValid &&
      isConfirmPasswordValid &&
      newPassword !== currentPassword
    ) {
      reauthenticateUser(currentPassword)
        .then(() => {
          handleFirebaseUpdatePassword(newPassword)
            .then(() => {
              setPasswordUpdated(true);
              setTimeout(() => setIsChangePasswordModalOpen(false), 2500);
            })
            .catch(e => console.log('Error: ', e));
        })
        .catch(e => console.log('Error: ', e));
    }
  };

  useEffect(() => {
    if (
      !isEmpty(currentPassword) &&
      !isEmpty(newPassword) &&
      currentPassword === newPassword
    ) {
      setDifferent(false);
      return;
    }
    setDifferent(true);
  }, [currentPassword, newPassword]);

  useEffect(() => {
    if (validatePassword(newPassword) && isDifferent) {
      setIsNewPasswordValid(true);
      return;
    }
    setIsNewPasswordValid(false);
  }, [newPassword, isDifferent]);

  useEffect(() => {
    if (
      isDifferent &&
      validatePassword(confirmPassword) &&
      confirmPassword === newPassword
    ) {
      setIsConfirmPasswordValid(true);
      return;
    }
    setIsConfirmPasswordValid(false);
  }, [confirmPassword, newPassword, isDifferent]);

  return (
    <Modal onClose={() => setIsChangePasswordModalOpen(false)}>
      <Container>
        {isPasswordUpdated ? (
          <>
            <ModalTitle
              text="Change Password"
              align="center"
              margin="0 0 30px"
            />
            <Subtitle
              text="Your password has been successfully changed."
              align="center"
            />
          </>
        ) : (
          <>
            <ModalTitle
              text="Change Password"
              align="center"
              margin="0 0 25px"
            />
            <Subtitle
              text="Passwords must be at least 6 characters long and include one uppercase letter, one lowercase letter, one symbols and one number."
              align="center"
              margin="0 0 25px"
            />
            <TextFieldContainer>
              <TextField
                type="password"
                label="Current password"
                value={currentPassword}
                onChange={setCurrentPassword}
              />
            </TextFieldContainer>
            <TextFieldContainer>
              <TextField
                type="password"
                label="New password"
                value={newPassword}
                autoComplete="new-password"
                onChange={(value: string) => {
                  setNewPassword(value);
                  setIsNewPasswordTouched(true);
                }}
                showIcon={isNewPasswordValid}
                iconComponent={<ValidIcon />}
                error={
                  !isDifferent || (!isNewPasswordValid && isNewPasswordTouched)
                }
                helperText={
                  !isDifferent
                    ? 'New password must be different from current password.'
                    : !isNewPasswordValid && isNewPasswordTouched
                    ? 'Password does not follow above instructions.'
                    : ''
                }
              />
            </TextFieldContainer>
            <TextFieldContainer>
              <TextField
                type="password"
                autoComplete="new-password"
                label="Confirm New Password"
                value={confirmPassword}
                onChange={(value: string) => {
                  setConfirmPassword(value);
                  setIsConfirmTouched(true);
                }}
                showIcon={isConfirmPasswordValid}
                iconComponent={<ValidIcon />}
                error={isConfirmTouched && !isConfirmPasswordValid}
                helperText={
                  isConfirmTouched && !isConfirmPasswordValid
                    ? "Passwords don't match."
                    : ''
                }
              />
            </TextFieldContainer>
            <ActionsContainer>
              <Button
                onClick={handleSubmit}
                variant="primary"
                text="CHANGE PASSWORD"
                height="45px"
                width="250px"
                fontSize="15px"
                fontWeight="600"
              />
            </ActionsContainer>
          </>
        )}
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  width: 600px;
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
