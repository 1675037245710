import React, { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion';
import get from 'lodash/fp/get';

import { ReactComponent as Pencil } from 'assets/images/pencil.svg';

import { AuthContext } from 'context/auth.context';

import {
  addWalletToNetwork,
  deleteWalletFromNetwork
} from 'hooks/firebase/firebase.hooks';

import { CopyToClipBoard } from 'components/common/copy-to-clip-board';
import { H2, Text } from 'components/common/typography';
import { Switch } from 'components/common/switch';

import { theme } from 'constants/themes.constants';
import { User } from 'types/firebase.types';

interface Props {
  walletId: string;
  walletAuth?: string;
  publicKey?: string;
  recipientAddress?: string;
}

export const Header = ({
  publicKey,
  walletAuth,
  recipientAddress,
  walletId
}: Props) => {
  const [isWalletPublic, setIsWalletPublic] = useState(false);
  const { user }: { user: User } = useContext(AuthContext);

  const handleDeleteWalletFromNetwork = () => {
    if (walletId) {
      deleteWalletFromNetwork(walletId).catch(e =>
        console.log('error in handleDeleteWalletFromNetwork', e)
      );
    }
  };

  const handleAddWalletToNetwork = () => {
    if (walletId && walletAuth && publicKey && recipientAddress) {
      addWalletToNetwork(
        walletId,
        walletAuth,
        publicKey,
        recipientAddress
      ).catch(e => console.log('error in handleAddWalletToNetwork', e));
    }
  };

  const handleToggleWalletPublicity = () => {
    if (!isWalletPublic) {
      handleAddWalletToNetwork();
      setIsWalletPublic(true);
      return;
    }
    handleDeleteWalletFromNetwork();
    setIsWalletPublic(false);
  };

  useEffect(() => {
    const addressBook = get('network.address_book', user);

    if (addressBook && addressBook[walletId]) {
      setIsWalletPublic(true);
      return;
    }

    setIsWalletPublic(false);
  }, []);

  return (
    <Container>
      <NameAndPublicContainer>
        <Flex alignItems="center">
          <WalletName>{walletId}</WalletName>
          {/* TODO: Add when walletNameChange functionality is available */}
          {/*<StyledPencil />*/}
        </Flex>
        <SwitchContainer alignItems="center" isWalletPublic={isWalletPublic}>
          <HelperText>Make this wallet name public to the network</HelperText>
          <Switch
            isChecked={isWalletPublic}
            onChange={handleToggleWalletPublicity}
            disabled={false}
          />
        </SwitchContainer>
      </NameAndPublicContainer>
      <Flex alignItems="center" marginLeft={30}>
        <CopyToClipBoardContainer>
          <PublicKeyLabel>
            <Text>Public key</Text>
          </PublicKeyLabel>
          <CopyToClipBoard
            value={publicKey ? publicKey : ''}
            toolTip={{ top: '-35px', left: '0' }}
            highlightOnCopy={true}
          />
        </CopyToClipBoardContainer>
        <CopyToClipBoardContainer>
          <PublicKeyLabel>
            <Text>Wallet ID</Text>
          </PublicKeyLabel>
          <CopyToClipBoard
            value={walletId}
            toolTip={{ top: '-35px', left: '0' }}
            highlightOnCopy={true}
          />
        </CopyToClipBoardContainer>
      </Flex>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${({ theme }) => theme.colors.midDarkGrey};
`;

const NameAndPublicContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const WalletName = styled(H2)`
  margin-right: 10px;
`;

const HelperText = styled(Text)`
  margin-right: 10px;
  line-height: 0;
`;

const StyledPencil = styled(Pencil)`
  width: 20px;
`;

const CopyToClipBoardContainer = styled.div`
  width: 290px;
  min-height: 40px;
  background-color: ${({ theme }) => theme.colors.midLightGrey};
  border-radius: 15px;
  padding: 3px 12px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.11);

  &:not(:last-child) {
    margin-right: 20px;
  }

  * {
    transition: ease-in 0.2s;
  }

  @media (max-width: 1650px) {
    width: 200px;
  }
`;

const PublicKeyLabel = styled.div`
  color: ${({ theme }) => theme.colors.midDarkGrey};
`;

const SwitchContainer = styled(Flex)<{ isWalletPublic: boolean }>`
  align-items: center;

  .react-switch-bg {
    ${({ isWalletPublic, theme }) =>
      isWalletPublic &&
      `
     border: 1px solid ${theme.colors.switchBorderGrey};
  `}
  }
`;
