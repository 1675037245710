import React, { useState } from 'react';
import styled from '@emotion/styled';
import CopyToClipboard from 'react-copy-to-clipboard';

import clipboard from 'assets/images/clip-board.png';

import { ToolTip } from 'components/common/tool-tip';
import { BaseLink } from 'components/common/typography';

interface Props {
  value: string;
  toolTip?: {
    top?: string;
    right?: string;
    bottom?: string;
    left?: string;
  };
  highlightOnCopy?: boolean;
}

export const CopyToClipBoard = ({ value, toolTip, highlightOnCopy }: Props) => {
  const [isToolTipOpen, setIsToolTipOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const onCopy = () => {
    if (highlightOnCopy) {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 1000);
    }
  };

  return (
    <Container id="copy-to-clip-board">
      {toolTip && isToolTipOpen && (
        <ToolTip
          text={value}
          top={toolTip.top}
          right={toolTip.right}
          bottom={toolTip.bottom}
          left={toolTip.left}
        />
      )}
      <Value
        isCopied={isCopied}
        onMouseEnter={() => setIsToolTipOpen(true)}
        onMouseLeave={() => setIsToolTipOpen(false)}>
        {value}
      </Value>
      <CopyToClipboard text={value} onCopy={onCopy}>
        <Icon />
      </CopyToClipboard>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

const Value = styled.div<{ isCopied?: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 0.95;
  transition: ease-in-out 0.3s;

  ${BaseLink}

  ${({ isCopied, theme }) =>
    isCopied &&
    `
    color: ${theme.colors.lightBlue};
  `}
`;

const Icon = styled.div`
  background: url(${clipboard}) no-repeat center;
  background-size: contain;
  min-width: 23px;
  height: 20px;
  cursor: pointer;
`;
