import React from 'react';
import styled from '@emotion/styled';
import moment from 'moment';
import { get, toNumber } from 'lodash/fp';
import { getFormattedAmount } from 'utils/wallet.utils';
import { ChartLabelSvg } from 'components/common/typography';

type ChartLabelProps = {
  // TS doesn't work when payload is mandatory
  payload?: {
    value: string;
  };
  dy?: number;
  dx?: number;
  valueFormatter: (value: number) => string;
};

const ChartLabel = ({ payload, valueFormatter, ...rest }: ChartLabelProps) => {
  const value = toNumber(get('value', payload)) || 0;
  const formattedValue = valueFormatter(value);
  return <ChartLabelSvg {...rest}>{formattedValue}</ChartLabelSvg>;
};

export default ChartLabel;
