import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';

import Card from 'components/common/card';
import { H2, Title, Text } from 'components/common/typography';
import { FixedContainer } from 'components/common/page-containers/fixed-container';

import { useAppInfo } from 'stores/app-info.store';

import { getOr } from 'utils/lodash.utils';

export const About = () => {
  const { appInfo, fetchAppInfo } = useAppInfo();

  const version = getOr('...', 'version', appInfo);

  useEffect(() => {
    fetchAppInfo();
  }, []);
  return (
    <FixedContainer>
      <Container>
        <Helmet>
          <title>About QEDIT | QEDIT</title>
        </Helmet>

        <TitleContainer>
          <H2>ABOUT QEDIT</H2>
        </TitleContainer>
        <Version>
          <Title>Version {version}</Title>
        </Version>
        <Card>
          <Text>About Qedit</Text>
        </Card>
      </Container>
    </FixedContainer>
  );
};

const Container = styled.div`
  padding: 30px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.midDarkGrey};
`;

const TitleContainer = styled.div`
  margin-bottom: 20px;
`;

const Version = styled.div`
  margin-bottom: 20px;
`;
