import * as React from 'react';
import forEach from 'lodash/forEach';
import { IntlProvider, addLocaleData, Locale } from 'react-intl';
import moment, { LocaleSpecification } from 'moment';

import locales from 'constants/locales';

import { useLocalization } from 'stores/localization.store';

forEach(locales, (locale: any, key: any) =>
  moment.defineLocale(
    key,
    (locale.dateTimeFormat as unknown) as LocaleSpecification
  )
);

// Go over all of the available locales and register them
forEach(locales, (_value: any, key: string) => {
  addLocaleData(({
    locale: key,
    // Couldn't find any documentation about 'pluralRuleFunction', throws error if not present
    pluralRuleFunction: () => {}
  } as unknown) as Locale);
});

export const Localization = ({ children }: Props) => {
  const [locale] = useLocalization();
  const messages = locales[locale] ? locales[locale].translations : {};
  return (
    <IntlProvider locale={locale} key={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
};

interface Props {
  children: React.ReactNode;
}

export default Localization;
