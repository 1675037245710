import React, { useState } from 'react';
import styled from '@emotion/styled';

import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';

import { BoldTitle } from 'components/common/typography';
import InfiniteScroll from 'components/common/infinite-scroll';
import { Transaction } from 'components/wallet/transactions/transaction';
import TransactionModeTabs from 'components/common/transaction-mode-tabs';
import { TableCategories } from 'components/wallet/transactions/table-categories';

import { AddressBook } from 'types/firebase.types';
import { Transaction as TransactionType, Mode } from 'types/wallet.types';

import { useAddressBook } from 'stores/user/address-book';

interface Props {
  walletId: string;
  transactions: TransactionType[];
  fetchPreviousTransactions: () => any;
  hasMore: boolean;
  isLoading: boolean;
  isInitialLoading: boolean;
}

const getInvertedAddressBook = (addressBook: AddressBook) =>
  Object.keys(addressBook)
    .map(key => ({
      [addressBook[key].recipient_address]: key,
      [addressBook[key].public_key]: key
    }))
    .reduce((acc, curr) => ({ ...acc, ...curr }), {});

export const Transactions = (props: Props) => {
  const {
    fetchPreviousTransactions,
    isLoading,
    transactions,
    hasMore,
    isInitialLoading
  } = props;
  const [mode, setOpenMode] = useState<Mode>('transfers');
  const { addressBook } = useAddressBook();
  const invertedAddressBook = getInvertedAddressBook(addressBook);
  const filteredTransactions = (transactions || []).filter(
    (transaction: TransactionType) => {
      let type = get('metadata.type', transaction);

      if (type) {
        type = type.toLowerCase();
        switch (mode) {
          case 'issuances':
            return type === 'issue' && type !== 'rule';
          case 'transfers':
            return type !== 'issue' && type !== 'rule';
          default:
            return null;
        }
      }
    },
    transactions
  );

  const loadMore = () => {
    if (!isLoading && isEmpty(filteredTransactions)) {
      return;
    }

    fetchPreviousTransactions();
  };

  const isEmptyState = !isInitialLoading && isEmpty(filteredTransactions);

  return (
    <Container>
      <HeaderContainer>
        <Header>
          <TransactionModeTabs mode={mode} onSelect={setOpenMode} />
        </Header>
      </HeaderContainer>
      {isEmptyState ? (
        <NoData>
          <BoldTitle>No transaction history.</BoldTitle>
        </NoData>
      ) : (
        <ContentContainer>
          <TableCategories mode={mode} />
          <InfiniteScroll
            loadMore={loadMore}
            hasMore={hasMore}
            isLoadingMore={isLoading}
            useWindow={true}
            isInitialLoading={isInitialLoading}>
            <Content>
              {filteredTransactions.map(transaction => {
                const addressee =
                  invertedAddressBook[
                    transaction.content.is_incoming
                      ? transaction.content.sender_id
                      : transaction.content.recipient_address
                  ];
                return (
                  <Transaction
                    key={transaction.metadata.tx_hash}
                    mode={mode}
                    transaction={transaction}
                    addressee={addressee}
                  />
                );
              })}
            </Content>
          </InfiniteScroll>
        </ContentContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  flex: 1;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  position: sticky;
  top: 60px;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 1;
`;

const ContentContainer = styled.div`
  height: 100%;
  flex: 1 0 0;
  max-height: 100%;
  &:hover {
    ${({ theme }) => theme.scrollbar.main};
  }
`;
const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 25px;
`;

const NoData = styled.div`
  height: ${({ theme }) => `${theme.cardContentSpace.height}px`};
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.midDarkGrey};
`;
