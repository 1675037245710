import styled from '@emotion/styled';
import { get } from 'lodash/fp';
import moment from 'moment';
import React from 'react';
import { getFormattedAmount } from 'utils/wallet.utils';
import { TooltipContainer } from 'components/common/chart-tooltip-styles';
import { BoldText, Text } from 'components/common/typography';
import { Flex } from '@rebass/grid/emotion';

type Props = {
  active: boolean;
  payload: object[];
};

//todo: use 'LT' etc for localization support
const formatDate = (tickItem: number) =>
  moment(tickItem).format('HH:mm DD/MM/YY');

export const AssetBalanceTooltip = ({ active, payload }: Props) => {
  const balance = get(['0', 'payload', 'balance'], payload);
  const formattedBalance = getFormattedAmount(balance);
  const date = formatDate(get(['0', 'payload', 'date'], payload));
  const assetName = get(['0', 'payload', 'assetName'], payload);

  if (active) {
    return (
      <TooltipContainer>
        <Flex flexDirection="column" alignItems="center">
          <BoldText>{formattedBalance}</BoldText>
          <Text>{assetName}</Text>
          <Text>{date}</Text>
        </Flex>
      </TooltipContainer>
    );
  }

  return null;
};
