import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { Pie } from '@nivo/pie';
import size from 'lodash/fp/size';
import isEmpty from 'lodash/fp/isEmpty';

import { ReactComponent as HeaderIcon } from 'assets/images/total-assets.svg';

import theme from 'constants/themes.constants';

import { Loader } from 'components/common/loader';
import IconicText from 'components/common/iconic-text';
import TooltipWrapper from 'components/common/tooltip-wrapper';
import PieChartTooltip from 'components/wallet/assets/pie-chart-tooltip';
import { BoldTitle, Text } from 'components/common/typography';

import { Asset } from 'types/wallet.types';

interface Props {
  assets: Asset[];
  isLoading?: boolean;
}

const MAX_ASSETS_TO_SHOW = 6;
const NUM_TOP_ASSETS = MAX_ASSETS_TO_SHOW - 1;

const pieChartTheme = {
  tooltip: {
    container: {
      background: 'transparent',
      border: 'none',
      boxShadow: 'none'
    }
  }
};

const getPieData = (assets: Asset[]) => {
  if (isEmpty(assets)) {
    return [{ id: 'empty', value: 1 }];
  }

  //@ts-ignore
  const assetsByAmount = assets.sort((a, b) => b.amount - a.amount);
  let pieData = assetsByAmount.map(asset => ({
    id: asset.asset_id,
    value: asset.amount
  }));

  if (size(assetsByAmount) > MAX_ASSETS_TO_SHOW) {
    const otherAssetsAmountSum = assetsByAmount
      .slice(NUM_TOP_ASSETS)
      .map(asset => asset.amount)
      .reduce((sum, currentAssetAmount) => sum + currentAssetAmount, 0);

    pieData = [
      ...pieData.slice(0, NUM_TOP_ASSETS),
      { id: 'Other', value: otherAssetsAmountSum }
    ];
  }

  return pieData;
};

export const TotalAssets = ({ assets, isLoading }: Props) => {
  const pieData = useMemo(() => getPieData(assets), [assets]);
  const isDataExists: boolean = pieData[0].id !== 'empty';
  const colors = useMemo(() => {
    if (!isDataExists) {
      return [theme.colors.grey];
    }
    return theme.assetsColors.map(
      (_, colorIndex) => theme.assetsColors[colorIndex]
    );
  }, [assets]);

  const renderPieChart = () => (
    <PieContainer>
      <Pie
        width={170}
        height={170}
        data={pieData}
        colors={colors}
        innerRadius={0.8}
        padAngle={0}
        cornerRadius={0}
        isInteractive={isDataExists}
        borderWidth={0}
        enableRadialLabels={false}
        enableSlicesLabels={false}
        //@ts-ignore
        tooltip={PieChartTooltip}
        theme={pieChartTheme}
      />
    </PieContainer>
  );

  const renderLegendChart = () => {
    if (!isDataExists) {
      return (
        <NoData>
          <BoldTitle>No assets currently.</BoldTitle>
        </NoData>
      );
    }
    return (
      <LegendsContainer>
        {pieData.map((pieDataEntry, pieDataEntryIndex) => {
          return (
            <LegendListItem key={`${pieDataEntry.id}asd`}>
              <TooltipWrapper
                top={-55}
                left={0}
                tooltipComponent={() => (
                  <PieChartTooltip
                    id={pieDataEntry.id}
                    value={pieDataEntry.value}
                    color={colors[pieDataEntryIndex]}
                  />
                )}>
                <Legend>
                  <LegendCircle color={colors[pieDataEntryIndex]} />
                  <Currency>{pieDataEntry.id}</Currency>
                </Legend>
              </TooltipWrapper>
            </LegendListItem>
          );
        })}
      </LegendsContainer>
    );
  };

  return (
    <Container>
      <IconicText text="TOTAL ASSETS" icon={HeaderIcon} />
      {isLoading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <>
          {renderPieChart()}
          {renderLegendChart()}
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const PieContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

const LegendsContainer = styled.div`
  flex: 1 0;
  align-items: center;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  width: 100%;
`;

const LegendListItem = styled.div``;

const Legend = styled.div`
  color: ${({ theme }) => theme.colors.midDarkGrey};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-self: flex-start;
`;

const LegendCircle = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  min-width: 13px;
  height: 13px;
  border-radius: 50%;
  margin-right: 5px;
`;

const Currency = styled(Text)`
  max-width: 8ch;
  min-width: 5ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const NoData = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.midDarkGrey};
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;
