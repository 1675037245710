import React, { ReactElement } from 'react';
import ReactDOM from 'react-dom';
import { Manager, Reference, Popper as PopperContainer } from 'react-popper';
import styled from '@emotion/styled';

type Placement =
  | 'auto-start'
  | 'auto'
  | 'auto-end'
  | 'top-start'
  | 'top'
  | 'top-end'
  | 'right-start'
  | 'right'
  | 'right-end'
  | 'bottom-end'
  | 'bottom'
  | 'bottom-start'
  | 'left-end'
  | 'left'
  | 'left-start';

interface Props {
  referenceElement: ReactElement;
  poppingElement: ReactElement;
  targetElementClass: string;
  isOpen: boolean;
  placement?: Placement;
  padding?: { top?: number; right?: number; bottom?: number; left?: number };
}

export const Popper = ({
  referenceElement,
  poppingElement,
  targetElementClass,
  isOpen,
  placement,
  padding
}: Props) => {
  const targetElement = document.querySelector(targetElementClass);

  if (!targetElement) {
    return null;
  }

  return (
    <Manager>
      <Reference>
        {({ ref }) => <div ref={ref}>{referenceElement}</div>}
      </Reference>
      {isOpen &&
        ReactDOM.createPortal(
          <PopperContainer
            placement={placement}
            modifiers={{
              preventOverflow: {
                boundariesElement: 'scrollParent',
                padding: padding ? padding : 0
              }
            }}>
            {({ placement, ref, style }) => (
              <PoppingContainer
                ref={ref}
                style={style}
                data-placement={placement}>
                {poppingElement}
              </PoppingContainer>
            )}
          </PopperContainer>,
          targetElement
        )}
    </Manager>
  );
};

const PoppingContainer = styled.div``;
