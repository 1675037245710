import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import get from 'lodash/fp/get';
import map from 'lodash/fp/map';
import size from 'lodash/fp/size';

import { ReactComponent as AssetsIcon } from 'assets/images/assets-icon.svg';

import { Loader } from 'components/common/loader';
import { IconicText } from 'components/common/iconic-text';
import { Title, LargeText, BoldTitle } from 'components/common/typography';

import { getFormattedAmount } from 'utils/wallet.utils';

import { Balances, Asset } from 'types/wallet.types';

import { useIsLoading } from 'stores/network.store';
import { BALANCES_LABEL } from 'stores/wallet/balances.store';

interface Props {
  balances: Balances;
  walletId: string;
}

export const AssetsList = ({ balances, walletId }: Props) => {
  const isLoadingBalances = useIsLoading(BALANCES_LABEL);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  useEffect(() => {
    const assets = get('assets', balances);
    if (assets && !isLoadingBalances) {
      setIsInitialized(true);
    }
  }, [balances, isLoadingBalances]);

  useEffect(() => {
    setIsInitialized(false);
  }, [walletId]);

  const assets = get('assets', balances);
  const assetsLength = size(assets);

  return (
    <Container>
      <Header>
        <TitleContainer>
          <IconicText text="ASSETS" icon={AssetsIcon} />
        </TitleContainer>
        <Title>{`(${assetsLength})`}</Title>
      </Header>

      {!isInitialized && (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )}

      {isInitialized && assetsLength === 0 && (
        <NoData>
          <BoldTitle>No assets currently.</BoldTitle>
        </NoData>
      )}

      {isInitialized && assetsLength > 0 && (
        <AssetsContainer>
          {map(
            (asset: Asset) => (
              <AssetListItem key={asset.asset_id}>
                <AssetAmount>
                  <LargeText>{getFormattedAmount(asset.amount)}</LargeText>
                </AssetAmount>
                <Title>{asset.asset_id}</Title>
              </AssetListItem>
            ),
            assets
          )}
        </AssetsContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Header = styled.div`
  flex: 0 1;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.midDarkGrey};
`;

const TitleContainer = styled.div`
  margin-right: 5px;
`;

const AssetsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: flex-start;
  max-height: 100%;
  &:hover {
    ${({ theme }) => theme.scrollbar.main};
  }
`;

const AssetListItem = styled.div`
  color: ${({ theme }) => theme.colors.midDarkGrey};
  &:not(:last-child) {
    margin-bottom: 40px;
  }
`;

const AssetAmount = styled.div`
  background-color: ${({ theme }) => theme.colors.grey};
  border-radius: 8px;
  margin-bottom: 3px;
  padding: 4px 8px;
  width: fit-content;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  width: 100%;
`;

const NoData = styled.div`
  min-height: 500px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.midDarkGrey};
`;
