import React, { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';

import get from 'lodash/fp/get';
import map from 'lodash/fp/map';
import isEmpty from 'lodash/fp/isEmpty';
import isNumber from 'lodash/fp/isNumber';

import { AuthContext } from 'context/auth.context';

import Modal from 'components/common/modal/modal';
import { Button } from 'components/common/button';
import { TextField } from 'components/common/text-field';
import { SingleSelect } from 'components/common/single-select';
import { BoldTitle, Text } from 'components/common/typography';
import { Switch } from 'components/common/switch';

import { useIsTransferModalOpen } from 'stores/ui/transfer-modal-ui.store';
import { useTransfer } from 'stores/wallet/transfers.store';

import { mapWithKeys } from 'utils/lodash.utils';
import { sortByTextField } from 'utils/common.utils';

import { Wallet } from 'types/firebase.types';
import { Asset, Balances, TransferDetails } from 'types/wallet.types';
import { useAddressBook } from 'stores/user/address-book';
import { useBalances } from 'stores/wallet/balances.store';
import theme from 'constants/themes.constants';

const transferDetailsInitialState: TransferDetails = {
  asset_id: { value: '', text: '' },
  amount: '',
  to: { value: '', text: '' },
  from: { value: '', text: '' },
  isConfirmRequired: false
};

export const TransferModal = () => {
  const wallets: Wallet[] = get('user.wallets', useContext(AuthContext));
  const { addressBook } = useAddressBook();
  const { transferAsset } = useTransfer();
  const [, setIsOpen] = useIsTransferModalOpen();
  const { fetchBalances } = useBalances();

  const [isAmountError, setIsAmountError] = useState<boolean>(false);
  const [addresses, setAddresses] = useState<{ value: any; text: string }[]>(
    []
  );
  const [assetIds, setAssetIds] = useState<{ value: any; text: string }[]>([]);
  const [walletsFrom, setWalletsFrom] = useState<
    { value: Wallet; text: string }[]
  >([]);
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(false);

  const [transferDetails, setTransferDetails] = useState<TransferDetails>(
    transferDetailsInitialState
  );
  useEffect(() => {
    const { asset_id, to, from, amount } = transferDetails;
    if (!asset_id.value || !to.value || !from.value || !amount) {
      setIsConfirmDisabled(true);
      return;
    }
    setIsConfirmDisabled(false);
  }, [transferDetails]);

  useEffect(() => {
    if (isEmpty(transferDetails.amount)) {
      if (isAmountError) {
        setIsAmountError(false);
      }
      return;
    }

    const amount = parseInt(transferDetails.amount);
    if (amount <= 0 || isNaN(amount) || !isNumber(amount)) {
      setIsAmountError(true);
    } else {
      setIsAmountError(false);
    }
  }, [transferDetails.amount]);

  useEffect(() => {
    if (isEmpty(wallets)) {
      return;
    }

    const formattedWallets = map(
      (wallet: Wallet) => ({ value: wallet, text: wallet.id }),
      wallets
    );

    const sortedFormattedWallet = sortByTextField(formattedWallets);

    setWalletsFrom(sortedFormattedWallet);
  }, [wallets]);

  useEffect(() => {
    if (isEmpty(addressBook)) {
      return;
    }

    const fromWalletId = get('from.value.id', transferDetails);
    if (!isEmpty(fromWalletId)) {
      fetchBalances({
        walletId: fromWalletId,
        onSuccess: (balances: Balances) => {
          const allAssetsIds = balances.assets.reduce(
            (assetsIds: any, { asset_id }: Asset) => {
              assetsIds[asset_id] = asset_id;
              return assetsIds;
            },
            {}
          );

          const formattedAssetsIds = mapWithKeys(
            (value: string, key: string) => ({ value: key, text: value }),
            allAssetsIds
          );

          const sortedFormattedWallet = sortByTextField(formattedAssetsIds);

          setAssetIds(sortedFormattedWallet);
        }
      });
    }

    const fromText = get('from.text', transferDetails);
    const formattedAddressBook = mapWithKeys(
      (value: string, key: string) => ({ value: addressBook[key], text: key }),
      addressBook
    ).filter(
      (address: { value: any; text: string }) => address.text !== fromText
    );
    const sortedFormattedAddressBook = sortByTextField(formattedAddressBook);

    setAddresses(sortedFormattedAddressBook);
    const recipientLabel = get('to.text', transferDetails);
    if (recipientLabel && recipientLabel === transferDetails.from.text) {
      handleFormChanged('to')({ value: '', text: '' });
    }
  }, [transferDetails.from.value]);

  const handleSubmit = () => {
    const { asset_id, amount, to, from } = transferDetails;
    if (
      isEmpty(asset_id.value) ||
      isEmpty(amount) ||
      isEmpty(to.value) ||
      isEmpty(from.value) ||
      isAmountError
    ) {
      return;
    }

    transferAsset(transferDetails);
    setIsOpen(false);
  };

  const handleFormChanged = (key: string) => (item: any) =>
    setTransferDetails({ ...transferDetails, [key]: item });

  return (
    <Modal onClose={() => setIsOpen(false)}>
      <Container>
        <Title>
          <BoldTitle>TRANSFER ASSET</BoldTitle>
        </Title>
        <SingleSelect
          items={walletsFrom}
          onSelect={handleFormChanged('from')}
          width="100%"
          label="From"
          selectedValue={transferDetails.from.text}
          margin="0 0 40px"
          dropDownMaxHeight={theme.singleSelect.dropdownMaxHeight}
        />
        <SingleSelect
          items={assetIds}
          onSelect={handleFormChanged('asset_id')}
          width="100%"
          label="Asset ID"
          selectedValue={transferDetails.asset_id.text}
          margin="0 0 40px"
          dropDownMaxHeight={theme.singleSelect.dropdownMaxHeight}
        />
        <TextFieldContainer>
          <TextField
            type="number"
            label="Amount"
            value={transferDetails.amount}
            onChange={handleFormChanged('amount')}
            error={isAmountError}
            helperText={isAmountError ? 'Amount must be greater than 0' : ''}
          />
        </TextFieldContainer>
        <SingleSelect
          items={addresses}
          onSelect={handleFormChanged('to')}
          width="100%"
          label="To"
          selectedValue={transferDetails.to.text}
          margin="0 0 40px"
          dropDownMaxHeight={theme.singleSelect.dropdownMaxHeight}
        />
        <SwitchContainer>
          <SwitchLabel>
            <Text>Confirmation Required</Text>
          </SwitchLabel>
          <Switch
            isChecked={transferDetails.isConfirmRequired}
            onChange={handleFormChanged('isConfirmRequired')}
            disabled={isConfirmDisabled}
          />
        </SwitchContainer>
        <ActionsContainer>
          <Cancel onClick={() => setIsOpen(false)}>
            <Text>Cancel</Text>
          </Cancel>
          <Button
            onClick={handleSubmit}
            text="transfer"
            variant="primary"
            width="200px"
            fontSize="12px"
            fontWeight="600"
          />
        </ActionsContainer>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  min-width: 500px;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colors.midDarkGrey};
  padding: 35px 0 50px;
  text-align: center;
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Cancel = styled.div`
  color: ${({ theme }) => theme.colors.midDarkGrey};
  cursor: pointer;
`;

const TextFieldContainer = styled.div`
  .label {
    margin-bottom: 6px;
  }
`;

const SwitchLabel = styled.div`
  color: ${({ theme }) => theme.colors.midDarkGrey};
  margin-right: 20px;
`;

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
`;
