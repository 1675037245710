import { createStore } from 'reusable';

import { useBalances } from 'stores/wallet/balances.store';
import { useUserTransactions } from 'stores/user/node-transactions.store';
import { useGetTaskStatus } from 'stores/wallet/task-status.store';

import {
  setTransferAndIssuance,
  setTransactionIsFailed,
  setNewFailedTransaction,
  setFailedTransactionCount,
  setNotificationResultChange,
  updateCurrentWalletBalancesAfterSuccessfulTask
} from 'utils/user-transactions.utils';

import { taskResultEnum } from 'constants/tasks.constants';

import { Task } from 'types/tasks.types';
import {
  fetchAndSetNotificationsPassedProps,
  NotificationId
} from 'types/notifications.types';

export const useTransferAndIssuanceNotifications = createStore(() => {
  const {
    setTransactions,
    transactionsRef,
    orderRef,
    blackListRef,
    failedTransactionsCount
  } = useUserTransactions();
  const { fetchBalances } = useBalances();
  const { fetchTaskStatus } = useGetTaskStatus();

  const handleSetTransaction = (
    status: Task,
    amount: string,
    assetId: string,
    sender: string,
    recipient: string
  ) => {
    if (blackListRef.current[status.id]) {
      return;
    }

    if (!transactionsRef.current[status.id]) {
      setTransferAndIssuance(
        status,
        amount,
        assetId,
        sender,
        recipient,
        orderRef,
        transactionsRef,
        setTransactions
      );

      return;
    }

    if (transactionsRef.current[status.id].result !== status.result) {
      setNotificationResultChange(status, transactionsRef, setTransactions);
    }
  };

  const fetchAndSetTransferAndIssuanceNotifications = (
    { id }: NotificationId,
    {
      amount,
      assetId,
      sender,
      recipient,
      type
    }: fetchAndSetNotificationsPassedProps
  ) => {
    const taskRequest = () => {
      fetchTaskStatus(id)
        .then((status: any) => {
          handleSetTransaction(status, amount, assetId, sender, recipient);
          if (
            status.result === taskResultEnum.pending ||
            status.result === taskResultEnum.inProgress
          ) {
            setTimeout(taskRequest, 3000);
          }

          if (status.result === taskResultEnum.success) {
            updateCurrentWalletBalancesAfterSuccessfulTask(
              sender,
              recipient,
              fetchBalances
            );
          }
        })
        .catch(() => {
          if (
            !failedTransactionsCount.current[id] ||
            failedTransactionsCount[id] < 2
          ) {
            setFailedTransactionCount(id, failedTransactionsCount);
            setTimeout(taskRequest, 3000);
            return;
          }

          if (!transactionsRef.current[id]) {
            setNewFailedTransaction(
              id,
              amount,
              assetId,
              sender,
              recipient,
              type,
              transactionsRef,
              setTransactions
            );

            return;
          }

          setTransactionIsFailed(id, transactionsRef, setTransactions);
        });
    };

    taskRequest();
  };

  return {
    fetchAndSetTransferAndIssuanceNotifications
  };
});
